import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import form_style from '../../style/form';
import button_style from '../../style/button';

function Coupon(props: any) {
  const { t } = useTranslation();
  const button_classes = button_style();
  const form_classes = form_style();
  const [values, setValues] = useState({
    vouchercode: '',
    isValidating: false,
    error: ''
  });
  const handleChange = (event: any) => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const handleSubmit = (event: any) =>  {
    if (event) event.preventDefault();
    props.setCouponCode(values.vouchercode);
  }

  return (
    <Grid container style={{marginTop: '24px'}}>
      <form onSubmit={handleSubmit} className={form_classes.container} noValidate autoComplete="off">
        <Grid item sm={12}>
          <Typography variant="h5">{t('orders.coupon')}</Typography>
        </Grid>
        <Grid item sm={12}>
          <TextField
            autoComplete=""
            error={values.error ? true: false}
            helperText={values.error ? values.error: ''}
            id="vouchercode"
            name="vouchercode"
            value={values.vouchercode}
            onChange={handleChange}
            fullWidth
            disabled={props.isBusy ? true: false || values.isValidating ? true: false}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }} />
            {(props.couponCode &&
              <Typography variant="body1">
                {t('orders.coupon')}: <b>{props.couponCode.toUpperCase()}</b> {t('orders.couponisapplied')}
              </Typography>
            )}
        </Grid>
        <Grid item sm={12}>
          <Button
            onClick={handleSubmit}
            disabled={props.isBusy ? true: false || values.isValidating ? true: false}
            type="submit"
            className={`${button_classes.noRadius} ${button_classes.default} ${button_classes.block}`}
            variant="contained">
            {values.isValidating ? t('orders.validating'): t('orders.applycoupon')}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
}
export default Coupon;
