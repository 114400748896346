import moment from 'moment';

export const disableDates = (date:any) => {
  const day = date.getDay();
  if (day === 0 || day === 6) {
    return true;
  }
  else {
    const now = moment(),
      nowDay = now.day();
    // Disable past dates.
    if (moment(date).isBefore(now)) return true;
    // Disable all this week is current day is mon - wed.
    if (nowDay >= 1 && nowDay <=3) {
      return moment(date).week() === now.week();
    }
    // Disable all this week and next week if current day is thu - sunday.
    else if ([0, 4, 5, 6].includes(nowDay)) {
      return moment(date).week() === now.week() ||
        moment(date).week() === ( now.week() === 52 ? 1 : (now.week() + 1))
    }
    else {
      return false;
    }
  }
}

export default disableDates;
