import isFunction from 'lodash/isFunction';
import { captureException } from '@sentry/browser';

/**
 * Utilities
 */
export function bind(object: any, methods?: [string]): any {
  (methods || Object.getOwnPropertyNames(object.constructor.prototype)).forEach((key) => {
    if (key !== 'constructor' && isFunction(object[key])) {
      object[key] = object[key].bind(object);
    }
  });
  return object;
}

/**
 * Parse query string
 */
export function parseQueryString(query: string): any {
  const params: any = {};
  (query || '?').substr(1).split('&').forEach((param: string) => {
    const [name, value] = param.split('=');
    if (name) {
      params[name] = decodeURIComponent(value);
    }
  });
  return params;
}

/**
 * Handle error.
 */
export function handleError(error:any) {
  // Current build stage.
  const stage = process.env.REACT_APP_ENV;
  // Record error on sentry.
  captureException(error);
  // Hide console errors on production. Show on development.
  if (stage !== 'prod' && stage !== 'production')
    console.error(error);
}

export class GraphQLError extends Error {
  constructor(message:string, operation:string) {
    super(message);
    this.name = `GraphQLError`;
    this.message = `[${operation}] ${message}`;
  }
}

export function openUrl(url: string) {
  const win = window.open(url, '_blank');
  if (win != null) {
    win.focus();
  }
}

export const formatLabel = (l:string):string => {
  return l.toLowerCase().replace(' ', '');
}