import { makeStyles } from '@material-ui/core/styles';

const price_style = makeStyles((theme) => ({
  inlineTax: {
    fontSize: '20px',
    marginLeft: '15px',
    color: theme.palette.primary.main,
    verticalAlign: 'middle'
  },
}));

export default price_style;
