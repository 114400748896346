import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import grid_style from '../../style/grids';
import loader_style from '../../style/loader';

export default function SimpleLoader(props: any) {
  const classes = grid_style();
  const loader_classes = loader_style();

  return (
    <Grid container className={props.full ? classes.fullHeight : ''}>
      <Grid item xs={12} className={classes.simpleLoader}>
        <CircularProgress className={loader_classes.loader2} />
      </Grid>
    </Grid>
  );
}
