import React from "react";
import price_style from "../../style/price";

interface SimpleTaxDisplayProps {
  subTotal: number;
}

export function SimpleTaxDisplay({
  subTotal,
}: SimpleTaxDisplayProps): React.ReactElement {
  const price_classes = price_style();

  if (subTotal <= 0) {
    return <></>;
  }

  const percentage = 14;

  const taxAmount = subTotal * (percentage / 100);

  return (
    <span className={price_classes.inlineTax}>{`${(taxAmount + subTotal || 0)
      .toFixed(2)
      .replace(".", ",")} € (${percentage}%) ALV`}</span>
  );
}

export default SimpleTaxDisplay;
