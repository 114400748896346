import React from 'react'
import QFBox from '../Base/QueryContainer/QFBox';
import SignupForm from '../Auth/SignupForm';
import { CreateOrderProvider } from '../Contexts/Order';

function Profile(props: any) {
  return (
    <div>
      <CreateOrderProvider>
        <QFBox resource={['user', 'picklists']}
          {...props}
          formTitle={'Edit profile'}
          component={SignupForm}
          editMode={true}
        />
      </CreateOrderProvider>
    </div>
  )
}

export default Profile;
