// Environment variables used will have a suffix based on the stage set.
// The available stage are 'DEV', 'STAGING', and 'PROD'. The REACT_APP_ENV
// will serve as the identifier for the stage, and if not set, it will use the localhost
// variables.
const env = process.env.REACT_APP_ENV, // Get stage set.
  host = 'REACT_APP_HOST' + (env?'_'+env.toUpperCase():''),
  host_aws_serverless = 'REACT_APP_HOST_AWS_SERVERLESS' + (env?'_'+env.toUpperCase():''),
  cognito_region = 'REACT_APP_COGNITO_REGION' + (env?'_'+env.toUpperCase():''),
  userpool_id = 'REACT_APP_USERPOOL_ID' + (env?'_'+env.toUpperCase():''),
  userpool_webclient_id = 'REACT_APP_USERPOOL_WEBCLIENT_ID' + (env?'_'+env.toUpperCase():''),
  google_tag_manager_id = 'REACT_APP_GOOGLE_TAG_MANAGER_ID' + (env?'_'+env.toUpperCase():''),
  stripe_key = 'REACT_APP_STRIPE_KEY' + (env?'_'+env.toUpperCase():'');

// Check if the environment variables exists.
if (!process.env[host_aws_serverless]) throw new Error(host_aws_serverless+' not set in .env');

// Export
export const HOST = process.env[host];
export const HOST_AWS_SERVERLESS = process.env[host_aws_serverless];
export const COGNITO_REGION = process.env[cognito_region];
export const USERPOOL_ID = process.env[userpool_id];
export const USERPOOL_WEBCLIENT_ID = process.env[userpool_webclient_id];
export const GOOGLE_TAG_MANAGER_ID = process.env[google_tag_manager_id];
export const REACT_APP_STRIPE_KEY = process.env[stripe_key];

export const ORDER_OPTION = {
  deliveryDays: [
    {value: 'Monday', label: 'Monday'},
    {value: 'Tuesday', label: 'Tuesday'},
    {value: 'Wednesday', label: 'Wednesday'},
    {value: 'Thursday', label: 'Thursday'},
    {value: 'Friday', label: 'Friday'}
  ],
  deliveryDaysDefault: 'Monday',
  deliveryOptions: [
    {value: 'Every week', label: 'Every week', locale: 'orders.everyweek'},
    {value: 'Every other week', label: 'Every other week', locale: 'orders.everyotherweek'},
    {value: 'One time only', label: 'One time only', locale: 'orders.onetimeonly'},
  ],
  deliveryOptionsDefault: 'Every week',
  quantity: 1,
  productId: ''
};

export const PRODUCTS_ORDER = [
  "joulu",
  "basic",
  "season",
  "organic",
  "exotic",
  "banana",
  "basic-nutly",
  "season-nutly",
  "organic-nutly",
  "exotic-nutly",
  "banana-nutly",
];

export const PRODUCT_IMAGES_ORDER = [
  "/images/menekki_kuva.jpg"
];

export const PRODUCT_ADD_ONS = [
  "nutly-mix-200g"
]

export const B2C_PAYMENT_METHODS = [
  "Card Payment"
];
