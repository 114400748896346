import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import ConfirmationDialog from '../Base/ConfirmationDialog';
import EditItemDialog from '../Base/EditItemDialog';
import { getItem } from '../../libs/product';
import { editItemCart, delItemCart } from '../../libs/cart';
import { HOST, ORDER_OPTION } from '../../config';
import card_styles from '../../style/card';
import grid_style from '../../style/grids';
import paper_style from '../../style/paper';
import link_style from '../../style/links';
import Price, { addVatToPrice } from '../Utils/Price';
import { getAddressHash } from '../Base/QueryContainer/Addresses';
import { gtmEvents } from '../../libs/tagmanager';
import RenderText from '../Utils/TextUtils';

function ItemDetails(props: any) {
  const { t } = useTranslation();
  const classes = card_styles();
  const link_classes = link_style();
  const paper_classes = paper_style();
  const grid_classes = grid_style();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const productDetail: any = getItem(props.orderDetails.productId, props.productFamily.item);
  const defaultVariant = {
    productId: productDetail.productId,
    price: productDetail.price,
    uomid: productDetail.uomid
  };
  const addresses = props.addresses || [];

  let totalCost = 0;
  const orderDetails = props.orderDetails.details.map((item: any) => {
    if (item.day) {
      totalCost += productDetail.price * item.quantity;
      return `${t('general.' + item.day.toLowerCase())}`;
    } else if (item.date) {
      totalCost += productDetail.price * item.quantity;
      return `${moment(item.date).format('DD.MM.YYYY')}`;
    }
    else {
      totalCost += productDetail.price * item.quantity;
      return '';
    }
  });

  const totalQty = () => {
    var _totalQty = 0;
    props.orderDetails.details.map((o: any) => o.quantity).forEach((qty: number) => {
      _totalQty += qty;
    });
    return _totalQty;
  };

  const [productVariant, setProductVariant] = React.useState(defaultVariant);
  const orderOption: any = props.orderDetails.orderOption ? ORDER_OPTION.deliveryOptions.find(({ value }) => {
    return value === props.orderDetails.orderOption;
  }) : '';

  const setSelectedVariant = (id: string) => {
    const i: any = getItem(id, props.productFamily.item);
    if (i) {
      setProductVariant({ productId: i.productId, price: i.price, uomid: i.uomid });
    }
  }

  const handleDeleteItem = () => {
    delItemCart(props.cart.items, props.cartId, props.updateCart);

    const { productFamily, orderDetails } = props;
    const variant_detail = productFamily.item.variants.filter((variant: any) => variant.productId === orderDetails.productId);
    const total_qty = orderDetails.details.reduce((acc: any, detail: any) => acc + detail.quantity, 0);

    gtmEvents([{
      'name': productFamily.item.name,
      'id': orderDetails.productId,
      'price': variant_detail[0].price,
      'brand': 'Fruitbox',
      'category': productFamily.slug,
      'variant': productFamily.item.variant_type,
      'dimension1': variant_detail[0].name,
      'dimension2': (productFamily.item.variant_type === 'weight') ? orderDetails.orderOption : '',
      'quantity': total_qty,
    }], 'remove_from_cart', total_qty);
  }

  const onAddressChange = (e: any) => {
    editItemCart(
      props.cart.items,
      {
        ...props.orderDetails,
        ...(addresses.find(({ hash }: any) => (hash === e.target.value)) || {})
      },
      props.cartId,
      props.updateCart
    );
  };

  const itemAddress = () => {
    if (!addresses.length) {
      return (
        <Typography variant="body2">
          {t('orders.nodeliveryaddress')}
        </Typography>
      );
    }
    const pickFields: Array<string> = [
      'index',
      'customerName',
      'streetAddress',
      'zipCode',
      'city'
    ];
    const itemAddress = pick(props.orderDetails, pickFields);
    if (addresses.length === 1) {
      const currentHash = getAddressHash(itemAddress);
      if (currentHash !== addresses[0].hash) {
        editItemCart(
          props.cart.items,
          {
            ...props.orderDetails,
            ...pick(addresses[0], pickFields)
          },
          props.cartId,
          props.updateCart
        );
        return '';
      }
      return (
        <Typography variant="body2">
          <b>{t('orders.deliverto')}:</b> <br />
          {addresses[0].customerName}<br />
          {addresses[0].streetAddress}<br />
          {addresses[0].zipCode} {addresses[0].city}
        </Typography>
      );
    }
    const selectId = 'address-' + props.cartId;
    if (isEmpty(itemAddress)) {
      editItemCart(
        props.cart.items,
        {
          ...props.orderDetails,
          ...pick(addresses[0], pickFields)
        },
        props.cartId,
        props.updateCart
      );
      return '';
    }
    const value = getAddressHash(itemAddress),
      currentAddress = addresses.find(({ hash }: any) => (hash === value));
    if (!currentAddress) {
      editItemCart(
        props.cart.items,
        {
          ...props.orderDetails,
          ...{
            ...addresses[itemAddress.index]
          }
        },
        props.cartId,
        props.updateCart
      );
      return '';
    }
    return (
      <>
        <strong>{t('orders.deliverto')}</strong>
        <FormControl variant="outlined" fullWidth={true}>
          <Select
            value={value}
            onChange={onAddressChange}
            input={(
              <OutlinedInput labelWidth={0} name="address" id={selectId} />
            )}>
            {addresses.map((address: any, i: number) => {
              return (
                <MenuItem key={i} value={address.hash}>
                  {address.customerName}{address.customerName ? <br /> : ''}
                  {address.streetAddress}, {address.zipCode}, {address.city}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </>
    );
  };

  return (
    <Grid container spacing={2} style={{ paddingTop: '12px' }} >
      <Grid item sm={12}>
        <Paper className={paper_classes.paper}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4} lg={3}>
              <Card className={classes.card_no_fade}>
                <div className={classes.swapMedia}>
                  {(props.productFamily.item.images.length > 0 &&
                    <CardMedia
                      className={classes.media}
                      image={`${HOST}${props.productFamily.item.images[0]}`}
                      title={props.productFamily.item.name} />
                  )}
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={8} lg={9}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <Typography variant="h3">{props.productFamily.item.name}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <Typography variant="h5">
                    {productDetail.name} - {props.productFamily.item.iscontinuouspossible ? `${t(orderOption.locale)} - ` : ''}
                    {orderDetails.reduce((prev: any, curr: any) => [prev, ', ', curr])}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: 10 }}>
                <Grid item sm={12}>
                  <Typography variant="h5" color='secondary'>
                    {totalQty()} x <Price amount={productDetail.price} />
                    {' '}
                    {t('general.total')}  <Price amount={totalCost} />
                  </Typography>
                </Grid>
                {(props.isPrivate || !props.isSignedIn) && 
                  <Grid item sm={12}>
                    <Typography color='secondary'>
                      <small>
                        {totalQty()} x <Price amount={addVatToPrice(productDetail.price)} /> {` alv (14%) `}
                        {' '}
                        {t('general.total')} <Price amount={addVatToPrice(totalCost)} />
                      </small>
                    </Typography>
                  </Grid>
                }
                <Grid item sm={12} className={classes.productDescription}>
                  {RenderText(props?.productFamily?.item?.description ?? '')}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={9}>
                  {itemAddress()}
                  {(!props.isBusy &&
                      <Typography variant="body1">
                        <span onClick={() => props.editAddress({}, props.cartId, props.updateCart)} className={clsx(link_classes.link_primary, link_classes.span_link)}>
                          {t('orders.adddeliveryaddress')}
                        </span>
                      </Typography>
                    )}
                </Grid>
                <Grid item sm={3}>
                  <div className={grid_classes.align_right}>
                    <IconButton disabled={props.isBusy ? true : false} aria-label={t('general.edit')} onClick={() => setOpenEditDialog(true)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton disabled={props.isBusy ? true : false} aria-label={t('general.delete')} onClick={() => setOpenDeleteDialog(true)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <EditItemDialog
        cartId={props.cartId}
        setSelectedVariant={setSelectedVariant}
        productVariant={productVariant}
        orderDetails={props.orderDetails}
        family={props.productFamily}
        open={openEditDialog}
        setOpen={setOpenEditDialog}
        contentTitle={`${t('orders.editorderdetails')} - ${props.productFamily.item.name}`}
        cart={props.cart}
        updateCart={props.updateCart} />
      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        yesAction={handleDeleteItem}
        contentText={`${t('orders.deleteorderconfirmation')} ${props.productFamily.item.name}?`}
        contentTitle={t('orders.deleteproduct')} />
    </Grid>
  );
};

export default ItemDetails;

