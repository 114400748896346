import React from 'react';
import CheckoutAnon from './CheckoutAnon';
import CheckoutUser from './CheckoutUser';
import QFBox from '../Base/QueryContainer/QFBox';
import { CreateOrderProvider } from '../Contexts/Order';
import { Query } from 'react-apollo';
import { ACCOUNT_QUERY } from '../../query/user';
import SimpleLoader from '../Loader/SimpleLoader';

function Overview(props: any) {
  if (props.isSignedIn) {
    return (<Query<any> query={ACCOUNT_QUERY} variables={{ email: props.userEmail }}>
      {({ loading, error, data }) => {
        if (loading) return <SimpleLoader />;
        if (error) return null;
        return (
          <CreateOrderProvider>
            <QFBox
              resource={["user", "products"]}
              {...props}
              component={CheckoutUser}
            />
          </CreateOrderProvider>);
      }}
    </Query>);
  } else {
    return (
      <CreateOrderProvider>
        <QFBox resource={["products"]} {...props} component={CheckoutAnon} />
      </CreateOrderProvider>
    );
  }
}

export default Overview;
