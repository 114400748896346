import React from 'react';
import { Query } from 'react-apollo';

import SimpleLoader from '../../Loader/SimpleLoader';
import { parseDynamicsUserInfo } from '../../../libs/user';
import { SALESORDER_OPTIONS_QUERY } from '../../../query/orderOptions';
import { PRODUCTS_QUERY } from '../../../query/products';
import { ORDERS_QUERY } from '../../../query/orders';
import { ACCOUNT_QUERY } from '../../../query/user';
import { AppContext, AppState } from '../../../App';
import { INVOICES_QUERY } from '../../../query/invoices';
import PICKLISTS from '../../../query/picklists';
import { ORDERS_HISTORY_QUERY } from '../../../query/ordersHistory';
import { IMAGES } from '../../../query/images';
import { DASHBOARD_USER_QUERY } from '../../../query/dashboarduser';

import { flattenOrders } from '../../../libs/order';
import { getProductIds, assignImages } from '../../../libs/product';
import { parseImages } from '../../../libs/image';

const sha1 = require('sha1');

export const fetches: any = {
  orders: 0,
  user: 0
};

interface Option {
  label: string;
  value: any;
}

interface Picklist {
  [entity: string]: [{
    logicalName: string;
    displayName: string;
    options: Option[];
  }]
}

const QFBox = (props: any) => {
  let loader: any = <SimpleLoader full/>
  let resource: any = props.resource;
  let current_resource = resource ? resource[0] : null;
  resource = resource.slice(1);
  let main_key = sha1(resource.join(', '));

  if (props.hiddenLoader) {
    loader = null;
  }

  return (
    <AppState.Consumer key={main_key}>
      {({ state, setState }: AppContext) => {
        switch (current_resource) {
          // userEmail is required to fetch user
          case 'user':
            return (
              <Query<any> query={ACCOUNT_QUERY}>
                {({ loading, data, error, refetch }) => {
                  if (loading) return loader;
                  if (state.refetching.user) {
                    return loader;
                  }
                  if (error) return null;
                  if (state.needsRefetch.user) {
                    if (fetches.user > 0) {
                      fetches.user++;
                      setState({
                        ...state,
                        refetching: {
                          ...state.refetching,
                          user: true
                        }
                      });
                      refetch().catch(() => null).then(() => {
                        setState({
                          ...state,
                          needsRefetch: {
                            ...state.needsRefetch,
                            user: false
                          },
                          refetching: {
                            ...state.refetching,
                            user: false
                          }
                        });
                      });
                      return loader;
                    }
                  }
                  fetches.user++;
                  const user = parseDynamicsUserInfo(data.account);
                  return <QFBox key={main_key} {...props} user={user} resource={resource} refetchUser={refetch} />
                }}
              </Query>
            );
          // you cannot call order without calling user resource first
          case 'orders':
            return (
              <Query<any> query={ORDERS_QUERY}>
                {({ loading, data, error, refetch }) => {
                  if (loading) return loader;
                  if (error) return null;
                  
                  if (state.needsRefetch.orders) {
                    if (fetches.orders > 0) {
                      fetches.orders++;
                      setState({
                        ...state,
                        refetching: {
                          ...state.refetching,
                          orders: true
                        }
                      });
                      refetch().catch((err) => {
                        return null;
                      }).then(() => {
                        setState({
                          ...state,
                          needsRefetch: {
                            ...state.needsRefetch,
                            orders: false
                          },
                          refetching: {
                            ...state.refetching,
                            orders: false
                          }
                        });
                      });
                      return loader;
                    }
                  }
                  fetches.orders++;

                  const { orders, stripesubscriptions: stripeSubscriptions } = data;
                  const { products } = props;
                  const compact = !props.isPrivate;
                  const res = flattenOrders(orders ?? [], getProductIds(products), compact);
                  return <QFBox {...props} refetch={refetch} orders={res} stripeSubscriptions={stripeSubscriptions} resource={resource} />
                }}
              </Query>
            );
          case 'products':
            return (
              <Query<any> query={PRODUCTS_QUERY}>
                {({ loading, error, data }) => {
                  if (loading) return loader;
                  if (error) return null;
                  const products = assignImages(data, data?.images ?? []);
                  return <QFBox {...props} products={products} resource={resource} />
                }}
              </Query>
            );
          case 'order_options':
            return (
              <Query<any> query={SALESORDER_OPTIONS_QUERY}>
                {({ loading, error, data }) => {
                  if (loading) return loader;
                  if (error) return null;

                  const getOptions = (field: string, logicalName: string) => {
                    return data?.[field]?.find((o: any) => {
                      return o.logicalName === logicalName
                    })?.options ?? [];
                  };

                  const deliveryWeekDays = getOptions('orderPicklists', 'fs_deliveryweekday');
                  const frequencies = getOptions('orderPicklists', 'fs_orderfrequency');

                  return <QFBox {...props}
                    frequencies={frequencies}
                    days={deliveryWeekDays}
                    resource={resource}
                  />
                }}
              </Query>
            );
          case 'invoices':
            return (
              <Query<any> query={INVOICES_QUERY} variables={{ accountid: props.user.accountId }}>
                {({ loading, error, data }) => {
                  if (loading) return loader;
                  if (error) return null;
                  return <QFBox {...props} invoices={data.invoices} resource={resource} />
                }}
              </Query>
            );
          case 'picklists':
            return (
              <Query<Picklist> query={PICKLISTS}>
                {({ loading, error, data }) => {
                  if (loading) return loader;
                  if (error) return null;

                  const getOptions = (field: string, logicalName: string) => {
                    return data?.[field]?.find(o => {
                      return o.logicalName === logicalName
                    })?.options ?? [];
                  };

                  const eInvoiceOperators = getOptions('accountPicklists', 'fs_einvoiceoperator');
                  const paymentMethods = getOptions('accountPicklists', 'fs_paymentmethod');
                  const customerTypes = getOptions('accountPicklists', 'fs_customertype');
                  const deliveryWeekDays = getOptions('orderPicklists', 'fs_deliveryweekday');
                  const frequencies = getOptions('orderPicklists', 'fs_orderfrequency');
                  const imageTypes = getOptions('imagePicklists', 'fs_imagetype');

                  return <QFBox {...props}
                    eInvoiceOperators={eInvoiceOperators}
                    paymentMethods={paymentMethods}
                    customerTypes={customerTypes}
                    days={deliveryWeekDays}
                    frequencies={frequencies}
                    imageTypes={imageTypes}
                    resource={resource}
                  />
                }}
              </Query>
            );
          case 'ordershistory':
            return (
              <Query<any> query={ORDERS_HISTORY_QUERY} variables={{ accountId: props.user.accountId }}>
                {({ loading, error, data }) => {
                  if (loading) return loader;
                  if (error) return null;
                  return <QFBox {...props} ordershistory={data.ordershistory} resource={resource} />
                }}
              </Query>
            );
          case 'images':
            return (
              <Query<any> query={IMAGES}>
                {({ loading, error, data }) => {
                  if (loading) return loader;
                  if (error) return null;
                  const images = parseImages(data?.images, props?.imageTypes);
                  return <QFBox {...props} images={images} resource={resource} />
                }}
              </Query>
            );
          case 'dashboarduser':
            return (
              <Query<any> query={DASHBOARD_USER_QUERY}>
                {({loading: loadingDashboardUser, error, data}: any) => {
                  if (loadingDashboardUser) return loader;
                  if (error) {
                    throw error;
                  }
                  var newProps: any = {};

                  const getOptions = (field: string, logicalName: string) => {
                    return data?.[field]?.find((o: any) => {
                      return o.logicalName === logicalName
                    })?.options ?? [];
                  };
                  const eInvoiceOperators = getOptions('accountPicklists', 'fs_einvoiceoperator');
                  const paymentMethods = getOptions('accountPicklists', 'fs_paymentmethod');
                  const customerTypes = getOptions('accountPicklists', 'fs_customertype');
                  const deliveryWeekDays = getOptions('orderPicklists', 'fs_deliveryweekday');
                  const frequencies = getOptions('orderPicklists', 'fs_orderfrequency');
                  const imageTypes = getOptions('imagePicklists', 'fs_imagetype');
                  newProps = {
                    ...newProps,
                    eInvoiceOperators: eInvoiceOperators,
                    paymentMethods: paymentMethods,
                    customerTypes: customerTypes,
                    days: deliveryWeekDays,
                    frequencies: frequencies,
                    imageTypes: imageTypes
                  }

                  const {
                    account,
                    orders,
                    stripesubscriptions,
                    images,
                    products: categories,
                  } = data;

                  if (images) {
                    const images = parseImages(data?.images, newProps?.imageTypes);
                    newProps = {...newProps, images: images};
                  }

                  if (categories) {
                    assignImages( {categories: categories}, newProps?.images ?? []);
                    newProps = {...newProps, products: {categories: categories}};
                  }

                  if (account) {
                    const user = parseDynamicsUserInfo(account);
                    newProps = {...newProps, user: user};
                  }

                  if (orders) {
                    const compact = !props.isPrivate;
                    const parsedOrders = flattenOrders(orders ?? [], getProductIds(newProps?.products ?? []), compact);
                    newProps = {...newProps, orders: parsedOrders};
                  }

                  if (stripesubscriptions) {
                    newProps = {...newProps, stripeSubscriptions: stripesubscriptions};
                  }

                  return <QFBox {...props} {...newProps} resource={resource} flagSkip={true}/>
                }}
              </Query>
            );
          default:
            return <props.component {...props} />
        }
      }}
    </AppState.Consumer>
  );
}

export default QFBox;
