import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import OrderForm from '../Products/OrderForm';

export default function EditItemDialog(props: any) {
  const family: any = props.family;
  const [totalCost, setTotalCost] = React.useState(0);

  function handleNo() {
    props.setOpen(false);
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.open}
        scroll={`body`}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{props.contentTitle}</DialogTitle>
        <DialogContent>
          <OrderForm
            isFromCheckout={true  }
            defaultVariant={props.productVariant}
            cartId={props.cartId} {...props}
            isDialog={true}
            setDialogOpen={props.setOpen}
            totalCost={totalCost}
            setTotalCost={setTotalCost}
            family={family}
            setSelectedVariant={props.setSelectedVariant}
            isPrivate={props?.isPrivate ?? false}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
