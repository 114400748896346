import isEmpty from 'lodash/isEmpty';
import React from 'react';

export default function RenderText(text: string) {
    const texts: string[] = text.split('\n');
    return (
        texts.map((line: string) => {
            if (isEmpty(line)) {
                return <br/>;
            }
            return <p>{line}</p>;
        })
    );
}