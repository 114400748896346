import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { appmenu_style } from '../../style/appmenu';
import { nav_anon, nav_user } from './nav';

const LeftDrawer: React.FC = (props: any) => {
  let nav: any = [];
  nav = props.isSignedIn ? nav_user : nav_anon;

  const { t } = useTranslation();
  const classes = appmenu_style();
  const [state, setState] = React.useState({
    isOpen: false
  });

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ isOpen: open });
  };

  const sideList = () => {

    return (<div
      className={classes.drawerList}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}

    >
      <List>
        {
          nav.map((menu: any, idx: number) => {
            if (menu.label === '') {
              return <Divider key={idx} />
            }
            return <Link key={idx} to={menu.link} className={classes.drawer_links}>
              <ListItem button >
                <ListItemText primary={t(`${menu.label}`)} />
              </ListItem>
            </Link>;
          })

        }
      </List>
    </div>);
  };

  return (
    <div>
      <IconButton
        onClick={toggleDrawer(true)}
        edge="start"
        className={`${classes.menuButton}`}
        color="inherit"
        aria-label="Open drawer"
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={state.isOpen} onClose={toggleDrawer(false)}>
        {sideList()}
      </Drawer>
    </div>
  );
}

export default LeftDrawer;
