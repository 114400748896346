import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import isFunction from 'lodash/isFunction';
import { useTranslation } from 'react-i18next';
import { setCookie } from '../../libs/cookie';

export default function NotificationDialog(props: any) {
  const { t } = useTranslation();

  function handleClose() {
    setCookie("welcome", 0);

    if (isFunction(props.callBackClose)) {
      props.callBackClose();
    }
    props.setOpen(false);
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.open}
        scroll={`body`}
        onClose={handleClose}
        onBackdropClick={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.contentTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
