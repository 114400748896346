import { toast } from 'react-toastify';
import { css } from 'glamor';

const sha1 = require('sha1');

export function ToastError(error: string) {

  const id = sha1(error);

  if (!toast.isActive(id)) {
    toast.error(error, {
      toastId: id,
      hideProgressBar: true,
      autoClose: false,
      className: css({
        borderRadius: '10px !important',
        background: 'rgb(255,158,25) !important',
        color: 'black',
        '& .Toastify__close-button': {
          color: 'black'
        }
      })
    })
  }
}

export default ToastError;
