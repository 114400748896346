import React from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import Avatar from "@material-ui/core/Avatar";
import { useTranslation } from "react-i18next";
import button_styles from "../../style/button";
import card_styles from "../../style/card";
import link_styles from "../../style/links";
import grid_styles from "../../style/grids";
import { HOST } from "../../config";
import { getFamily, getItemFromAll } from "../../libs/product";
import EditOrderDialog from "../Base/EditOrderDialog";
import Addresses from "../Base/QueryContainer/Addresses";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import compact from "lodash/compact";
import Products from "../Base/QueryContainer/Products";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import ReactHtmlParser from "react-html-parser";
import StripeSubscription from "./StripeSubscription";
import { labelLookup } from "../Base/OrderFrequency";

const Item = (props: any) => {
  const { t } = useTranslation();
  const classes: any = card_styles();
  const button_classes = button_styles();
  const link_classes = link_styles();
  const grid_classes = grid_styles();
  const {
    order,
    frequencies,
    weekdays,
    gotNutly,
    subscription,
    isPrivate,
    // refetch
  } = props;
  const months = [
    t("general.january"),
    t("general.february"),
    t("general.march"),
    t("general.april"),
    t("general.may"),
    t("general.june"),
    t("general.july"),
    t("general.august"),
    t("general.september"),
    t("general.october"),
    t("general.november"),
    t("general.december"),
  ];

  const pauses = uniqWith(
    props.order.tempchanges
      .filter((o: any) => !!o.ispaused)
      .map((o: any) => {
        return { from: o.from, to: o.to };
      })
      .slice(0, 3),
    isEqual
  );

  return (
    <Grid item sm={12} className={classes.jumbotronContainter}>
      <Card className={classes.jumbotron}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className={classes.swapMedia}>
              {props.family.item.images.length > 1 && (
                <CardMedia
                  className={`${classes.swapMediaOverlay}`}
                  image={`${HOST}${props.family.item.images[1]}`}
                  title={props.family.item.name}
                />
              )}
              {props.family.item.images.length > 0 && (
                <CardMedia
                  className={`${classes.media} swapimg`}
                  image={`${HOST}${props.family.item.images[0]}`}
                  title={props.family.item.name}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <div className={classes.card_condensed_content}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3">
                    {props.family.item.name}{" "}
                    {props.family.item.variants?.length > 1
                      ? props.variant.variant.name
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {props.family.item.description}
                  </Typography>
                  <Grid container spacing={2} style={{ marginTop: 32 }}>
                    {!isPrivate && (
                      <Grid item xs={12} lg={6}>
                        <EditOrderDialog
                          className={`${button_classes.block} ${link_classes.normal_text}`}
                          order={props.order}
                          refetch={props.refetch}
                          isPrivate={isPrivate}
                        >
                          <div className={`${link_classes.pause_order}`}>
                            <Avatar className={`${classes.avatar} editIcon `}>
                              <EditIcon fontSize="default" />
                            </Avatar>
                            <span className="pauseText">
                              {t("orders.editorder")}
                            </span>
                          </div>
                        </EditOrderDialog>
                      </Grid>
                    )}
                    <Grid item xs={12} lg={6}>
                      <span
                        className={`${button_classes.block} ${link_classes.normal_text}`}
                        onClick={() =>
                          props.editOrderAddress({
                            product: props.family.item,
                            order,
                            frequencies,
                            weekdays,
                          })
                        }
                      >
                        <div className={`${link_classes.pause_order}`}>
                          <Avatar
                            className={`${classes.avatar} editLocationIcon `}
                          >
                            <EditLocationIcon fontSize="default" />
                          </Avatar>
                          <span className="pauseText">
                            {t("orders.editaddress")}
                          </span>
                        </div>
                      </span>
                      <div className={classes.continuousDeliveryAddress}>
                        <Typography variant="body2">
                          {props.order.customername}
                        </Typography>
                        <Typography variant="body2">
                          {props.order.shiptoline1}
                        </Typography>
                        <Typography variant="body2">
                          {props.order.shiptozip} {props.order.shiptocity}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className={grid_classes.jumbotronTexts}
                  >
                    {!!gotNutly && (
                      <Grid item xs={12}>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <Tooltip
                            title={ReactHtmlParser(
                              `<div>${t("orders.containsproducthelper")}</div>`
                            )}
                          >
                            <InfoIcon></InfoIcon>
                          </Tooltip>
                          <span>{t("orders.containsproduct")}</span>
                        </span>
                      </Grid>
                    )}
                    {!!pauses.length && (
                      <Grid item xs={12}>
                        <React.Fragment>
                          <strong>
                            <p style={{ margin: 0 }}>
                              {t("orders.pausedatesmessage")}
                            </p>
                          </strong>
                          {pauses.map((pause: any, idx: number) => {
                            const from = new Date(pause.from);
                            const to = new Date(pause.to);
                            return (
                              <p key={`pause-${idx}`} style={{ margin: 0 }}>
                                {months[from.getMonth()]} {from.getDate()}{" "}
                                {t("general.to")} {months[to.getMonth()]}{" "}
                                {to.getDate()}
                              </p>
                            );
                          })}
                        </React.Fragment>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <StripeSubscription subscription={subscription} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

const Jumbotron = (props: any) => {
  const classes = card_styles();
  const {
    orders,
    stripeSubscriptions,
    frequencies: optionsFrequencies,
    days: optionsDays,
  } = props;

  if (!orders) {
    return (
      <Grid item sm={12}>
        <Card className={classes.jumbotron}>
          User did not make any continuous order.
        </Card>
      </Grid>
    );
  }

  var frequencies = {};
  optionsFrequencies.forEach((option: any) => {
    frequencies = { ...frequencies, [option.value]: labelLookup[option.label] };
  });

  var weekdays = {};
  optionsDays.forEach((option: any) => {
    weekdays = { ...weekdays, [option.value]: option.label };
  });

  return (
    <Products>
      {({ getProducts }: any) => {
        return (
          <Addresses {...props}>
            {({ editOrderAddress }: any) => {
              return orders
                .map((order: any) => {
                  const variant: any = getItemFromAll(
                    order.productid,
                    props.products
                  );
                  const family: any = getFamily(variant.slug, props.products);

                  if (!order.orderfrequency) {
                    return null;
                  }

                  var others: any = order.order_details.map(
                    (detail: any) => detail.otherproducts
                  );
                  others = Array.prototype.concat.apply([], others);

                  const allProducts: any[] = getProducts();
                  var otherProducts = others.map((productId: String) => {
                    const product: any = allProducts.find(
                      (p: any) => p.id === productId
                    );
                    if (product && product.price <= 10) return product.name;
                    return null;
                  });
                  otherProducts = compact(otherProducts);

                  const subscription = order?.subscriptionId
                    ? stripeSubscriptions?.find(
                        (s: any) => s.id === order.subscriptionId
                      )
                    : null;

                  const key = `${order.productid} - ${order?.order_details?.map(
                    (detail: any) => detail.orderid
                  )}`;

                  return (
                    <Item
                      variant={variant}
                      family={family}
                      key={key}
                      products={props.products}
                      order={order}
                      refetch={props.refetch}
                      editOrderAddress={editOrderAddress}
                      frequencies={frequencies}
                      weekdays={weekdays}
                      gotNutly={!!otherProducts && !!otherProducts.length}
                      subscription={subscription}
                      isPrivate={props?.isPrivate ?? false}
                    />
                  );
                })
                .filter((order: any) => !!order);
            }}
          </Addresses>
        );
      }}
    </Products>
  );
};

export default Jumbotron;
