import React from 'react';
import Grid from '@material-ui/core/Grid';
import ResetPasswordForm from './ResetPasswordForm';
import grid_style from '../../style/grids';

const ResetPassword: React.FC = (props: any) => {
  const grid_classes = grid_style();
  return (
    <div className={grid_classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} xl={4} lg={3} md={2}></Grid>
        <Grid item xs={12} sm={12} xl={4} lg={6} md={8}>
          <ResetPasswordForm {...props} />
        </Grid>
        <Grid item xs={12} sm={12} xl={4} lg={3} md={2}></Grid>
      </Grid>
    </div>
  );
};

export default ResetPassword;
