import React from 'react';
import Slider from 'react-slick';
import banner_style from '../../style/banner';
import { Link } from 'react-router-dom';
import { HOST } from '../../config';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

export default function Banner(props: any) {
  const { images } = props;
  const bannerImages: Image[] = images?.filter((image: Image) => image.type === 'Banner Image');
  const banner_classes = banner_style();

  const desktopBanners = bannerImages.filter(image => image.url.includes('desktop-'));
  const mobileBanners = bannerImages.filter(image => image.url.includes('mobile-'));



  const links: Array<string> = [
    '/products',
  ];

  return (
    <Slider {...carouselSettings} className={`${banner_classes.slider_container}`}>
      {
        [0].map((idx: number) => {
          return (
            <div key={idx}>
              <Link to={links[idx]}>
                <img src={`${HOST}${desktopBanners[idx].url}`}
                  className={`${banner_classes.banner_image} desktop`}
                  alt={`Banner-${idx}-Desktop`}
                />
                <img src={`${HOST}${mobileBanners[idx].url}`}
                  className={`${banner_classes.banner_image} mobile`}
                  alt={`Banner-${idx}-Mobile`}
                />
              </Link>
            </div>
          )
        })
      }
    </Slider>
  );
}
