import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import card_styles from '../../style/card';
import button_styles from '../../style/button';
import link_styles from '../../style/links';
import { HOST } from '../../config';
import Price from '../Utils/Price';
import { gtmEvents } from '../../libs/tagmanager';
import EditOrderDialog from '../Base/EditOrderDialog';
import RenderText from '../Utils/TextUtils';

const UpSellCard = (props: any) => {
  const { t } = useTranslation();
  const classes = card_styles();
  const button_classes = button_styles();
  const link_classes = link_styles();

  const gtmProductClick = () => {
    gtmEvents([{
      'name': props.upSellItem.family.name,
      'id': props.upSellItem.family.variants[0].productId,
      'price': props.upSellItem.family.variants[0].price,
      'brand': 'Fruitbox',
      'category': props.upSellItem.categorySlug,
      'variant': props.upSellItem.family.variant_type
    }],'product_click','cross sell');
  }

  return (
    <Grid item sm={12} onClick={gtmProductClick}>
      <Card className={classes.cardUpSell}>
        <div className={classes.upSellTag}>
          {t('dashboard.upselltag')}
        </div>
        <Link to={`/products/${props.upSellItem.categorySlug}/${props.upSellItem.family.slug}`}>
          <div className={classes.swapMedia}>
            {( (props.upSellItem.family.images.length > 1) &&
              <CardMedia
                className={`${classes.swapMediaOverlay}`}
                image={`${HOST}${props.upSellItem.family.images[1]}`}
                title={props.upSellItem.family.name} />
            )}
            {( (props.upSellItem.family.images.length > 0) &&
              <CardMedia
                className={`${classes.media} swapimg`}
                image={`${HOST}${props.upSellItem.family.images[0]}`}
                title={props.upSellItem.family.name} />
            )}
          </div>
        </Link>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Link
                to={`/products/${props.upSellItem.categorySlug}/${props.upSellItem.family.slug}`}
                className={link_classes.normal_text}>
                <Typography variant="h4">
                  {props.upSellItem.family.name} - {props.upSellItem.variant.name}
                  &nbsp;
                  <Price amount={props.upSellItem.variant.price} />
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} className={classes.productDescription}>
              {RenderText(props?.upSellItem?.family?.description ?? '')}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <EditOrderDialog
            className={`${button_classes.block} ${link_classes.normal_text}`}
            orders={props.orders}
            upSellItem={props.upSellItem}
            refetch={props.refetchOrders}
            isPrivate={props?.isPrivate ?? false}
          >
            <Button
              className={`${button_classes.noRadius} ${button_classes.default}  ${button_classes.block}`}
              variant="contained"
              color="primary">
              {t('dashboard.changetoproduct')}
            </Button>
          </EditOrderDialog>
        </CardActions>
      </Card>
    </Grid>
  );
}

export default UpSellCard;
