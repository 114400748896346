import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import InvoiceItemDetail from './InvoiceItemDetail';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import grid_style from '../../style/grids';
import paper_style from '../../style/paper';
import { getItem, getFamily } from '../../libs/product';
import AdditionalFields from './AdditionalFields';
import SubmitOrder from './SubmitOrder';
import Coupon from './Coupon';
import PriceWithTax from '../Utils/PriceWithTax';

const InvoiceSummary: React.FC = (props: any) => {
  const { t } = useTranslation();
  const grid_classes = grid_style();
  const paper_classes = paper_style();
  const [couponCode, setCouponCode] = useState('');
  const [additionalValues, setAdditionalValues] = useState({
    orderInformation: '',
    orderReference: '',
  });


  let totalCost = 0;
  props.cart.items.forEach((product: any, idx: number) => {
    const family_detail: any = getFamily(product.family, props.products);
    const product_detail: any = getItem(product.productId, family_detail.item);
    product.details.forEach((item: any) => {
      totalCost += product_detail.price * item.quantity;
    });
  })

  if (props.cart.items.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Grid container className={`${grid_classes.root} ${grid_classes.top_content_container}`}>
        <Grid item sm={12}>
          <Typography variant="h3">
            {t('orders.summary')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} xs={12}>
          <Grid container>
            <Grid item sm={12}>
              <Paper className={`${paper_classes.paper} ${paper_classes.login_container}`}>
                <Grid container spacing={2}>
                  <Grid item xs={8} sm={8}>
                    <Typography variant="h5">{t('general.name')}</Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} className={grid_classes.align_right}>
                    <Typography variant="h5">{t('general.total')}</Typography>
                  </Grid>
                </Grid>
                {(
                  props.cart.items.map((product: any) => {
                    const family: any = getFamily(product.family, props.products);
                    return (
                      <InvoiceItemDetail
                        user={props.user}
                        isSignedIn={props.isSignedIn}
                        key={product.id}
                        productFamily={family}
                        orderDetails={product}
                        cartId={product.id}
                        cart={props.cart}
                        updateCart={props.updateCart} />
                    );
                  })
                )}
                <Grid container>
                  <Grid item xs={12} sm={12} className={grid_classes.align_right}>
                    <PriceWithTax subTotal={totalCost} withTax={!props.isSignedIn || props.isPrivate}/>
                  </Grid>
                </Grid>
                <AdditionalFields
                  additionalValues={additionalValues}
                  setAdditionalValues={setAdditionalValues}
                />
                <Coupon
                  couponCode={couponCode}
                  setCouponCode={setCouponCode}
                  isBusy={props.isBusy}
                  setIsBusy={props.setIsBusy}/>
              </Paper>
              <Paper className={`${paper_classes.paper} ${paper_classes.login_container}`}>
                <SubmitOrder
                  couponCode={couponCode}
                  cart={props.cart}
                  user={props.user}
                  products={props.products}
                  isBusy={props.isBusy}
                  totalCost={totalCost}
                  setAgreed={props.setAgreed}
                  agreed={props.agreed}
                  additionalValues={additionalValues}
                  setIsBusy={props.setIsBusy}
                  submitSuccess={props.submitSuccess}
                  signupValues={props.signupValues}
                  emitCheckoutAction={props.emitCheckoutAction}
                  setSubmitSuccess={props.setSubmitSuccess}
                  isSignedOut={props.isSignedOut}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default InvoiceSummary;
