import forEach from 'lodash/forEach';
import isUndefined from 'lodash/isUndefined';
import keys from 'lodash/keys';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';

import { PRODUCTS_ORDER, PRODUCT_ADD_ONS } from '../config';

/*
 * Products library
 */

export function getCategoryTitle(slug: string, data: any) {
  const ctg = data.categories.find((el: any) => el.slug === slug);
  return ctg ? ctg.name : '';
}

export function getCategoryItems(slug: string, data: any, include_machines:boolean) {
  var families : any = [];
  if (slug) {
    const ctg = data.categories.find((el: any) => el.slug === slug);
    ctg.families.forEach((el2: any) => (families.push({item: el2, slug: slug})));
    if (include_machines) {
      const cat_machines = data.categories.find((cat:any) =>
        cat.families &&
        cat.families.length &&
        cat.families[0].variant_type==="payment" &&
        cat.slug !== slug
      );
      if (cat_machines) {
        cat_machines.families.forEach((fam:any) => {
          families.push({item: fam, slug: slug});
        });
      }
    }
  } else {
    let hedelmaboxit = 0;
    const indices = data.categories.map((category: any, i: number) => {
      if (category.slug === 'hedelmaboxit') {
        hedelmaboxit = i;
      }
      return i;
    });
    indices.splice(hedelmaboxit, 1);
    indices.unshift(hedelmaboxit);
    indices.forEach((i: number) => {
      const el = data.categories[i];
      el.families.forEach((el2: any) => {
        var maxPrice = 0;
        el2.variants.forEach((variant:any) => {
          if (variant.price > maxPrice) maxPrice = variant.price;
        });
        if (maxPrice > 0)
          families.push({item: el2, slug: el.slug});
      });
    });
  }
  // Sorting based on the configuration file. If does not exist
  // in the configuration, place at last.
  families = sortBy(families, (family) => {
    const index = PRODUCTS_ORDER.indexOf(family.item.slug);
    if (index < 0)
      return PRODUCTS_ORDER.length;
    return index;
  });
  return families;
}

export function getFamily(slug: string, data: any) {
  let product = {item: {}, slug: ''}
  data.categories.forEach((el: any) => {
    el.families.forEach((el2: any) => {
      if (el2.slug === slug) {
        product = {item: el2, slug: el.slug};
      }
    });
  });
  return product;
}

export function getItem(id: string, data: any) {
  let item = {};
  data.variants.forEach((el: any) => {
    if (el.productId === id) {
      item = el;
    }
  });
  return item;
}

export function getItemFromAll(id: string, data: any) {
  let item = {variant: {}, categorySlug: '', slug: '', family: {}};
  data.categories.forEach((el: any) => {
    el.families.forEach((el2: any) => {
      el2.variants.forEach((el3: any) => {
        if (el3.productId === id) {
          item = {variant: el3, categorySlug: el.slug, slug: el2.slug, family: el2};
        }
      });
    });
  });
  return item;
}

export interface UpsellItem {
  categorySlug: string;
  family: any;
  variant: any;
  price: number;
  uomid: string;
}

export function getUpSell(orders: any, products: any, numItems: number = 3): Array<UpsellItem> {
  var upSell: Array<UpsellItem> = [],
        allCategories: any = {},
        allProducts: any = {},
        categories: any = {};
  // Normalize categories and products
  products.categories.forEach(({ families, name, slug }: any) => {
    allCategories[slug] = {
      name,
      products: {}
    };
    families.forEach((family: any) => {
      family.variants.forEach((variant: any) => {
        allProducts[variant.productId] =
        allCategories[slug].products[variant.productId] = {
          ...family,
          category: slug,
          variant
        };
      });
    })
  });

  // Get all prices by categories
  orders?.forEach((order: any) => {
    const slug = allProducts[order.productid].category;
    if (isUndefined(categories[slug])) {
      categories[slug] = [];
    }
    if (categories[slug].indexOf(order.priceperunit) < 0) {
      categories[slug].push(order.priceperunit);
    }
  });

  // List all products that are more expensive than maxPrice per category
  forEach(categories, (prices: Array<number>, slug: string) => {
    const maxPrice: number = Math.max(...prices),
      category = allCategories[slug];
    keys(category.products).forEach((key: string) => {
      const product = category.products[key];
      if (product.variant.price > maxPrice) {
        upSell.push({
          categorySlug: slug,
          family: product,
          variant: product.variant,
          price: product.variant.price,
          uomid: product.variant.uomid
        });
      }
    });
  });

  // Append upsell per product family.
  const mainUpSellProductIds:String[] = upSell.map((item:UpsellItem) => {
    return item.variant.productId;
  });
  const currentOrders:any = sortBy(orders.map((order:any) => {
    return {
      productid: order.productid,
      priceperunit: order.priceperunit
    };
  }), 'priceperunit');
  const ordersProductIds:String[] = currentOrders.map((order:any) => order.productid);
  const arrProducts:Array<any> = keys(allProducts).map((key:string) => {
    return allProducts[key];
  });
  currentOrders.forEach((currentOrder:any) => {
    arrProducts
      .filter((product:any) => {
        return product.iscontinuouspossible &&
          product.name === allProducts[currentOrder.productid].name &&
          product.variant.price > currentOrder.priceperunit &&
          !ordersProductIds.includes(product.variant.productId);
      })
      .forEach((product:any) => {
        if (!mainUpSellProductIds.includes(product.variant.productId)) { // Prevent duplicate.
          upSell.push({
            categorySlug: product.category,
            family: product,
            variant: product.variant,
            price: product.variant.price,
            uomid: product.variant.uomid
          });
        }
      });
  });

  // Sort from cheapest
  // return sortBy(upSell, 'price').slice(0, numItems);

  return sortBy(
    compact(
      upSell
        .map((item: UpsellItem) => {
          let o = orders.find((order:any) => {
            return order.uomid === item.variant.uomid;
          });
          if (o) return item;
          else return null;
        })
    )
  ).slice(0, numItems);
}

export function getCrossSell(
  orders: any,
  cart: any,
  products: any,
  numItems: number = 4,
  includeAddOns: boolean = false
) {

  let crossSellList : any = [];
  let addOnsList : any = [];
  const orderSlugs : any = [];

  if (orders) {
    orders.forEach((el: any) => {
      const q = getItemFromAll(el.productid, products)
      orderSlugs.push(q.slug);
    });
  }

  if (cart && cart.items) {
    cart.items.forEach((el: any) => {
      orderSlugs.push(el.family);
    });
  }

  for(const el of products.categories) {
    for(const el2 of el?.families) {
      const {
        slug,
        variants,
        iscontinuouspossible
      } = el2;
      const maxPrice = Math.max(variants.map((variant: any) => variant.price));

      if (
        !PRODUCT_ADD_ONS.includes(slug) &&
        !orderSlugs.includes(slug) &&
        maxPrice > 0
      ) {
        crossSellList.push({
          categorySlug: el.slug,
          slug,
          family: el2,
          iscontinuouspossible
        });
      }

      // Add-ons as top priority.
      if ( includeAddOns
        && iscontinuouspossible
        && PRODUCT_ADD_ONS.includes(slug)
      ) {
        addOnsList.push({
          categorySlug: el.slug,
          slug,
          iscontinuouspossible,
          family: el2,
          isExtra: true
        })
      }
    }
  }

  // Prioritize continuous products first.
  sortBy(crossSellList, 'iscontinuouspossible');

  if (!!addOnsList.length) {
    addOnsList = compact(
      addOnsList.map((item: any) => {
        const uomIds = uniq(orders?.map((order: any) => order?.uomid) ?? []);
        const { uomid } = item?.family?.variants?.[0] ?? {};
        return uomIds.includes(uomid) ? item : null;
      })
    );

    if (!!addOnsList.length) {
      crossSellList = uniqBy([...addOnsList, ...crossSellList], 'slug');
    }
  }

  return crossSellList.slice(0, numItems);
}

export function getProductIds(products: any) {
  const productIds:string[] = [];
  products?.categories?.forEach((el:any) => {
    el.families.forEach((el2:any) => {
      el2.variants.forEach((el3:any) => {
        productIds.push(el3.productId);
      });
    });
  });
  return productIds;
}

export function assignImages(products: any, images: Image[]) {
  products?.categories?.forEach((category: any) => {
    category?.families?.forEach((family: any) => {
      const { name } = family;
      const productImages = images?.filter(image => image.productfamily === name);
      family.images = productImages?.map(image => image.url) ?? [];
    });
  });
  return products;
}

export function shuffleArray(array: any) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}
