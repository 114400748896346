import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const AdditionalFields = (props: any) =>  {
  const { t } = useTranslation();

  const { additionalValues , setAdditionalValues } = props;

  const handleChange = (event: any) => {
    event.persist();
    setAdditionalValues({
      ...additionalValues,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Grid container style={{marginTop: '24px'}}>
      <Grid item sm={12}>
        <Typography variant="h5">{t('orders.orderadditionalinfo')}</Typography>
      </Grid>
      <Grid item sm={12}>
      <TextField
        autoComplete=""
        id="orderInformation"
        name="orderInformation"
        value={additionalValues.orderInformation}
        onChange={handleChange}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }} />
      </Grid>
    </Grid>
  );
}

export default AdditionalFields;
