import { makeStyles } from '@material-ui/core/styles';

const banner_style = makeStyles((theme) => ({
  slider_container: {
    '& .slick-dots': {
      position: 'absolute',
      right: '0',
      bottom: '5px',
      width: 'fit-content',
      '& li button::before': {
        fontSize: '14px',
        color: 'rgba(87, 87, 87, 1)'
      }
    }
  },
  banner_image: {
    width: '970px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    '&.desktop': {
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    '&.mobile': {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    }
  }
}));

export default banner_style;
