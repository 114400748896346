import React, { useState, useEffect, useRef } from 'react';

import { Query } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import CardFields from './CardFields';
import OrderSummary from './OrderSummary';
import InvoiceSummary from './InvoiceSummary';
import SimpleLoader from '../Loader/SimpleLoader';
import { PRODUCTS_QUERY } from '../../query/products';
import SubmitOrderSuccess from './SubmitOrderSuccess';
import { CHECKOUT_REGISTRATION_STAGES } from '../../libs/order';
import grid_style from '../../style/grids';
import { gaCheckoutSteps } from '../../libs/tagmanager';
import { useTranslation } from "react-i18next";
import { useCreateOrder } from '../Contexts/Order';
import Addresses from "../Base/QueryContainer/Addresses";

function CheckoutAnon(props: any) {
  const { t } = useTranslation();
  const { cart, products } = props;
  const childRef = useRef<any>();
  const grid_classes = grid_style();
  const [isBusy, setIsBusy] = useState(false);
  const { agreed, setAgreed }= useCreateOrder();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [signupValues, setSignupValues] = useState({ accountType: 1,paymentMethodId:''});
  const [paymentMethod, setPaymentMethod] = useState('');
  const [signupStage, setSignupStage] = useState(CHECKOUT_REGISTRATION_STAGES.waiting);
  
  useEffect(() => {
    gaCheckoutSteps(products, cart, 2);
  }, [cart,products]);

  const getSignupValues = (values: any) => {
    setSignupValues({...values, paymentMethodId: paymentMethod})
    setIsBusy(values.isBusy)
  };

  // Check if user is private and then generate payment method token
  // Otherwise sumbit the form.
  const checkoutSubmitAction = (action:any) => {
    if(childRef && signupValues.accountType === 2) {
      setSignupStage(CHECKOUT_REGISTRATION_STAGES.verify);
      childRef.current.createToken(action);
    }
    else {
      setSignupStage(CHECKOUT_REGISTRATION_STAGES.ready);
    }
  }

  const executeRegistraion = (paymentMethodId?: string, error?: boolean) => {
    setSignupStage(error ? CHECKOUT_REGISTRATION_STAGES.verify: CHECKOUT_REGISTRATION_STAGES.ready);
    setPaymentMethod(paymentMethodId ?? '')
  }

  if (submitSuccess) {
    return <SubmitOrderSuccess 
      isPrivate={props.isPrivate}
      isSignedIn={props.isSignedIn}
      onEnter={props.onEnter}
      updateCart={props.updateCart}
    />
  }

  return (
    <Query<any> query={PRODUCTS_QUERY}>
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <SimpleLoader />
          );
        }
        if (error) return null;
        const products = data;
        return (
          <React.Fragment>
            <Grid container spacing={2} className={`${grid_classes.root}`}>
              <Grid item xs={12} sm={12} md={8}>
                  <Addresses {...props}>
                    {({ addresses, editAddress }: any) => {
                      return (
                        <OrderSummary
                          {...props}
                          editAddress={editAddress}
                          addresses={addresses}
                          products={props.products}
                          isBusy={isBusy}
                          setIsBusy={setIsBusy}
                          emitSignupValues={getSignupValues}
                          signupStage={signupStage}
                          setSignupStage={setSignupStage}
                        />
                      );
                    }}
                  </Addresses>
                  {signupValues.accountType === 2 && (
                    <CardFields 
                      headerLabel={t("profile.payment")}
                      ref={childRef}
                      isBusy={isBusy}
                      executeRegistraion={executeRegistraion} 
                      newUser={true}
                      setIsBusy={setIsBusy}
                    />
                  )}
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InvoiceSummary {...props}
                  products={products}
                  isBusy={isBusy}
                  setIsBusy={setIsBusy}
                  submitSuccess={submitSuccess}
                  setSubmitSuccess={setSubmitSuccess}
                  signupValues={signupValues}
                  emitCheckoutAction={checkoutSubmitAction}
                  isSignedOut={true}
                  paymentMethod={paymentMethod}
                  setAgreed={setAgreed}
                  agreed={agreed}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
      }}
    </Query>
  );
}

export default CheckoutAnon;
