import americanExpressLogo from "../../assets/svgs/american-express.svg";
import mastercardLogo from "../../assets/svgs/mastercard.svg";
import visaLogo from "../../assets/svgs/visa.svg";
import visaElectronLogo from "../../assets/svgs/visa-electron.svg";

const CardImages = [
  { brand: "visa", logo: visaLogo },
  { brand: "visa_electornics", logo: visaElectronLogo },
  { brand: "amex", logo: americanExpressLogo },
  { brand: "mastercard", logo: mastercardLogo },
];

export default CardImages;