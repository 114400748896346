import isFunction from 'lodash/isFunction';
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

export default function ConfirmationDialog(props: any) {
  const { t } = useTranslation();
  const [busy, setBusy] = React.useState(false);

  function handleNo() {
    if (!busy) {
      // Should not be possible to close when busy
      props.setOpen(false);
    }
  }

  function handleYes() {
    const response: any = props.yesAction();
    if (!response || !isFunction(response.then) || !isFunction(response.catch)) {
      props.setOpen(false);
    } else {
      setBusy(true);
      response.catch(() => null).then(() => {
        setBusy(false);
        props.setOpen(false);
      });
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={props.open}
      scroll={`body`}
      onClose={handleNo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{props.contentTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={busy} onClick={handleNo} color="primary">
          {t('buttons.no')}
        </Button>
        <Button disabled={busy} onClick={handleYes} color="primary" autoFocus>
          {t('buttons.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
