import { makeStyles } from '@material-ui/core/styles';

const form_style = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  menuItemPlaceHolder: {
    color: '#969494',
    fontSize: '0.9em'
  },
  infoIcon: {
    fontSize: '16px',
    color: 'black',
    verticalAlign: 'sub',
    marginRight: '3px'
  },
  infoMessage: {
    color: 'black'
  }
}));

export default form_style;
