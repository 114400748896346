import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CartIcon from '../../components/Checkout/CartIcon';
import grid_style from '../../style/grids';
import { appmenu_style } from '../../style/appmenu';
import { nav_anon, nav_user, nav_funnel } from './nav';
import LeftDrawer from './LeftDrawer';
import container_style from '../../style/container';
import { AppState } from '../../App';

const MenuAnon: React.FC = (props: any) => {
  const classes = appmenu_style();
  const { t } = useTranslation();

  return (
    <AppState.Consumer key={`menu-anon`}>
      {({ state }) => {
        const use_nav = state.funnel ? nav_funnel : nav_anon;
        return (
          <React.Fragment>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {
                use_nav.map((menu: any, idx: number) => {
                  if (menu.label !== '') {
                    const style: any = {};
                    var styleArrow: any = {
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '6px',
                      color: '#000'
                    }
                    if (state.funnel && (state.funnel !== (idx + 1))) {
                      style.fontWeight = 200;
                    }
                    const displayArrow = state.funnel > 0 && idx > 0;
                    if (menu.label === 'menu.cart' && props.cart.items.length === 0) {
                      return null;
                    } else {
                      return (
                        <React.Fragment key={idx}>
                          <Link key={idx} to={menu.link} className={classes.drawer_links}>
                            {
                              (menu.label === 'menu.cart') ?
                                <CartIcon items={props.cart.items} />
                                : <Button className={classes.menuNavButton} style={style}>
                                  {(menu.label === 'menu.step2' && props.isSignedIn) ?
                                    t(`menu.step2logggedIn`)
                                    : t(`${menu.label}`)}
                                </Button>
                            }
                          </Link>
                          {displayArrow &&
                            <span style={styleArrow}>{'>'}</span>
                          }
                        </React.Fragment>
                      );
                    }
                  }
                  return null;
                })
              }
            </div>
          </React.Fragment>
        );
      }}
    </AppState.Consumer>
  );
}

const MenuUser: React.FC = (props: any) => {
  const classes = appmenu_style();
  const grid_classes = grid_style();
  const { t } = useTranslation();

  return (
    <AppState.Consumer key={`menu-user`}>
      {({ state }) => {
        let use_nav = state.funnel ? nav_funnel : nav_user;
        if (props.history.location.state) {
          if (props.history.location.state.funnel === 1) {
            use_nav = nav_funnel;
          }
        }
        return (
          <Grid container className={grid_classes.root} spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>
              <div className={classes.sectionDesktop}>
                {
                  use_nav.map((menu: any, idx: number) => {
                    if (menu.label !== '') {
                      const style: any = {};
                      const displayArrow: boolean = idx < (use_nav.length - 1) && state.funnel > 0;
                      var styleArrow: any = {
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '6px',
                        color: '#000'
                      };
                      if (state.funnel && (state.funnel !== (idx + 1))) {
                        style.fontWeight = 200;
                      }
                      if (menu.label === 'menu.cart' && props.cart.items.length === 0) {
                        return null;
                      } else {
                        return (
                          <React.Fragment key={idx}>
                            <Link key={idx} to={menu.link} className={classes.drawer_links}>
                              {
                                (menu.label === 'menu.cart') ?
                                  <CartIcon items={props.cart.items} />
                                  : <Button className={classes.menuNavButton} style={style}>
                                    {(menu.label === 'menu.step2' && props.isSignedIn) ?
                                      t(`menu.step2logggedIn`)
                                      : t(`${menu.label}`)}
                                  </Button>
                              }
                            </Link>
                            {displayArrow &&
                              <span style={styleArrow}>{'>'}</span>
                            }
                          </React.Fragment>
                        );
                      }
                    }
                    return null;
                  })
                }
              </div>
            </Grid>
          </Grid>
        );
      }}
    </AppState.Consumer>
  );
}

function AppMenu(props: any) {
  const classes = appmenu_style();
  const container_classes = container_style();
  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appbar}>
        <Container maxWidth="xl" fixed className={container_classes.body}>
          <Toolbar disableGutters className={classes.toolbar}>
            <div className={classes.sectionMobile}>
              <LeftDrawer {...props} />
            </div>
            <Link to='/' className={classes.brand}>
              <img src="/assets/fruitbox_logo.svg" alt="fruitbox" />
            </Link>
            {!!props.isSignedIn ?
              <MenuUser {...props} /> :
              <MenuAnon {...props} />
            }
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default AppMenu;
