import { makeStyles } from '@material-ui/core/styles';

const history_styles = makeStyles(theme => ({
  header: {
    marginTop: '10px'
  },
  itemContainer: {
    paddingLeft: '10px'
  },
  orderTypeLabel: {
    marginTop: '-9px',
    marginBottom: '10px',
    fontWeight: 'bold'
  }
}));

export default history_styles;
