import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import OrderForm from './OrderForm';
import card_styles from '../../style/card';
import grid_style from '../../style/grids';
import { getFamily, getItem } from '../../libs/product';
import Slider from '../Base/Slider';
import Price, { addVatToPrice } from '../Utils/Price';
import { parseQueryString } from '../../libs/utils';
import { gtmEvents } from '../../libs/tagmanager';
import RenderText from '../Utils/TextUtils';

const ItemDetails: React.FC = (props: any) => {
  const classes = card_styles();
  const grid_classes = grid_style();
  const family: any = getFamily(props.match.params.family, props.products);
  const params: any = parseQueryString(props.location.search);
  const [count, setCount] = React.useState(0);
  const defaultItem = (count || isEmpty(params)) ? (family.item.variants ? family.item.variants[0] : { id: '', price: 0, uomid: '' }) : {
    id: params.variant,
    price: ((getItem(params.variant, family.item) || {}) as any).price
  };
  let orderDetails = null;
  if (!count) {
    setCount(count + 1);
  }
  if (params.frequency) {
    orderDetails = {
      isupsell: (params.isupsell === '1'),
      orderOption: params.frequency,
      details: compact(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day: any) => {
        if (!params[day]) {
          return null;
        }
        return {
          day: day,
          quantity: params[day]
        }
      }))
    };
  }
  
  const [productVariant, setProductVariant] = React.useState({
    productId: defaultItem.id || defaultItem.productId,
    price: defaultItem.price,
    uomid: defaultItem.uomid ?? ''
  });

  useEffect(() => {
    gtmEvents([{
      'name': family.item.name,
      'id': productVariant.productId,
      'price': productVariant.price,
      'brand': 'Fruitbox',
      'category': family.slug,
      'variant': family.item.variant_type
    }], 'product_detail_impression', 'product');
  }, [  family.item.name,
    productVariant.productId,
    productVariant.price,
    family.slug,
    family.item.variant_type
  ]);

  const setSelectedVariant = (id: string) => {
    const i: any = getItem(id, family.item);
    if (i) {
      setProductVariant({
        productId: i.productId,
        price: i.price,
        uomid: i.uomid
      });
    }
  }

  const [totalCost, setTotalCost] = React.useState(0);

  return (
    <Grid container className={`${grid_classes.root} ${grid_classes.top_content_container} ${grid_classes.product_content_container}`}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Card className={classes.card_no_fade}>
              <Slider images={family.item.images} />
            </Card>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h2">{family.item.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" color='secondary'>
                  <Price amount={productVariant.price} />
                </Typography>
                {(props.isPrivate || !props.isSignedIn) &&
                  <Typography variant="h4" color='secondary'>
                    <small>
                      {`ALV (14%) `}<Price amount={ addVatToPrice(productVariant.price)} />
                    </small>
                  </Typography>
                }
              </Grid>
              <Grid item xs={12} className={classes.productDescription}>
                {RenderText(family?.item?.longerDescription ?? '')}
              </Grid>
              <Grid item xs={12}>
                <OrderForm
                  orderDetails={orderDetails} {...props}
                  totalCost={totalCost}
                  setTotalCost={setTotalCost}
                  family={family}
                  defaultVariant={productVariant}
                  newItem={props.newItem}
                  setSelectedVariant={setSelectedVariant} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ItemDetails;
