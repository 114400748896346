import gql from 'graphql-tag';

export const ORDERS_QUERY = gql`
  query Orders {
    orders {
      ordernumber
      orderreference
      productid
      productname
      productslug
      salesorderid
      salesorderdetailid
      uomid
      manualdiscountamount
      priceperunit
      quantity
      deliveryweekday
      orderfrequency
      nextpickupdate
      customername
      shiptoline1
      shiptocity
      shiptozip
      orderstartdate
      orderispaused
      subscriptionid
      tempchanges {
        id
        orderid
        from
        to
        ispaused
        productid
        weekdayid
        quantity
      }
    }

    stripesubscriptions {
      id
      customer
      latest_invoice {
        id
        number
        hosted_invoice_url
        invoice_pdf
        status
      }
    }
  }
`;
