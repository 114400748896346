import { makeStyles } from '@material-ui/core/styles';

const paper_style = makeStyles(theme => ({
  paper: {
    borderRadius: '0px',
    boxShadow: 'none',
  },
  shrinkable: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    }
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  colorPrimaryDark: {
    backgroundColor: theme.palette.primary.dark,
  },
  category: {
    fontSize: '0.875rem',
    lineHeight: '0.9',
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
  login_container: {
    padding: '24px',
    marginTop: '12px'
  },
  error: {
    color: 'red',
    textAlign: 'center',
    fontSize: '0.8rem',
  },
  login_modal: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  }
}));

export default paper_style;
