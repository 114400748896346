import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import grid_style from '../../style/grids';
import loader_style from '../../style/loader';

export default function ComponentLoader() {
  const classes = grid_style();
  const loader_classes = loader_style();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.simpleLoader}>
        <CircularProgress className={loader_classes.componentLoader} />
      </Grid>
    </Grid>
  );
}
