import React from 'react';
import { Query } from 'react-apollo';
import { SALESORDER_OPTIONS_QUERY } from '../../../query/orderOptions';
import SimpleLoader from '../../Loader/SimpleLoader';

/**
 * Weekdays query
 */
const WeekdaysQuery: React.FC = (props: any) => {
  return (
    <Query<any> query={SALESORDER_OPTIONS_QUERY}>
      {({ loading, error, data }) => {
        if (loading) {
          return <SimpleLoader />;
        }
        if (error) return null;

        const deliveryWeekDays = data?.orderPicklists?.find((o: any) => {
          return o.logicalName === 'fs_deliveryweekday';
        })?.options ?? [];

        var weekdays: any = {};
        for (const option of deliveryWeekDays) {
          weekdays = {...weekdays, [option.value]: option.label};
        }

        return props.children(weekdays);
      }}
    </Query>
  );
}

export default WeekdaysQuery;
