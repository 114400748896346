import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  useState,
  createContext,
  useContext,
} from "react";

interface UseOrder {
  user: any;
  setUser: Dispatch<SetStateAction<any>>;
  paymentMethod: any;
  setPaymentMethod: Dispatch<SetStateAction<any>>;
  agreed: boolean;
  setAgreed: Dispatch<SetStateAction<boolean>>;
  editCard: boolean;
  setEditCard: Dispatch<SetStateAction<boolean>>;
  errorFields: string[];
  setErrorFields: Dispatch<SetStateAction<string[]>>;
  additionalValues: any;
  setAdditionalValues: Dispatch<SetStateAction<any>>;
  couponCode: string;
  setCouponCode: Dispatch<SetStateAction<string>>;
}

const CreateCheckoutContext = createContext({});
const CreateOrderProvider = (props: any): ReactElement => {
  const [user, setUser] = useState({ stripepaymentmethods: "" });
  const [paymentMethod, setPaymentMethod] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [editCard, setEditCard] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [additionalValues, setAdditionalValues] = useState({
    orderInformation: "",
    orderReference: "",
  });

  const values: UseOrder = {
    user,
    setUser,
    paymentMethod,
    setPaymentMethod,
    agreed,
    setAgreed,
    errorFields,
    setErrorFields,
    editCard,
    setEditCard,
    additionalValues,
    setAdditionalValues,
    couponCode,
    setCouponCode,
  };

  return <CreateCheckoutContext.Provider value={values} {...props} />;
};

const useCreateOrder = (): UseOrder =>
  useContext(CreateCheckoutContext) as UseOrder;

export { CreateOrderProvider, useCreateOrder };
