interface ImageTypes {
  [type: string]: string;
}

export function parseImages (images:any, imageTypes:any) {
  var types: ImageTypes = {};
  imageTypes?.forEach((imageType: any) => {
    types = {...types, [imageType.value]: imageType.label};
  });
  return images?.map((image: any) => {
    return {...image, type: types[image.type]};
  })
}
