import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import Price from './Price';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import ComponentLoader from '../Loader/ComponentLoader';

interface PriceWithTaxProps {
  subTotal: number;
  withTax: boolean;
}

const query = gql`
  query TaxRate($jurisdiction: String) {
    taxrate(jurisdiction: $jurisdiction) {
      id
      inclusive
      percentage
      display_name
    }
  }
`;

export function PriceWithTax({
  subTotal,
  withTax
}: PriceWithTaxProps): React.ReactElement {
  const { t } = useTranslation();

  if (!withTax) {
    return (
      <React.Fragment>
        <Typography variant="h5">{t('general.total')}:</Typography>
        <Typography variant="h4" color='secondary'>
          <Price amount={subTotal} />
        </Typography>
      </React.Fragment>
    );
  }

  return (
    <Query<any> query={query} variables={{jurisdiction: 'Finland'}} >
      {({loading, error, data}: any) => {
        if (loading) return <ComponentLoader />
        if (error) {
          throw error;
        }

        const {
          display_name: displayName,
          percentage,
          inclusive
        } = data?.taxrate ?? {};
        const taxAmount = (subTotal * (percentage / 100));

        if (inclusive) {
          return (
            <React.Fragment>
              <Typography variant="h5">{`${displayName} (${percentage}%)`}:</Typography>
              <Typography variant="h4" color='secondary'>
                <Price amount={taxAmount} />
              </Typography>
              <br />
              <Typography variant="h5">{t('general.total')}:</Typography>
              <Typography variant="h4" color='secondary'>
                <Price amount={subTotal} />
              </Typography>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <Typography variant="h5">{t('general.subtotal')}:</Typography>
              <Typography variant="h4" color='secondary'>
                <Price amount={subTotal} />
              </Typography>
              <br />
              <Typography variant="h5">{`alv (${percentage}%)`}:</Typography>
              <Typography variant="h4" color='secondary'>
                <Price amount={taxAmount} />
              </Typography>
              <br />
              <Typography variant="h5">{t('general.total')}:</Typography>
              <Typography variant="h4" color='secondary'>
                <Price amount={subTotal + taxAmount} />
              </Typography>
            </React.Fragment>
          );
        }
      }}
    </Query>
  );
}

export default PriceWithTax;
