import React, { Suspense, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Container from "@material-ui/core/Container";
import container_style from "../../style/container";
import routes from "../../routes";
import AppMenu from "./AppMenu";
import { CartProps } from "../../defs/products";
import { cartItemsLoadFromCookie } from "../../libs/cart";
import PageNotFound from "./PageNotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Footer } from "../../components/Footer/Footer";

const DefaultLayout = (props: any) => {
  const container_classes = container_style();
  const [cart, updateCart] = useState<CartProps>({
    items: cartItemsLoadFromCookie(),
  });

  return (
    <React.Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        <AppMenu
          history={props.history}
          isSignedIn={props.isSignedIn}
          cart={cart}
          userEmail={props.userEmail}
        />
      </Suspense>

      <Suspense fallback={<div></div>}>
        <Container maxWidth="xl" fixed className={container_classes.body}>
          <Switch>
            {routes.map((route, idx) => {
              const { restricted, withuser } = route;
              const { isSignedIn } = props;

              const allow =
                !restricted ||
                (restricted && !withuser && !isSignedIn) ||
                (restricted && withuser && isSignedIn);

              if (allow) {
                return route.component ? (
                  <Route
                    key={idx + 1}
                    path={route.path}
                    exact={route.exact}
                    render={(rprops: any) => {
                      return (
                        <route.component
                          {...rprops}
                          cart={cart}
                          updateCart={updateCart}
                          onStateChange={props.onStateChange}
                          isSignedIn={props.isSignedIn}
                          user={props.user}
                          userEmail={props.userEmail}
                          setUserEmail={props.setUserEmail}
                          isPrivate={props.isPrivate}
                          setIsPrivate={props.setIsPrivate}
                        />
                      );
                    }}
                  />
                ) : null;
              } else {
                return null;
              }
            })}
            <Route key={0} component={PageNotFound} />
          </Switch>
        </Container>
        <Footer></Footer>
      </Suspense>
      <ToastContainer />
    </React.Fragment>
  );
};

export default DefaultLayout;
