import React, { Component } from 'react';

import DeliveryAddressDialog from './DeliveryAddressDialog';
import { bind } from '../../libs/utils';

/**
 * Edit Address
 */
export default class EditAddress extends Component<any> {

  constructor(props: any) {
    super(props);
    bind(this);
    this.state = {
      open: false
    };
  }
  
  render() {
    const { children }: any = this.props;
    const open = (this.state as any).open;
    return (
      <>
        {children(this)}
        <DeliveryAddressDialog
          cartId={this.props.cartId}
          cart={this.props.cart}
          address={this.props.address}
          open={open}
          updateCartFn={this.props.updateCartFn}
          saveAddress={this.props.saveAddress}
          setOpen={this.toggle}
          contentTitle={this.props.title} />
      </>
    );
  }

  /**
   * Open
   */
  open() {
    return this.toggle(true);
  }

  /**
   * Toggle
   */
  toggle(open: boolean) {
    return this.setState({
      open
    });
  }
}
