import { fade, makeStyles } from '@material-ui/core/styles';

export const appmenu_style = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  notActive: {
    fontWeight: 200
  },
  appbar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    '& img': {
      width: '157px',
    }
  },
  toolbar: {
    [theme.breakpoints.down('md')]: {
      height: '64px',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginTop: '16px',
    color: theme.palette.primary.main
  },
  title: {
    display: 'block',
    left: '50%',
    position: 'absolute',
    marginLeft: '-35px',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      left: '0%',
      position: 'relative',
      marginLeft: '0px',
    },
  },
  brand: {
    display: 'block',
    left: '50%',
    position: 'absolute',
    marginLeft: '-75px',
    marginTop: '7px',
    width: '120px',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      left: '0%',
      position: 'relative',
      marginLeft: '0px',
      marginTop: '0px',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  menuNavButton: {
    marginLeft: theme.spacing(1),
    textTransform: 'none',
    color: '#000',
    fontSize: '1rem',
    lineHeight: '1',
    fontWeight: 700,
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      background: 'none',
    }
  },
  drawerList: {
    width: 250,
    '& span': {
      fontWeight: 700
    }
  },
  drawer_links: {
    textDecoration: 'none',
    color: '#000',
  },
  rightDrawer: {
    width: 250,
    '& span': {
      fontWeight: 700,
    }
  },
  cartIcon : {
    color: '#fff',
    marginTop: '3px',
    marginLeft: '10px',
    marginRight: '10px'
  },
  cartIconQuantity: {
    position: 'absolute',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    border: '1px solid white',
    borderRadius: '50%',
    top: '-6px',
    right: '5px'
  }
}));
