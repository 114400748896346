import { makeStyles } from '@material-ui/core/styles';

const error_style = makeStyles((theme) => ({
  container: {
    width: '320px',
    height: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '50px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

export default error_style;
