import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  spacing: 6,
  breakpoints: {
    values: {
      xs: 0,
      sm: 376,
      md: 768,
      lg: 1200,
      xl: 1440,
    }
  },
  palette: {
    primary: {
      main: '#f36a36',
    },
    secondary: {
      main: '#ff9e19',
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: [
      'Source Sans Pro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fire Sans',
      'Droid Sans',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 16,
    h1: {
      fontSize: '3.75rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2.8125rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.875rem',
      lineHeight: '1',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.5625rem',
      lineHeight: '1',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: '1',
      fontWeight: 700,
    },
    h6: {
      lineHeight: '1.875rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1.125rem',
      lineHeight: '1.125'
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        borderRadius: '0',
        boxShadow: 'none',
      },
      rounded: {
        borderRadius: '0',
        boxShadow: 'none',
      },
    },
    MuiCardActions: {
      root: {
        padding: 0,
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.35rem',
        color: "#000",
      }
    },
    MuiToolbar: {
      regular: {
        height: '100px',
      }
    },
    MuiStepIcon: {
      active: {
        '& circle' : {
          color: '#fff',
        },
        '& text' : {
          fill: '#e44300',
        }
      },
    }
  }
});

export default theme;
