import { makeStyles } from '@material-ui/core/styles';

const grid_style = makeStyles(theme => ({
  hr: {
    border: '1px solid',
    borderColor: theme.palette.primary.light,
  },
  hr2: {
    border: '0.5px solid',
    opacity: 0.5
  },
  container_padding: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  container_padding_on_smDown: {
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
  },
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  product_content_container: {
    padding: theme.spacing(1),
    background: '#fff',
    [theme.breakpoints.between('xs' ,'sm')]: {
      marginTop: '12px !important'
    },
  },
  simpleLoader: {
    textAlign: 'center',
  },
  loginFooter: {
    textAlign: 'center',
    paddingTop: '24px',
  },
  loginHeader: {
    paddingBottom: '24px',
  },
  loginForgotPassword: {
    paddingTop: '12px',
    paddingBottom: '24px',
  },
  relative: {
    position: 'relative',
  },
  top_content_container: {
    margin: '1.5rem 0 1rem 0'
  },
  align_right: {
    textAlign: 'right',
    '& button': {
      marginRight: '0'
    }
  },
  align_center: {
    textAlign: 'center',
  },
  top_bottom_margin_typo: {
    marginTop: '20px',
    marginBottom: '20px',
    [theme.breakpoints.up('lg')]: {
      marginTop: '0px',
      marginBottom: '12px'
    },
  },
  qtyInputDesktopView: {
      display: 'flex',
    [theme.breakpoints.between('xs' ,'sm')]: {
      display: 'none',
    },
  },
  qtyInputMobileView: {
    display: 'none',
    [theme.breakpoints.between('xs' ,'sm')]: {
      display: 'flex',
    },
  },
  overviewAllProductsLink: {
    textAlign: 'right',
    '& button': {
      marginRight: '0'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      marginTop: '18px'
    }
  },
  checkoutAllProductsLink: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& button': {
      marginRight: '0'
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      marginTop: '18px'
    }
  },
  headerGridMargin: {
    marginTop: '14px',
    marginBottom: '14px'
  },
  carouselContainer: {
    maxWidth: '970px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '300px'
    }
  },
  spanSelect: {
    marginTop: '10px',
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',
    '& .label': {
      marginRight: '10px'
    }
  },
  jumbotronTexts: {
    marginTop: '10px',
  },
  paymentGrid: {
    display: 'flex',
  },
  paymentLogos: {
    flex: '1',
    textAlign: 'end',
    '& img': {
      padding: '0 3px',
      height: '30px'
    }
  },
  dialogHeader: {
    textAlign: 'right',
    padding: '10px 24px 0px 25px'
  },
  fullHeight: {
    height: "700px"
  }
}));

export default grid_style;
