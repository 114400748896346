import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import OrderForm from '../Products/OrderForm';
import NotificationDialog from '../Base/NotificationDialog';
import Products, { Methods, Product } from './QueryContainer/Products';
import Frequencies from './QueryContainer/Frequencies';
import Weekdays from './QueryContainer/Weekdays';
import { HOST_AWS_SERVERLESS } from '../../config';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import axios from 'axios';
import { HOST } from '../../config';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import button_style from '../../style/button';

/**
 * Edit order dialog props
 */
export interface EditOrderDialogProps {
  className?: string;
  order?: any;
  orders?: Array<any>;
  upSellItem?: any;
  crossSellItem?: any;
  isPrivate: boolean;
  refetch?: () => any;
}

/**
 * Edit Order Dialog component
 */
const EditOrderDialog: React.FC<EditOrderDialogProps> = (props) => {
  const {
    children,
    className,
    order,
    orders,
    upSellItem,
    crossSellItem,
    isPrivate
  } = props;
  
  const { t } = useTranslation()
  const button_classes = button_style();

  const [totalCost, setTotalCost] = useState(0),
    [variant, setVariant] = useState({ price: 0, productId: '', uomid: '' }),
    [others, setOthers] = useState({
      pricelevelid: '',
      title: '',
      transactioncurrencyid: '',
      variant_type: ''
    }),
    [open, setOpen] = useState(false),
    [openNotificationDialog, setOpenNotificationDialog] = useState(false),
    [editInfoMsg, setEditInfoMsg] = useState(''),
    [viewedChanges, setViewedChanges] = useState(''),
    [viewedProduct] = useState(''),
    [selectedOrder, setSelectedOrder] = useState(order ? order : (orders ? orders[0] : {}));

  const months = [
    t('general.january'),
    t('general.february'),
    t('general.march'),
    t('general.april'),
    t('general.may'),
    t('general.june'),
    t('general.july'),
    t('general.august'),
    t('general.september'),
    t('general.october'),
    t('general.november'),
    t('general.december')
  ];

  useEffect(() => {
    var order;
    if (props.order) {
      order = props.order;
    }
    else if (props.orders && props.orders.length) {
      order = props.orders[0];
    }
    if (order) {
      setSelectedOrder(order);
    }
  }, [props.order, props.orders])

  /*
   * Update order
   */
  const updateOrder = (item: any, done: () => any) => {
    var selected = selectedOrder;

    if (upSellItem && item) {
      item.productId = variant.productId;
      var buttonSelected = document.getElementsByClassName('btn-choose-upsell selected')[0];
      var productIdSelected = buttonSelected.getAttribute("data-id");
      if (props.orders) {
        selected = props.orders.find((o:any) => o.productid === productIdSelected);
      }
    }

    const data = [{
      ...item,
      city: selected.shiptocity,
      postOffice: '',
      pricelevelid: others.pricelevelid,
      streetAddress: selected.shiptoline1,
      transactioncurrencyid: others.transactioncurrencyid,
      variant_type: others.variant_type,
      zipCode: selected.shiptozip,
      orderreference: selected.orderreference,
      productId_original: selected.productid,
      uomid_original: selected.uomid,
      orderOption_id_original: selected.orderfrequency,
      orderids: selected.order_ids,
      priceperunit: variant.price,
      extraProducts: [ crossSellItem ? {
        productId: crossSellItem.item.variants[0].productId,
        uomid: crossSellItem.item.variants[0].uomid
      } : null ]
    }];

    Auth
      .currentSession()
      .then(authorization => {
        const token = authorization.getAccessToken();
        return axios({
          method: 'post',
          url: `${HOST_AWS_SERVERLESS}/orders/${!!item.temp.apply ? `temp` : `update`}`,
          data,
          headers: {
            Authorization: `Bearer ${token.getJwtToken()}`
          }
        })
        .then(() => {
          return done();
        })
        .catch((err:any) => {
          throw(err);
        });
      })
      .catch(done);
  };

  const displayInfo = () => {
    const mwf = [1, 2, 3];
    const today = moment().isoWeekday();
    let newDate = '';
    if (mwf.includes(today)) {
      newDate = moment().add(1, 'weeks').isoWeekday(1).format('D.M.YYYY')
    } else {
      newDate = moment().add(2, 'weeks').isoWeekday(1).format('D.M.YYYY')
    }
    setEditInfoMsg(`${t('orders.editinfo')} ${newDate}.`);
    setOpenNotificationDialog(true);
  };

  const handleViewedChanges = (e: any) => {
    setViewedChanges(e.target.value !== 'default' ? e.target.value : '');
  }

  function handleChooseUpSell(e:any) {

    var buttons = document.getElementsByClassName('btn-choose-upsell');
    for (var i=0; i<buttons.length; i++) {
      buttons[i].classList.remove('selected');
    }
    e.currentTarget.classList.toggle('selected');

    const chosenId = e.currentTarget.getAttribute('data-id');

    var clickedOrder;
    if (props.orders) {
      for (i = 0; i < props.orders.length; i++) {
        const o = props.orders[i];
        if (o.productid === chosenId) {
          clickedOrder = o;
          break;
        }
      }
    }

    if (clickedOrder) {
      setSelectedOrder(clickedOrder);
    }
  }

  return (
    <>
      <span onClick={() => { setOpen(true) }} className={className}>
        {children}
      </span>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        scroll={`body`}
        onClose={() => { setOpen(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t('orders.editorder')} - {others.title}</DialogTitle >
        <DialogContent>
          <Frequencies>
            {(frequencies: any) => (
              <Weekdays>
                {(weekdays: any) => (
                  <Products>
                    {({ getFamily, getProduct }: Methods) => {

                      var productIdToLoad = selectedOrder.productid;
                      if (viewedProduct) productIdToLoad = viewedProduct;
                      if (upSellItem) productIdToLoad = upSellItem.variant.productId;

                      const product: Product = getProduct(productIdToLoad, 'id'),
                        { price, id, slug, uomid } = product,
                        item = getFamily(slug, product.category);

                      var family = { item, slug };

                      if (upSellItem) {
                        family = {
                          item: upSellItem.family,
                          slug: upSellItem.family.slug
                        }
                        if (!variant.productId) {
                          setVariant({
                            productId: upSellItem.variant.productId,
                            price: upSellItem.variant.price,
                            uomid: upSellItem.variant.uomid
                          });
                        }
                      }
                      else if (crossSellItem) {
                        family = crossSellItem;
                        if (!variant.productId) {
                          setVariant(crossSellItem.item.variants[0]);
                        }
                      }
                      else {
                        if (!variant.productId) {
                          setVariant({ price, productId: id, uomid: uomid });
                        }
                      }

                      var order_weekdays = selectedOrder.order_details.map((detail: any) => {
                        return {
                          day: weekdays[detail.deliveryweekday],
                          quantity: detail.quantity,
                          orderids: detail.orderids
                        }
                      });
                      const orderDetails = {
                        productId: viewedProduct ? viewedProduct : selectedOrder.productid,
                        family: product.slug,
                        orderOption: frequencies[selectedOrder.orderfrequency],
                        isContinuous: product.iscontinuouspossible,
                        details: order_weekdays,
                        id: null
                      };

                      if (!others.transactioncurrencyid) {
                        setOthers({
                          pricelevelid: product.pricelevelid,
                          title: product.name,
                          transactioncurrencyid: product.transactioncurrencyid,
                          variant_type: product.variant_type
                        });
                      }

                      var changes: Array<string> = [];
                      selectedOrder.tempchanges
                        .filter((change: any) => !!!change.ispaused)
                        .forEach((change: any) => {
                          const from = new Date(change.from);
                          const to = new Date(change.to);
                          change.label = `${months[from.getMonth()]} ${from.getDate()} ${t('general.to')} ${months[to.getMonth()]} ${to.getDate()}`;
                          changes.push(change.label);
                        });
                      changes = uniq(changes);

                      var change_productid: any;
                      if (!!viewedChanges) {
                        orderDetails.details.forEach((detail: any) => {
                          const orderid = detail.orderids[0];
                          const change = selectedOrder.tempchanges.find((change: any) => {
                            return !change.ispaused &&
                              change.orderid === orderid &&
                              change.label === viewedChanges;
                          });
                          if (change) {
                            if (change.quantity) detail.quantity = change.quantity;
                            if (change.weekdayid) detail.day = weekdays[change.weekdayid];
                            if (change.productid) {
                              orderDetails.productId = change.productid;
                              change_productid = change.productid;
                            }
                          }
                        });
                      }

                      if (change_productid) {
                        const change_product: Product = getProduct(change_productid, 'id');
                        if (variant.productId !== change_product.id) {
                          setVariant({
                            price: change_product.price,
                            productId: change_product.id,
                            uomid: change_product.uomid
                          });
                        }
                      }

                      // Double checker for upsell: If the variant does not belong to the list of variants,
                      // set the default variant.
                      if (upSellItem || crossSellItem) {
                        const variantIds:string[] = family?.item?.variants?.map((v:any) => v.productId);
                        const _variant = upSellItem?.variant || crossSellItem?.item?.variants?.[0];
                        if (!variantIds.includes(variant?.productId)) {
                          setVariant(_variant);
                        }
                      }

                      const showChanges = !!changes.length && !upSellItem;
                      const showSelectOrder = (!!upSellItem || !!crossSellItem);

                      // First valid order for upsell / cross-sell selection.
                      let first:Number = -1;

                      return (
                        <React.Fragment>
                          { showChanges &&
                            <FormControl margin="normal" fullWidth>
                              <InputLabel shrink>{t('orders.vieworderchanges')}</InputLabel>
                                <Select value={viewedChanges ?? 'default'} onChange={handleViewedChanges}>
                                  <MenuItem key={0} value='default'>{t('orders.originalorder')}</MenuItem>
                                  {changes.map((label: string, idx: number) =>
                                    <MenuItem key={idx + 1} value={label}>{label}</MenuItem>
                                  )}
                                </Select>
                            </FormControl>
                          }
                          { showSelectOrder &&
                            <Grid container>
                              { !!orders &&
                                compact(
                                  orders.map((o:any, i:number) => {
                                    const orderProduct = getProduct(o.productid, 'id');
                                    if (orderProduct.uomid !== o.uomid)
                                      return null;
                                    // Set the first order valid for upsell / cross-sell.
                                    first = first < 0 ? i : first;

                                    return (
                                      <Grid item xs={12} sm={6} md={4} key={i} className={button_classes.buttonGridContainer}>
                                        <Button className={`
                                            ${button_classes.upsellChooseButton}
                                            btn-choose-upsell
                                            ${i===first?`selected`:``}
                                          `}
                                          onClick={handleChooseUpSell}
                                          data-id={`${orderProduct.id}`}
                                          data-price={`${orderProduct.price}`}
                                          fullWidth>
                                          <CardMedia className={'image'} image={`${HOST}${orderProduct.images[0]}`}/>
                                          <span className={'text'}>
                                            {orderProduct.name}<br/>
                                            {orderProduct.variant}
                                          </span>
                                        </Button>
                                      </Grid>
                                    );
                                  })
                                )
                              }
                            </Grid>
                          }
                          {
                            <OrderForm
                              defaultVariant={variant}
                              orderDetails={orderDetails}
                              cartId={null}
                              isDialog={true}
                              setDialogOpen={setOpen}
                              totalCost={totalCost}
                              setTotalCost={setTotalCost}
                              family={family}
                              onUpdateOrder={updateOrder}
                              displayInfo={displayInfo}
                              isPrivate={isPrivate}
                              viewOnly={!!viewedChanges}
                              isUpCrossSell={!!upSellItem || !!crossSellItem}
                              setSelectedVariant={(productId: string) => {
                                const selectedProduct = getProduct(productId, 'id');
                                setVariant({
                                  price: selectedProduct.price,
                                  productId,
                                  uomid: selectedProduct.uomid
                                });
                              }}
                            />
                          }
                        </React.Fragment>
                      );
                    }}
                  </Products>
                )}
              </Weekdays>
            )}
          </Frequencies>
        </DialogContent>
      </Dialog>
      <NotificationDialog
        open={openNotificationDialog}
        setOpen={setOpenNotificationDialog}
        callBackClose={props.refetch}
        contentText={editInfoMsg}
        contentTitle={`Info`}
      />
    </>
  );
}

export default EditOrderDialog;
