import { Auth } from 'aws-amplify';
import axios from 'axios';
import { HOST_AWS_SERVERLESS } from '../config';

export function getOrderDeliveryAddress(orderDetails: any, user: any) {
  const id = orderDetails.deliveryAddressId ? orderDetails.deliveryAddressId : user.deliveryAddress ? user.deliveryAddress[0].id : null;
  return id;
}

export function getDeliveryAddressDetails(id: any, user: any) {
  let address = {};
  user.deliveryAddress.forEach((el: any) => {
    if (el.id === id)
      address = el
  });
  return address;
}

export function parseAmplifyUserInfo(data: any) {
  return {
    email: data.attributes['custom:dynamics_email'],
    dynamics_contact_id: data.attributes['custom:dynamics_contact_id'],
    account_type: data.attributes['custom:account_type'],
    firstLogin: data.attributes['custom:firstLogin'],
    token: data.signInUserSession.accessToken.jwtToken
  }
}

export function parseDynamicsUserInfo(data: any) {
  return {
    accountId: data.accountid,
    accountType: data.accounttype,
    contactId: data.contactid,
    givenName: data.firstname,
    familyName: data.lastname,
    email: data.email,
    company: data.accountname,
    companyid: data.businessid,
    phone: data.phone,
    invoicemethod: data.paymentmethod,
    einvoiceoperator: data.einvoiceoperator,
    einvoiceidentifier: data.einvoiceidentifier,
    invoiceemail: data.accountemail,
    startingpoints: data.startingpoints,
    points: data.earnedrewardpoints,
    billingAddress: {
      id: '1',
      streetAddress: data.addressline1,
      city: data.addresscity1,
      postOffice: '',
      zipCode: data.addresszip1
    },
    deliveryAddress: [
      {
        id: '1',
        streetAddress: data.addressline2,
        city: data.addresscity2,
        postOffice: '',
        zipCode: data.addresszip2
      }
    ],
    orders: [],
    stripeid: data.stripeid,
    stripepaymentmethods: data.stripepaymentmethods
  }
}

export function preSignUpAttributes (data: any):PreSignUpAttributes {
  var ret:PreSignUpAttributes = {
    email: data.username.toLowerCase(),
    given_name: data.firstname,
    family_name: data.lastname,
    phone_number: data.phone,
    'custom:account_name': data.company,
    'custom:account_type': `${data.accountType}`,
    'custom:account_pay_method': `${data.invoicemethod}`,
    'custom:account_business_id': data.companyid,
    'custom:account_telephone': data.phone,
    'custom:invoice_street': data.streetaddress,
    'custom:invoice_zip': data.zipcode,
    'custom:invoice_postoffice': data.postaddress,
    'custom:deliver_same_address': data.is_delivery_same ? '1' : '0',
    'custom:deliver_street': !!data.d_streetaddress ? data.d_streetaddress : (data?.is_delivery_same ? data.streetaddress : ''),
    'custom:deliver_zip': !!data.d_zipcode ? data.d_zipcode : (data?.is_delivery_same ? data.zipcode : ''),
    'custom:deliver_postoffice': !!data.d_postaddress ? data.d_postaddress : (data?.is_delivery_same ? data.postaddress : ''),
    'custom:dynamics_email': data.username,
    'custom:einvoice_operator': data.einvoiceoperator ?? '',
    'custom:einvoice_identifier': data.einvoiceidentifier ?? '',
    'custom:account_email': data.invoiceemail ?? ''
  }
  return ret;
}

export const getToken = async () => {
  try {
    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getAccessToken().getJwtToken();
    return accessToken;
  } catch (e) {
    return null;
  }
}

export const signUp = (values: any) => {
  const attributes = preSignUpAttributes(values);
  return Auth.signUp({
    username: values.username.toLowerCase(),
    password: values.password,
    attributes: attributes,
    validationData: []
  });
}

export const updateProfile = (contactId:string, accountId:string, values: any, stripeToken?:string) => {
  return Auth.currentAuthenticatedUser()
    .then(user => {
      const accessToken = user.signInUserSession.accessToken.jwtToken;
      const updateData: any = {
        account_id: accountId,
        contact_id: contactId,
        account_type: values.accountType,
        given_name: values.firstname,
        family_name: values.lastname,
        phone_number: values.phone,
        account_name: values.company,
        invoice_street: values.streetaddress,
        invoice_city: values.postaddress,
        invoice_zip: values.zipcode,
        delivery_street: values.d_streetaddress,
        delivery_city: values.d_postaddress,
        delivery_zip: values.d_zipcode,
        payment_method: Number(values.invoicemethod),
        business_id: values.companyid,
        account_phone_number: values.phone,
        einvoice_operator: Number(values.einvoiceoperator),
        einvoice_identifier: values.einvoiceidentifier,
        invoice_email: values.invoiceemail,
        stripe_payment_token: stripeToken
      };
      return axios({
        method: 'post',
        url: `${HOST_AWS_SERVERLESS}/users/update`,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        data: updateData
      });
    });
}

export const getTranslateError = (err: any) => {
  let errCode = 'unknownerror';
  if (err.code) {
    switch (err.code) {
      case 'InvalidParameterException':
        if (err.message.includes('\'username\'')) {
          errCode = 'err_msgs.invalidemail';
        }
        else if (err.message.includes('email address format')) {
          errCode = 'err_msgs.invalidemailformat';
        }
        else if (err.message.includes('Invalid phone number format.')) {
          errCode = 'err_msgs.invalidphoneformat';
        }
        else if (err.message.includes('\'password\'')) {
          errCode = 'err_msgs.invalidlength';
        }
        break;
      case 'UsernameExistsException':
        errCode = 'err_msgs.emailexists';
        break;
      case 'InvalidPasswordException':
        if (err.message === 'Password did not conform with policy: Password must have symbol characters') {
          errCode = 'err_msgs.passwordneedssymbol';
        }
        else if (err.message === 'Password did not conform with policy: Password must have uppercase characters') {
          errCode = 'err_msgs.passwordneedsuppercase';
        }
        else if (err.message === 'Password did not conform with policy: Password not long enough') {
          errCode = 'err_msgs.passwordtooshort';
        }
        else if (err.message === 'Password did not conform with policy: Password must have numeric characters') {
          errCode = 'err_msgs.passwordneedsnumber';
        }
        else {
          errCode = 'err_msgs.passwordconfirmpolicy';
        }
        break;
      default:
        break;
    }
  }
  return errCode;
}

