import { makeStyles } from '@material-ui/core/styles';

const subscription_style = makeStyles(theme => ({
  heading: {
    margin: '0'
  },
  link: {
    color: theme.palette.secondary.contrastText
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    '& svg': {
      marginRight: '10px'
    }
  },
  notes : {
    fontSize: "90%",
    marginTop: "10px"
  }
}));

export default subscription_style;
