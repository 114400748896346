import replace from 'lodash/replace';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QFBox from '../Base/QueryContainer/QFBox';

export const labelLookup: any = {
  '1': 'Every week',
  '2': 'Every other week',
  'Single Order': 'One time only'
}

function OrderFrequencyLabel(props: any) {
  const { t } = useTranslation();
  const { frequencies, frequency } = props;

  const targetFq = frequencies.filter((item: any) => item.value === frequency);
  if (targetFq) {
    return t('orders.' + replace(labelLookup[targetFq[0].label], /\s/g, '').toLowerCase());
  } else {
    return null;
  }
}

export default function OrderFrequency(props: any) {
  return (
    <QFBox resource={['orderOptions']} {...props} component={OrderFrequencyLabel} />
  );
}
