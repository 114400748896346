import gql from 'graphql-tag';

export const SALESORDER_OPTIONS_QUERY = gql`
  query PicklistsSalesorder {
    orderPicklists: picklists(entity: "salesorder", fields: ["fs_deliveryweekday", "fs_orderfrequency"]) {
      logicalName
      displayName
      options {
        label
        value
      }
    }
  }
`;
