import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

import button_style from '../../style/button';
import paper_style from '../../style/paper';
import grid_style from '../../style/grids';
import link_style from '../../style/links';

export default function EditProfileSuccess(props: any) {
  const { t } = useTranslation();
  const button_classes = button_style();
  const paper_classes = paper_style();
  const grid_classes = grid_style();
  const link_classes = link_style();

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Paper className={`${paper_classes.paper} ${paper_classes.login_container}`}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h3">{t('profile.editsuccess')}</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <br/>
            <Typography variant="body1">
              {t('profile.editsuccessmessage')}
            </Typography>
            <br/>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={3} lg={4}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} className={grid_classes.relative}>
              <Link to="/" className={`${button_classes.block} ${link_classes.normal_text}`}>
                <Button
                  className={`${button_classes.noRadius} ${button_classes.default}  ${button_classes.block}`}
                  variant="contained"
                  color="primary">
                  {t('profile.home')}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={4}></Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
