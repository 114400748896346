import React, { Suspense, useState, useEffect } from 'react';
import ApolloClient from 'apollo-client';
import {ApolloProvider} from 'react-apollo';
import Amplify, {Auth} from 'aws-amplify';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { parseAmplifyUserInfo, getToken } from './libs/user';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/Utils/ScrollToTop';
import DefaultLayout from'./layouts/DefaultLayout/DefaultLayout';
import { HOST_AWS_SERVERLESS, COGNITO_REGION, USERPOOL_ID, USERPOOL_WEBCLIENT_ID } from './config';
import './i18n';
import TagManager from 'react-gtm-module'
import { GOOGLE_TAG_MANAGER_ID } from './config';
import { ApolloLink } from 'apollo-link';
import ToastError from './components/Base/ToastError';
import { handleError, GraphQLError } from './libs/utils';
import fetch from 'cross-fetch';

const tagManagerArgs = {
  gtmId: `${GOOGLE_TAG_MANAGER_ID}`,
  dataLayer: {}
}

TagManager.initialize(tagManagerArgs);

Amplify.configure({
  Auth: {
    region: COGNITO_REGION,
    userPoolId: USERPOOL_ID,
    userPoolWebClientId: USERPOOL_WEBCLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
  Analytics: {
    disabled: true
  }
});

const httpLink = createHttpLink({
  fetch,
  uri: `${HOST_AWS_SERVERLESS}/graphql`,
});

const errorLink = onError(({ graphQLErrors, operation, networkError }) => {
  if (graphQLErrors) {
    ToastError(`[GraphQLError] Failed to fetch ${operation.operationName}`);
    graphQLErrors.forEach(error => {
      handleError(new GraphQLError(error.message, operation.operationName));
    });
  }
  else if (networkError) {
    ToastError(`[NetworkError] Failed to fetch ${operation.operationName}`);
    handleError(networkError);
  }
});

/* This is to provide accessToken for backend */
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from AWS Amplify
  const token = await getToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const allLink = ApolloLink.from([
  errorLink,
  httpLink
]);

export const cache = new InMemoryCache();
const client = new ApolloClient({
  link: authLink.concat(allLink),
  cache: cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    },
    query: {
      errorPolicy: 'all'
    }
  }
});

export interface AppContext {
  state: any;
  refetch: (cache: string, extension?: any) => any;
  setState: (value: any) => any;
}
const defaultAppContext: AppContext = {
  state: {},
  refetch: () => {},
  setState: () => {}
}
export const AppState = React.createContext(defaultAppContext);

const App: React.FC = (props: any) => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [state, setState] = useState({
    funnel: 0,
    needsRefetch: {
      orders: false,
      user: false
    },
    refetching: {
      orders: false,
      user: false
    }
  });

  /* set initial isSignedIn when app starts (or reload) */
  Auth.currentSession()
  .then(res => {
    setIsSignedIn(!!res)
  })
  .catch(() => setIsSignedIn(false))
  .finally(() => setIsAuthInitialized(true));


  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false
    }).then(data => {
      const amplifyUserInfo = parseAmplifyUserInfo(data);
      setUserEmail(amplifyUserInfo.email);
      setIsPrivate(amplifyUserInfo?.account_type === '2');
    }).catch(err => {

    });
    return () => {};
  }, [])


  /* Handle Auth State change and save to state */
  const onStateChange = (newState: any) => {
    if (newState === 'signedIn') {
      return setIsSignedIn(true);
    }
    if (newState === 'signedOut') {
      return setIsSignedIn(false);
    }
    setIsSignedIn(false);
  }

  const refetch = (cache: string, extension: any = {}) => {
    setState({
      ...state,
      ...extension,
      needsRefetch: {
        ...state.needsRefetch,
        [cache]: true
      }
    });
  };

  return (
    <ApolloProvider client={client}>
      <AppState.Provider value={{ state, refetch, setState }}>
        <Router>
          <ScrollToTop>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route
                  path="/"
                  name="Home"
                  render={(props: any) => (isAuthInitialized ?
                    <DefaultLayout {...props}
                      userEmail={userEmail}
                      setUserEmail={setUserEmail}
                      isSignedIn={isSignedIn}
                      onStateChange={onStateChange}
                      isPrivate={isPrivate}
                      setIsPrivate={setIsPrivate}
                    /> : <></>
                  )} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </AppState.Provider>
    </ApolloProvider>
  );
}

export default App;
