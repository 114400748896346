import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import americanExpressLogo from "../../assets/svgs/american-express.svg";
import mastercardLogo from "../../assets/svgs/mastercard.svg";
import visaLogo from "../../assets/svgs/visa.svg";
import visaElectronLogo from "../../assets/svgs/visa-electron.svg";
import StripeInput from "./StripeInput";
import grid_style from "../../style/grids";
import paper_style from "../../style/paper";
import button_style from "../../style/button";

import { StripeError } from "@stripe/stripe-js";
import { useCreateOrder } from "../Contexts/Order";

const CardFields = forwardRef((props: any, ref) => {
  const { t } = useTranslation();
  const [grid_classes, paper_classes, button_classes] = [
    grid_style(),
    paper_style(),
    button_style(),
  ];
  const stripe = useStripe();
  const elements = useElements();
  const [fieldErrors, setFieldErrors] = useState<StripeError>({
    code: "",
    type: "authentication_error",
  });
  const { setEditCard } = useCreateOrder();

  useImperativeHandle(ref, () => ({
    hasErrors() {
      return fieldErrors.code;
    },
    async createToken(callback: any) {
      if (!stripe || !elements) return "";

      const cardElement =
        elements.getElement(CardNumberElement) ?? elements.create("cardNumber");
      props.setIsBusy(true);
      return await stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
        })
        .then((data) => {
          if (data.error) {
            props.setIsBusy(false);
            return setFieldErrors(data.error)
          };
          // Checks if the user is new, if True register otherwise create the order;
          return props.newUser
            ? props.executeRegistraion(data?.paymentMethod?.id ?? "")
            : callback(data?.paymentMethod?.id ?? "");
        });
    },
  }));

  return (
    <React.Fragment>
      <Grid
        container
        className={`${grid_classes.root} ${grid_classes.top_content_container}`}
      >
        {!props.user && (
          <Grid container>
            <Grid item lg={7} md={12} className={`${grid_classes.paymentGrid}`}>
              <Typography variant="h3">{props.headerLabel}</Typography>
              <div className={`${grid_classes.paymentLogos}`}>
                <img src={visaLogo} alt="visa-logo" />
                <img src={visaElectronLogo} alt="visa-electron-logo" />
                <img src={mastercardLogo} alt="mastercard-logo" />
                <img src={americanExpressLogo} alt="american-express-logo" />
              </div>
            </Grid>
          </Grid>
        )}
        <Grid item sm={12}>
          <Paper
            className={
              !props.user
                ? `${paper_classes.paper} ${paper_classes.login_container}`
                : ""
            }
          >
            <Grid container>
              <Grid item lg={6} md={10} xs={12}>
                <TextField
                  label={t("profile.cardnumber")}
                  error={
                    fieldErrors.code === "incomplete_number" ? true : false
                  }
                  disabled={props.isBusy}
                  helperText={
                    fieldErrors.code === "incomplete_number"
                      ? t("payments.cardnumberinvalid")
                      : ""
                  }
                  autoComplete=""
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardNumberElement,
                      options: { placeholder: "" },
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item lg={3} md={5} sm={6} xs={7}>
                <TextField
                  label={t("profile.periodvalid")}
                  autoComplete=""
                  fullWidth
                  disabled={props.isBusy}
                  margin="normal"
                  error={
                    fieldErrors.code === "incomplete_expiry" ? true : false
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardExpiryElement,
                      options: { placeholder: t("profile.cardexpiry") },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={3} md={5} sm={6} xs={7}>
                <TextField
                  label={t("profile.cardcode")}
                  disabled={props.isBusy}
                  autoComplete=""
                  fullWidth
                  margin="normal"
                  error={fieldErrors.code === "incomplete_cvc" ? true : false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardCvcElement,
                      options: { placeholder: t("profile.cvc") },
                    },
                  }}
                />
              </Grid>
            </Grid>
            {(!props.newUser &&  !props.noCards)  && (
              <Grid container>
                <Grid item lg={3} md={5} sm={6} xs={7}>
                  <Button
                    onClick={() => setEditCard(false)}
                    className={`${button_classes.noRadius} ${button_classes.default} ${button_classes.block}`}
                    variant="contained"
                    size="small"
                    color="primary"
                  >
                    {t(`buttons.cancel`)}
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <p className={paper_classes.error}>
                  {fieldErrors.code === "card_declined"
                    ? t("payments.cardnotsupported")
                    : ""}
                </p>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default CardFields;
