import React from 'react';
import { useTranslation } from 'react-i18next';
import subscription_style from '../../style/subscription';
import InfoIcon from '@material-ui/icons/Info';
import { Typography } from '@material-ui/core';

interface StripeSubscriptionProps {
  subscription: any;
}

export function StripeSubscription({ subscription }: StripeSubscriptionProps): React.ReactElement {
  const { t } = useTranslation();
  const subscription_classes = subscription_style();
  const {
    status,
    number: invoiceNumber,
    hosted_invoice_url: invoiceUrl
  } = subscription?.latest_invoice ?? {};

  return (
    <div>
      { !!subscription?.latest_invoice &&
        <React.Fragment>
          { status === 'draft' &&
            <p className={subscription_classes.info}><InfoIcon />{t('orders.invoicebeingdrafted')}</p>
          }
          { (status === 'paid' || status === 'open') &&
            <React.Fragment>
              <h3 className={subscription_classes.heading}>
                {`${t('orders.latestinvoice')}: ${invoiceNumber} (${t(`orders.invoicestatus.${status as string}`)})`}
              </h3>
              <a className={subscription_classes.link} href={invoiceUrl} target='_blank' rel='noopener noreferrer'>
                {t('orders.viewreceipthere')}
              </a>
              <Typography className={subscription_classes.notes}>
                {t("payments.notes")} <a className={subscription_classes.link} href="mailto:info@fruitbox.fi">info@fruitbox.fi.</a>
              </Typography>

            </React.Fragment>
          }
        </React.Fragment>
      }
    </div>
  )
}

export default StripeSubscription;
