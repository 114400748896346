import React , { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NotificationDialog from '../Base/NotificationDialog';

const Welcome = (props: any) => {
  const { t } = useTranslation();
  const [welcomeDialog, setWelcomeDialog] = useState(props.welcomepopup);
  return (
    <React.Fragment>
      <Typography variant="h3">
        {t('dashboard.welcome')}, {props.user.givenName}!
      </Typography>
      <Typography variant="body1">
        {t('dashboard.message')}
      </Typography>
      <NotificationDialog
        open={welcomeDialog}
        setOpen={setWelcomeDialog}
        contentText={t('dashboard.welcomepopup')}
        contentTitle={``}
      />
    </React.Fragment>
  );
}

export default Welcome;
