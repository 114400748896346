import { Container, Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      background: "#f3f3f3",
      padding: "50px 0",
      marginTop: 50,
      fontSize: "90%",
      fontWeight:400,
      position: "absolute",
      bottom: "-300px",
      [theme.breakpoints.down('md')]: {
        bottom: "unset",
      }
    },
    logo: {
      display: "flex",
    },
    lighter: {
      fontWeight: 300,
    },
    links: {
      color: "#282828",
      textDecoration: "none",
    },
    text: {},
    control: {
      padding: theme.spacing(2),
    },
  })
);

export const Footer = (props: any) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Container maxWidth="xl" fixed>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <div className={classes.logo}>
              <img
                src="https://fruitbox.fi/wp-content/uploads/2019/11/logo_b-1-e1588497422790.png"
                style={{ height: "29px" }}
                alt="logo"
              />
              <img
                src="https://fruitbox.fi/wp-content/uploads/2020/05/Screenshot_2020-05-03_at_12-removebg-preview.png"
                style={{ height: "31px", marginTop: "3px" }}
                alt="nutly"
              />
            </div>
            <div>
              <p className={classes.lighter}>Fruitbox Suomi Oy</p>
              <p className={classes.lighter}>
                Pieni Roobertinkatu 13 B 26, 00130 Helsinki
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <p>010 505 5696 (pvm/mpm) ma-pe 8-16</p>
            <p>info(at)fruitbox.fi</p>
            <p className={classes.lighter}>Copyright © Fruitbox Suomi Oy</p>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <p>
              <a
                className={classes.links}
                href="https://fruitbox.fi/tietosuojaseloste/"
              >
                Tietosuojaseloste
              </a>
            </p>
            <p>
              <a
                className={classes.links}
                href="https://fruitbox.fi/toimitusehdot/"
              >
                Toimitusehdot
              </a>
            </p>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};
