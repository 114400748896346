import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import button_style from '../../style/button';
import paper_style from '../../style/paper';
import grid_style from '../../style/grids';
import link_style from '../../style/links';
import { setCookie } from '../../libs/cookie';
import { AppContext, AppState } from '../../App';

interface SubmitOrderSuccessProps {
  isPrivate: boolean;
  isSignedIn: boolean;
  updateCart: (item: any) => void;
  onEnter: () => void;
}
export default function SubmitOrderSuccess({
  isPrivate,
  isSignedIn,
  updateCart,
  onEnter,
}: SubmitOrderSuccessProps) {
  const { t } = useTranslation();
  const buttonClasses = button_style();
  const paperClasses = paper_style();
  const gridClasses = grid_style();
  const linkClasses = link_style();

  useEffect(() => {
    setCookie('cart', '');
    updateCart({ items: [] });
    if (onEnter) {
      onEnter();
    }
  });

  return (
    <AppState.Consumer>
      {({ state, setState }: AppContext) => {
        if (!state.needsRefetch.orders)
          setState({
            ...state,
            needsRefetch: {
              ...state.needsRefetch,
              orders: true,
            },
          });
 
        if(!state.needsRefetch.user) {
          setState({
            ...state,
            needsRefetch: {
              ...state.needsRefetch,
              user: true,
            },
          });
        }

        return (
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Paper className={`${paperClasses.paper} ${paperClasses.login_container}`} >
                <Grid item xs={12} sm={12}>
                  <Typography variant="h3">{t('orders.ordersuccessful')}</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <br />
                  <Typography variant="body1">
                    {isPrivate ? t('orders.ordersuccessfullysubmittedprivate') : t('orders.ordersuccessfullysubmitted')}
                  </Typography>
                  <br />
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={3} lg={4}></Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} className={gridClasses.relative}>
                    <Link to="/" className={`${buttonClasses.block} ${linkClasses.normal_text}`}>
                      <Button
                        className={`${buttonClasses.noRadius} ${buttonClasses.default}  ${buttonClasses.block}`}
                        variant="contained"
                        color="primary">
                        {isSignedIn
                          ? t("orders.vieworders")
                          : t("orders.backtohome")}
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={4}></Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )
      }}
    </AppState.Consumer>
  );
}
