import React, { useState, useEffect, useRef } from 'react';

import { Redirect } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import { parseQueryString } from '../../libs/utils';
import { CHECKOUT_REGISTRATION_STAGES } from '../../libs/order';
import { signUp, getTranslateError, updateProfile } from '../../libs/user';
import { formatLabel } from '../../libs/utils'
import InfoIcon from '@material-ui/icons/Info';
import form_style from '../../style/form';
import button_style from '../../style/button';
import paper_style from '../../style/paper';
import grid_style from '../../style/grids';
import link_style from '../../style/links';
import EditProfileSuccess from '../Profile/EditProfileSuccess';
import PaymentMethods from '../Profile/PaymentMethods';
import orderBy from 'lodash/orderBy';
import { FormHelperText } from '@material-ui/core';
import { useCreateOrder } from '../Contexts/Order';
import { B2C_PAYMENT_METHODS } from '../../config';

const params = parseQueryString(window.location.search);

interface Option {
  label: string;
  value: number;
  uid: string;
}

interface PaymentMethodRef {
  createToken: (callback:() => void) => Promise<string>;
  hasErrors:any;
}

interface SignupFields {
  accountType: number | string;
  firstname: string;
  lastname: string;
  company?: string;
  companyid?: string;
  email: string;
  phone: string;
  streetaddress?: string;
  zipcode?: string;
  postaddress?: string;
  is_delivery_same?: boolean;
  d_streetaddress?: string;
  d_zipcode?: string;
  d_postaddress?: string;
  username?: string;
  password?: string;
  retypepassword?: string;
  invoicemethod?: string;
  invoiceemail?: string;
  einvoiceoperator: string;
  einvoiceidentifier: string;
  agreed: boolean;
  isBusy: boolean;
  isSuccess: boolean;
  isVerified: boolean;
  isAuthenticating ?: boolean;
  error: string | any;
}

const Form: React.FC = (props: any) => {
  const {
    user,
    paymentMethods,
    eInvoiceOperators,
    customerTypes,
    isFromCheckout,
    signupStage,
    emitFormValues,
    editMode
  } = props;
  const form_classes = form_style();
  const button_classes = button_style();
  const paper_classes = paper_style();
  const grid_classes = grid_style();
  const link_classes = link_style();

  const translate = useTranslation().t;
  const childRef = useRef<PaymentMethodRef>();
  const t = (message: string) => translate('signup.' + message);
  const [placeHolder, setPlaceHolder] = useState(true);
  const {
    errorFields,
    setErrorFields,
    editCard,
    setEditCard,
  } = useCreateOrder();

  const [values, setValues] = useState<SignupFields>({
    accountType: user?.accountType ?? '',
    firstname: user?.givenName ?? '',
    lastname: user?.familyName ?? '',
    company: user?.company ?? '',
    companyid: user?.companyid ?? '',
    email: props.isFromCheckout || !editMode ? '' : user?.email ?? '',
    phone: user?.phone ?? '+358',
    streetaddress: user?.billingAddress?.streetAddress ?? '',
    zipcode: user?.billingAddress?.zipCode ?? '',
    postaddress: user?.billingAddress?.city ?? '',
    is_delivery_same: true,
    d_streetaddress: user?.deliveryAddress?.[0]?.streetAddress ?? '',
    d_zipcode: user?.deliveryAddress?.[0]?.zipCode ?? '',
    d_postaddress: user?.deliveryAddress?.[0]?.city ?? '',
    username: params.email ?? user?.email ?? '',
    password: '',
    retypepassword: '',
    invoicemethod: String(user?.invoicemethod ?? ''),
    invoiceemail: user?.invoiceemail ?? '',
    einvoiceoperator: String(user?.einvoiceoperator ?? ''),
    einvoiceidentifier: String(user?.einvoiceidentifier ?? ''),
    agreed: false,
    isBusy: false,
    isSuccess: false,
    isVerified:false,
    error: ''
  });

  const [allowedPaymentMethods, setAllowedPaymentMethods] = useState<any[]>([]);

  const setIsBusy = (isBusy:boolean) :void => {
    setValues({...values, isBusy: isBusy});
  }

  const handleChange = (event: any) => {
    event.persist();
    if(props.isFromCheckout) {
      props.emitFormValues({ ...values, [event.target.name]: event.target.value });
    }
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));
  };

  const handleCheckChange = (event: any) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.checked }));
  };

  const isElectronicInvoice = (): boolean => {
    const eInvoice: Option = paymentMethods?.find((i:Option) => formatLabel(i.label) === 'einvoice');
    return eInvoice ? eInvoice.value === Number(values.invoicemethod) : false;
  }

  const isEmailInvoice = (): boolean => {
    const emailInvoice = paymentMethods?.find((i:Option) => formatLabel(i.label) === 'emailinvoice');
    return emailInvoice ? emailInvoice.value === Number(values.invoicemethod) : false;
  }

  const isPrivate = (): boolean => {
    const customerType = customerTypes.find((type:Option) => type.value === values.accountType);
    return customerType ? (formatLabel(customerType.label) === 'privateperson') : false;
  }

  useEffect(() => {
    if(isFromCheckout) {
      emitFormValues(values);
    }
  }, [values, isFromCheckout]);

  useEffect(() => {
    if(signupStage && signupStage !== CHECKOUT_REGISTRATION_STAGES.waiting) {
      handleSubmit();
    }
  }, [signupStage]);

  const isPrivateSelected = isPrivate();
  useEffect(() => {
    if (Array.isArray(paymentMethods)) {
      // Filter payment methods availabl based on the account type.
      const filterred: any[] = paymentMethods.filter((method) => {
        if (isPrivateSelected) {
          return B2C_PAYMENT_METHODS.includes(method?.label ?? '');
        } else {
          return !B2C_PAYMENT_METHODS.includes(method?.label ?? '');
        }
      });
      setAllowedPaymentMethods(filterred);
      // Reset selected invoice method if the current one is not in the available list.
      if (values.invoicemethod) {
        const filterredIds = filterred?.map(item => String(item.value));
        if (!filterredIds?.includes(String(values.invoicemethod ?? ''))) {
          setValues(values => ({...values, invoicemethod: ''}));
        }
      }
    }
  }, [paymentMethods, isPrivateSelected, values.accountType, values.invoicemethod])

  const update = (stripeToken?:string ) => {
    setValues(values => ({ ...values, isBusy: true, error: '' }));
    updateProfile(props.user.contactId, props.user.accountId, values, stripeToken ?? '')
    .then(() => {
      props.refetchUser()
        .catch((err:any) => {
          let errMsg = 'Refetching user failed';
          if (err.message) {
            errMsg = err.message;
          }
          setValues(values => ({ ...values, isBusy: false, error: errMsg }));
        })
        .then(() => {
          setValues(values => ({ ...values, isBusy: false, error: '', isSuccess: true }));
        });
    }).catch((error: any) => {
      const errMsg = error.message ? error.message : 'Update failed';
      setValues(values => ({ ...values, isBusy: false, error: errMsg }));
    })
  }

  function handleSubmit(event?: any) {
    if (event) {
      event.preventDefault();
    }

    let isErrorRequired = false;
    const error_fields: string[] = [];

    let requiredFields;
    if (isPrivate()) {
      requiredFields = [
        'accountType',
        'firstname',
        'lastname',
        'username',
        'phone',
        'd_streetaddress',
        'd_zipcode',
        'd_postaddress',
        'invoicemethod'
      ]
    } else {
      requiredFields = [
        'accountType',
        'firstname',
        'lastname',
        'company',
        'username',
        'companyid',
        'phone',
        'streetaddress',
        'zipcode',
        'postaddress',
        'invoicemethod'
      ];
      if (isElectronicInvoice())
        requiredFields.push('einvoiceoperator', 'einvoiceidentifier');
      if (isEmailInvoice())
        requiredFields.push('invoiceemail');
    }
    if (!editMode) requiredFields.push('password', 'retypepassword');

    requiredFields.forEach((item: string) => {
      if (!values[item as keyof SignupFields]) {
        error_fields.push(item);
        var error: string = '';
        switch (item) {
          case 'invoicemethod': {
            error = t('fillupinvoicemethod');
            break;
          }
          default: {
            error = t('fillupfields');
            break;
          }
        }
        setValues(values => ({ ...values, error: error }));
        isErrorRequired = true;
      }
    });

    if (!values.is_delivery_same) {
      [
        'd_streetaddress',
        'd_zipcode',
        'phone',
        'd_postaddress'
      ].forEach((item: string) => {
        if (!values[item as keyof SignupFields]) {
          error_fields.push(item);
          setValues(values => ({ ...values, error: t('fillupaddressfields') }));
          isErrorRequired = true;
        }
      });
    }

    setErrorFields(error_fields)
    if (isErrorRequired) {
      if(props.isFromCheckout)
        props.setSignupStage(CHECKOUT_REGISTRATION_STAGES.verify);
      return null
    };

    if (!editMode && !values.agreed && !props.isFromCheckout) {
      setValues(values => ({ ...values, error: t('agreetermsandconditions') }));
      return null;
    }

    if (!editMode && (values.password !== values.retypepassword)) {
      setValues(values => ({ ...values, isBusy: false, error: t('passwordmismatch') }));
      if(props.isFromCheckout)
        props.setSignupStage(CHECKOUT_REGISTRATION_STAGES.verify);
      return null;
    }

    if (editMode) {
      if(isPrivate() && (editCard || !user.stripepaymentmethods?.length)) {
        childRef.current?.createToken(update);
      }
      else {
        update();
      }
    }
    else if(props.signupStage !== CHECKOUT_REGISTRATION_STAGES.verify){
      setValues(values => ({ ...values, isBusy: true, error: '' }));
      signUp(values)
        .then(() => {
          setValues(values => ({
            ...values,
            username: values.username,
            isBusy: props.isFromCheckout ? true : false,
            error: '',
            isSuccess: true
            })
          );
        })
        .catch(err => {
          const t_err = getTranslateError(err);
          if(props.isFromCheckout)
            props.setSignupStage(CHECKOUT_REGISTRATION_STAGES.verify);

          setValues(values => ({
            ...values,
            isBusy: false,
            error: translate(t_err)
          }));
        });
    }
  };

  if (values.isSuccess) {
    // Success message on edit profile.
    if (editMode) {
      return <EditProfileSuccess {...props} />
    }
    // Success redirect on successfull signup.
    else {
      const uri = `/confirmsignup`;
      if(!props.isFromCheckout) return <Redirect to={uri} />
    }
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item sm={12}>
          <Paper className={`${paper_classes.paper} ${paper_classes.login_container}`}>
            {!props.isFromCheckout && (<Grid item sm={12} className={grid_classes.headerGridMargin}>
              <Typography variant="h3">{editMode ? translate('profile.editprofile') : t('createaccount')}</Typography>
            </Grid>)}
            <Grid item sm={12}>
              <form onSubmit={handleSubmit} className={form_classes.container} noValidate autoComplete="off">
                <Grid container spacing={2}>
                  { !editMode &&
                  <Grid item xs={12}>
                  <FormControl margin="normal"
                    fullWidth
                    disabled={values.isAuthenticating ? true : false}
                    error={errorFields.includes('accountType') ?? false }>
                      <InputLabel shrink
                        required={editMode}

                        htmlFor='customertype-select'
                      >
                        {t('accounttype')}
                      </InputLabel>
                      <Select
                        error={errorFields.includes('accountType')}
                        value={values.accountType}
                        onChange={handleChange}
                        displayEmpty
                        disabled={values.isAuthenticating ? true : false}
                        inputProps={{
                          name: 'accountType',
                          id: 'customertype-select',
                        }}
                        >
                        <MenuItem value="">
                          <em className={(placeHolder) ? form_classes.menuItemPlaceHolder : ''}>{(placeHolder) ? t('accounttype') : t('none')} </em>
                        </MenuItem>
                        {orderBy(customerTypes ?? [], 'value', 'desc')?.map((item: Option, idx: number) => {
                          return <MenuItem key={idx} value={item.value}>{t(formatLabel(item.label))}</MenuItem>
                        })}
                      </Select>
                      <FormHelperText>{errorFields.includes('accountType') ? t('accounttyperequired') : ''}</FormHelperText>
                    </FormControl>
                  </Grid>
                  }
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="firstname"
                      name="firstname"
                      error={errorFields.includes('firstname') ? true : false}
                      helperText={errorFields.includes('firstname') ? t('firstnamerequired') : ''}
                      label={t('firstname')}
                      value={values.firstname}
                      onChange={handleChange}
                      fullWidth
                      disabled={values.isBusy ? true : false}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                        required: (editMode) || false
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="lastname"
                      error={errorFields.includes('lastname') ? true : false}
                      helperText={errorFields.includes('lastname') ? t('lastnamerequired') : ''}
                      name="lastname"
                      label={t('lastname')}
                      value={values.lastname}
                      onChange={handleChange}
                      fullWidth
                      disabled={values.isBusy ? true : false}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                        required: (editMode) || false
                      }} />
                  </Grid>
                </Grid>
                { !isPrivate() &&
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="company"
                      name="company"
                      error={errorFields.includes('company') ? true : false}
                      helperText={errorFields.includes('company') ? t('companyrequired') : ''}
                      label={t('companyname')}
                      value={values.company}
                      onChange={handleChange}
                      fullWidth
                      disabled={values.isBusy ? true : false}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                        required: (editMode) || false
                      }} />

                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="companyid"
                      name="companyid"
                      error={errorFields.includes('companyid') ? true : false}
                      helperText={errorFields.includes('companyid') ? t('companyidrequired') : ''}
                      label={t('businessid')}
                      value={values.companyid}
                      onChange={handleChange}
                      fullWidth
                      disabled={values.isBusy ? true : false}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                        required: (editMode) || false
                      }} />
                  </Grid>
                </Grid>
                }
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="username"
                      name="username"
                      error={errorFields.includes('username') ? true : false}
                      helperText={!editMode ?
                        t('emailisusername') :
                        <>
                          <span><InfoIcon className={form_classes.infoIcon} /></span>
                          <span className={form_classes.infoMessage}>{t('emailchangeinfo')} <a href="mailto:info@fruitbox.fi">info@fruitbox.fi</a></span>
                        </>
                      }
                      label={t('email')}
                      value={values.username}
                      onChange={handleChange}
                      fullWidth
                      disabled={(values.isBusy || editMode) ? true : false}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                        required: (editMode) || false
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <TextField
                      id="phone"
                      name="phone"
                      error={errorFields.includes('phone') ? true : false}
                      label={t('phone')}
                      placeholder="+"
                      helperText={t('phonenumberules')}
                      value={values.phone}
                      onChange={handleChange}
                      fullWidth
                      disabled={values.isBusy ? true : false}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                        required: (editMode) || false
                      }} />
                  </Grid>
                </Grid>
                {!editMode &&
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="password"
                        name="password"
                        error={errorFields.includes('password') ? true : false}
                        helperText={t('passwordrules')}
                        label={t('password')}
                        value={values.password}
                        type="password"
                        onChange={handleChange}
                        fullWidth
                        disabled={values.isBusy ? true : false}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="retypepassword"
                        name="retypepassword"
                        error={errorFields.includes('retypepassword') ? true : false}
                        label={t('retypepassword')}
                        helperText={errorFields.includes('retypepassword') ? t('passwordmismatch') : ''}
                        value={values.retypepassword}
                        onChange={handleChange}
                        type="password"
                        fullWidth
                        disabled={values.isBusy ? true : false}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }} />
                    </Grid>
                  </Grid>
                }
                { !isPrivate() &&
                <>
                  <Grid container spacing={2} className={grid_classes.headerGridMargin}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h5">{t('invoiceaddress')}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id="streetaddress"
                        name="streetaddress"
                        error={errorFields.includes('streetaddress') ? true : false}
                        helperText={errorFields.includes('streetaddress') ? t('streetrequired') : ''}
                        label={t('streetaddress')}
                        value={values.streetaddress}
                        onChange={handleChange}
                        fullWidth
                        disabled={values.isBusy ? true : false}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                          required: (editMode) || false
                        }} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="zipcode"
                        name="zipcode"
                        error={errorFields.includes('zipcode') ? true : false}
                        helperText={errorFields.includes('zipcode') ? t('zipcoderequired') : ''}
                        label={t('zipcode')}
                        value={values.zipcode}
                        onChange={handleChange}
                        fullWidth
                        disabled={values.isBusy ? true : false}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                          required: (editMode) || false
                        }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="postaddress"
                        name="postaddress"
                        error={errorFields.includes('postaddress') ? true : false}
                        helperText={errorFields.includes('postaddress') ? t('postofficerequired') : ''}
                        label={t('postoffice')}
                        value={values.postaddress}
                        onChange={handleChange}
                        fullWidth
                        disabled={values.isBusy ? true : false}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                          required: (editMode) || false
                        }} />
                    </Grid>
                  </Grid>
                </>
                }
                <FormControl>
                  <Grid container spacing={2} className={grid_classes.headerGridMargin}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h5">{t('deliveryaddress')}</Typography>
                    </Grid>
                  </Grid>
                  {!isPrivate() && !editMode &&
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              color="primary"
                              checked={values.is_delivery_same}
                              name="is_delivery_same"
                              onChange={handleCheckChange} />
                          )}
                          label={t('deliveryaddressclone')}
                          labelPlacement="end" />
                      </Grid>
                    </Grid>
                  }
                  {( (isPrivate() || (!values.is_delivery_same || editMode)) &&
                    <React.Fragment>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            id="d_streetaddress"
                            name="d_streetaddress"
                            label={t('streetaddress')}
                            error={errorFields.includes('d_streetaddress') ? true : false}
                            helperText={errorFields.includes('d_streetaddress') ? t('streetrequired') : ''}
                            value={values.d_streetaddress}
                            onChange={handleChange}
                            fullWidth
                            disabled={values.isBusy ? true : false}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <TextField
                            id="d_zipcode"
                            name="d_zipcode"
                            error={errorFields.includes('d_zipcode') ? true : false}
                            helperText={errorFields.includes('d_zipcode') ? t('zipcoderequired') : ''}

                            label={t('zipcode')}
                            value={values.d_zipcode}
                            onChange={handleChange}
                            fullWidth
                            disabled={values.isBusy ? true : false}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <TextField
                            id="d_postaddress"
                            error={errorFields.includes('d_postaddress') ? true : false}
                            helperText={errorFields.includes('d_postaddress') ? t('postofficerequired') : ''}
                            name="d_postaddress"
                            label={t('postoffice')}
                            value={values.d_postaddress}
                            onChange={handleChange}
                            fullWidth
                            disabled={values.isBusy ? true : false}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }} />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  <FormHelperText>{t('deliverymessage')}</FormHelperText>
                </FormControl>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel shrink required={editMode} htmlFor="age-simple">{t('invoicemethod')}</InputLabel>
                      <Select
                        error={errorFields.includes('invoicemethod')}
                        value={values.invoicemethod}
                        onChange={handleChange}
                        onOpen={() => setPlaceHolder(false)}
                        onClose={() => setPlaceHolder(true)}
                        displayEmpty
                        inputProps={{
                          name: 'invoicemethod',
                          id: 'invoicemethod-select',
                        }}>
                        <MenuItem value="">
                          <em className={(placeHolder) ? form_classes.menuItemPlaceHolder : ''}>{(placeHolder) ? t('invoiceplaceholder') : t('none')} </em>
                        </MenuItem>
                        {
                          (allowedPaymentMethods?.map((item: Option, idx: number) => (
                            <MenuItem key={idx} value={item.value}>{t(formatLabel(item.label))}</MenuItem>
                          )))
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {!isPrivate() && isEmailInvoice() &&
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="invoiceemail"
                        name="invoiceemail"
                        error={errorFields.includes('invoiceemail') ? true : false}
                        label={t('invoiceemail')}
                        value={values.invoiceemail}
                        onChange={handleChange}
                        type="email"
                        fullWidth
                        disabled={values.isBusy ? true : false}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                }
                {!isPrivate() && isElectronicInvoice() &&
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel shrink htmlFor="einvoiceoperator-select">{t('einvoiceoperator')}</InputLabel>
                        <Select
                          error={errorFields.includes('einvoiceoperator') ? true : false}
                          value={values.einvoiceoperator}
                          onChange={handleChange}
                          inputProps={{
                            name: 'einvoiceoperator',
                            id: 'einvoiceoperator-select',
                          }}>
                          <MenuItem value="">
                            <em>{t('none')}</em>
                          </MenuItem>
                          {(eInvoiceOperators?.map((item: Option, idx: number) => (
                            <MenuItem key={idx} value={`${item.value}`}>{item.label}</MenuItem>
                          )))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                }
                {!isPrivate() && isElectronicInvoice() &&
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="einvoiceidentifier"
                        name="einvoiceidentifier"
                        error={errorFields.includes('einvoiceidentifier') ? true : false}
                        label={t('einvoceidentifier')}
                        value={values.einvoiceidentifier}
                        onChange={handleChange}
                        fullWidth
                        disabled={values.isBusy ? true : false}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                }
                {isPrivate() && !props.isFromCheckout && !props.newUser &&
                  <Grid item xs={12} md={8}>
                    <PaymentMethods
                      user={props.user}
                      ref={childRef}
                      editCard={editCard}
                      setEditCard={setEditCard}
                      setIsBusy={setIsBusy}
                    />
                  </Grid>
                }
                {(!editMode && !props.isFromCheckout) &&
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={(
                          <Checkbox color="primary" checked={values.agreed} name="agreed" onChange={handleCheckChange} />
                        )}
                        label={t('iagreetotermsandconditions')}
                        labelPlacement="end" />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://fruitboxoffice.fi/toimitusehdot/"
                        className={`${link_classes.link_primary}`}>{t('termsandconditions')}</a>
                    </Grid>
                  </Grid>
                }
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <p className={paper_classes.error}>
                      {values.error ?? t('paymentfailed') }
                    </p>
                  </Grid>
                </Grid>
                {!props.isFromCheckout && (<Grid container>
                  <Grid item xs={12} sm={12} md={3}></Grid>
                  <Grid item xs={12} sm={12} md={6} className={grid_classes.relative}>
                    <Button
                      disabled={!!values.isBusy}
                      type="submit"
                      className={`${button_classes.noRadius} ${button_classes.default} ${button_classes.block}`}
                      variant="contained"
                      color="primary">
                      {values.isBusy ? t('validating') : (editMode) ? t('savechanges') : t('submit')}
                    </Button>
                    {values.isBusy &&
                      (<CircularProgress size={24} className={button_classes.buttonProgress} />)
                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}></Grid>
                </Grid>)}
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Form;
