import isFunction from 'lodash/isFunction';
import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Link } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import DaySelector from './OrderFormFields/DaySelector';
import DateSelector from './OrderFormFields/DateSelector';
import VariantSelector from './OrderFormFields/VariantSelector';
import QtyInput from './OrderFormFields/QtyInput';
import button_styles from '../../style/button';
import input_styles from '../../style/input';
import grid_style from '../../style/grids';
import link_style from '../../style/links';
import Price from '../Utils/Price';
import { ORDER_OPTION, PRODUCT_ADD_ONS } from '../../config';
import { push2Cart, editItemCart } from '../../libs/cart';
import { gtmEvents } from '../../libs/tagmanager';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import fiLocale from 'date-fns/locale/fi';
import enLocale from 'date-fns/locale/en-US';
import i18next from '../../i18n';
import disableDates from '../Utils/DisabledDates';
import CheckBox from '@material-ui/core/Checkbox';
import uniq from 'lodash/uniq';
import isArray from 'lodash/isArray';
import SimpleTaxDisplay from '../Utils/SimpleTaxDisplay';

interface Dictionary<T> {
  [key: string]: T;
}

interface DateRange {
  start: Date | number | null,
  end: Date | number | null,
  apply: boolean
}

class CustomDateFnsUtils extends DateFnsUtils {
  getDatePickerHeaderText(date:Date) {
    return format(date, 'dd.MM.yy', {locale: i18next.language === 'fi' ? fiLocale : enLocale})
  }
}

const dayQtyDefault : any = {
    Monday: 1,
    Tuesday: 1,
    Wednesday: 1,
    Thursday: 1,
    Friday: 1,
    Saturday: 1,
    Sunday: 1,
    OnceOnly: 1,
  }

const radioDaysDefault: any = {
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false
}

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday"
];

function OrderForm(props: any) {
  const { t } = useTranslation();
  const button_classes = button_styles();
  const input_classes = input_styles();
  const grid_classes = grid_style();
  const link_classes = link_style();
  const {
    isDialog,
    isPrivate,
    orderDetails
  } = props;
  const { items: cartItems } = props?.cart ?? {};
  const { item: orderProduct } = props?.family ?? {};
  const {
    slug,
    iscontinuouspossible: isRecurring
  } = props?.family?.item ?? {};
  const isAddOn = PRODUCT_ADD_ONS.includes(slug);
  const dateLocale = i18next.language === 'fi' ? fiLocale : enLocale;

  const [range, setRange] = useState<DateRange>({
    start: null,
    end: null,
    apply: false
  });

  // If product is an add on, should be able to add the product
  // based on the days of the current cart items.
  var addOnEnabledDays:string[] = [];
  if ( isAddOn &&
    !isDialog &&
    isArray(cartItems) &&
    !!cartItems?.length
  ) {
    cartItems?.forEach((item:any) => {
      item?.details?.forEach((detail:any) => {
        if (detail.day) {
          addOnEnabledDays.push(detail.day);
        }
      })
    })
    addOnEnabledDays = uniq(addOnEnabledDays);
  }

  // Add function to adds days exclude weekends. (one time order)
  const addBusinessDays  = (days:any ) => {
    var d = moment().add(Math.floor(days / 5) * 7, 'd');
    var remaining = days % 5;
    while (remaining) {
      d.add(1, 'd');
      if (d.day() !== 0 && d.day() !== 6)
        remaining--;
    }
    return d;
  }
  let dateDefault = addBusinessDays(3);

  orderDetails?.details?.forEach((item: any) => {
    if (item.day) {
      dayQtyDefault[item.day] = item.quantity;
      radioDaysDefault[item.day] = true;
    }
    else if (item.date) {
      dateDefault = item.date;
      dayQtyDefault.OnceOnly = item.quantity;
    }
    else {
      dayQtyDefault.OnceOnly = item.quantity;
    }
  });


  const [busy, setBusy] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(dateDefault);
  const [routeToOverview, setRouteToOverview] = React.useState(false);
  const [values, setValues] = React.useState({
    deliveryDaysDefault: ORDER_OPTION.deliveryDaysDefault,
    deliveryOptionsDefault: !isRecurring ? 'One time only' : ORDER_OPTION.deliveryOptionsDefault,
    quantity: ORDER_OPTION.quantity,
    radioDeliveryOption: !isRecurring ? 'One time only' : (orderDetails?.orderOption ?? ORDER_OPTION.deliveryOptionsDefault),
    isUpSell: orderDetails?.isupsell ?? false
  });
  const [radioDays, setDayValues] = React.useState<Dictionary<boolean>>(
    props.newItem
      ? {
          Monday: false,
          Tuesday: false,
          Wednesday: false,
          Thursday: false,
          Friday: false,
          Saturday: false,
          Sunday: false,
        }
      : radioDaysDefault
  );
  const [dayQty, setDayQtyValues] = React.useState<Dictionary<number>>(
    props.newItem
      ? {
          Monday: 1,
          Tuesday: 1,
          Wednesday: 1,
          Thursday: 1,
          Friday: 1,
          Saturday: 1,
          Sunday: 1,
          OnceOnly: 1,
        }
      : dayQtyDefault
  );
  const [defaultVariant, setDefaultVariant] = React.useState(props.defaultVariant);

  function usePrevious(value:any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const details = orderDetails?.details ?? [];
  const prevDetails:any = usePrevious({ details });

  // === Start GTM ===
  // useEffect(() => {
  //   if (!!!prevDetails || !isEqual(prevDetails.details, details)) {
  //     const _dayQty : any = {
  //       Monday: 1,
  //       Tuesday: 1,
  //       Wednesday: 1,
  //       Thursday: 1,
  //       Friday: 1,
  //       Saturday: 1,
  //       Sunday: 1,
  //       OnceOnly: 1,
  //     }
  //     const _radioDays: any = {
  //       Monday: false,
  //       Tuesday: false,
  //       Wednesday: false,
  //       Thursday: false,
  //       Friday: false,
  //       Saturday: false,
  //       Sunday: false
  //     }

  //     if (orderDetails?.details) {
  //       orderDetails.details.forEach((item: any) => {
  //         if (item.day) {
  //           _dayQty[item.day] = item.quantity;
  //           _radioDays[item.day] = true;
  //         }
  //       });
  //       setDayValues(_radioDays);
  //       setDayQtyValues(_dayQty);
  //     }
  //   }
  // }, [details,  prevDetails, orderDetails]);

  useEffect(() => {
    if (props.defaultVariant) {
      setDefaultVariant(props.defaultVariant);
    }
  },[ props.defaultVariant]);
  // === End GTM ===

  const handleChange = (name: any) => (event: any) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSetRange = (target:any, val:any) => {
    setRange({ ...range, [target]: val});
  }

  const calcCost = () => {
    let cost = 0;
    if(isRecurring) {
      if((values.radioDeliveryOption === 'One time only') && (dayQty.OnceOnly > 0)) {
        cost = dayQty.OnceOnly * props.defaultVariant.price;
      } else {
        let qty = 0;
        Object.keys(radioDays).forEach((key) => {
          if(radioDays[key]) {
            qty = Number(qty) + Number(dayQty[key]);
          }
        });
        cost = Number(qty) * Number(props.defaultVariant.price);
      }
    } else {
      cost = dayQty.OnceOnly * props.defaultVariant.price;
    }
    props.setTotalCost(cost);
  }
  calcCost();

  const resetForm = () => {
    setDayValues({
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
    });

    setDayQtyValues({
      Monday: 1,
      Tuesday: 1,
      Wednesday: 1,
      Thursday: 1,
      Friday: 1,
      OnceOnly: 1,
    });
  }

  const handlePushCart = () => {
    const details = [];
    if (isRecurring) {
      if ((values.radioDeliveryOption === 'One time only') && (dayQty.OnceOnly > 0)) {
        details.push({date: selectedDate, quantity: dayQty.OnceOnly});
      } else {
        Object.keys(radioDays).forEach((key) => {
          if(radioDays[key]) {
            details.push({day: key, quantity: dayQty[key]});
          }
        });
      }
    } else {
      details.push({quantity: dayQty.OnceOnly});
    }

    const product = {
      productId: props.defaultVariant.productId,
      family: props.family.item.slug,
      orderOption: values.radioDeliveryOption,
      isContinuous: props.family.item.iscontinuouspossible,
      isupsell: values.isUpSell,
      ischeap: isAddOn,
      temp: range,
      details: details,
      uomid: props.defaultVariant.uomid
    }

    if (isDialog) {
      if (props.cartId) {
        const originalItem = props.cart.items.find(
          (item: any) => item.id === props.cartId
        );
        const addressSelected = {
          city: originalItem.city,
          streetAddress: originalItem.streetAddress,
          zipCode: originalItem.zipCode,
          hash: originalItem.hash,
        };
        const updatedProduct = {
          ...product,
          ...(addressSelected.hash ? addressSelected : {}),
        };
        editItemCart(
          props.cart.items.filter((item: any) => !!!item.ischeap),
          updatedProduct,
          props.cartId,
          props.updateCart
        );
        props.setDialogOpen(false);
      } else {
        setBusy(true);
        if (isFunction(props.onUpdateOrder)) {
          props.onUpdateOrder(product, () => {
            setBusy(false);
            props.setDialogOpen(false);
            props.displayInfo();
          });
        } else {
          props.setDialogOpen(false);
          throw new Error('Missing `onUpdateOrder` prop');
        }
      }
    } else {
      push2Cart(props.cart.items, product, props.updateCart);
      const variant_detail = props.family.item.variants.filter( (variant : any )  => variant.productId === product.productId);
      const total_qty = product.details.reduce((acc, detail) => acc + detail.quantity, 0);
      gtmEvents([{
        'name': props.family.item.name,
        'id': props.defaultVariant.productId,
        'price': props.defaultVariant.price,
        'brand': 'Fruitbox',
        'category': props.family.slug,
        'variant': props.family.item.variant_type,
        'dimension1' : variant_detail[0].name,
        'dimension2' : (props.family.item.variant_type === 'weight') ? product.orderOption : '',
        'quantity': total_qty,
      }],'add_to_cart',total_qty);
      resetForm();
      setRouteToOverview(true);
    }
  }

  const enableCTA = ():boolean => {
    if (busy) {
      return false;
    }
    if (range.apply && (!range.start || !range.end)) {
      return false;
    }
    if (!!!props.totalCost) {
      return false;
    }
    if (isDialog && isPrivate) {
      return false;
    }
    return true;
  }

  if (routeToOverview) {
    return <Redirect to="/orderoverview" />
  }

  // If no price found, product is reward.
  if (props?.family?.item?.variants[0]?.price === 0) {
    return (
      <React.Fragment>
        <strong><p>{t('products.productisrewardonly')}</p></strong>
        <Link className={link_classes.link_primary} to={'/rewards'}>
          {t('products.viewrewards')}
        </Link>
      </React.Fragment>
    );
  }

  // If product is add-on and no items in cart are present, display
  // message that the product is for add-on only.
  if (!props?.isDialog && isAddOn && !!!cartItems?.length) {
    return (
      <React.Fragment>
        <strong><p>{t('products.productrequiresotherproducts')}</p></strong>
        <Link className={link_classes.link_primary}
          to={{pathname: '/products', state: {funnel: 1}}}
        >
          {t('products.continueshopping')}
        </Link>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
    { (
      isDialog ||
      ( (!isDialog && !isAddOn) ||
        (!isDialog && isAddOn && props.cart.items.length > 0) )
      ) &&
    <Grid container>
      <Grid item xs={12}>
        {(orderProduct?.variant_type === 'weight' &&
          orderProduct?.variants?.length > 1 &&
          <FormControl component="fieldset"
            className={clsx(input_classes.margin, input_classes.textField, input_classes.formgroupInline)}
            disabled={props?.isDialog && props?.isPrivate}
          >
            <FormLabel component="legend">
              {orderProduct.variant_type === 'weight' ? t('products.orderform.selectweight') : t('products.orderform.paymentterms') }
            </FormLabel>
            <VariantSelector family={props.family}
              defaultVariant={defaultVariant}
              setSelectedVariant={props.setSelectedVariant}
            />
          </FormControl>
        )}
        {(orderProduct.iscontinuouspossible && !props.isUpCrossSell &&
          <div>
            <FormControl component="fieldset" className={clsx(input_classes.margin, input_classes.textField, input_classes.formgroupInline)}>
              <FormLabel component="legend">{t('products.orderform.deliveryschedule')}</FormLabel>
              <RadioGroup
                aria-label="Delivery Schedule"
                name="radioDeliveryOption"
                className={input_classes.textField}
                value={values.radioDeliveryOption}
                onChange={handleChange('radioDeliveryOption')}>
                <Grid container>
                { ORDER_OPTION.deliveryOptions.map((option: any, index: any) => {
                  // if (isDialog && option.value === 'One time only') {
                  //   return <React.Fragment key={index} />;
                  // }
                  return (
                    <Grid item sm={4} key={index}>
                      <FormControlLabel
                        value={option.value}
                        control={<Radio />}
                        label={t(option.locale)}
                        disabled={props?.isDialog && props?.isPrivate}
                      />
                    </Grid>
                  );
                })}
                </Grid>
              </RadioGroup>
            </FormControl>
            {(values.radioDeliveryOption !== 'One time only' &&
              <div className={input_classes.separatedFormControls}>
                <FormControl className={clsx(input_classes.margin, input_classes.textField)}>
                  <FormLabel component="legend">{t('products.orderform.deliveryday')}</FormLabel>
                  <br/>
                  {
                  <Typography variant="body1">
                    <span style={{color: 'red'}}>*</span>{t('products.orderform.deliverydays')}
                  </Typography>
                  }
                </FormControl>
                {
                  days.map((day) => {
                    var isDisabled = false;
                    // If product is add on, enable only days based from
                    // other main products.
                    if (isAddOn && !addOnEnabledDays.includes(day)) {
                      isDisabled = true;
                    }
                    // If edit mode and account is private, disable edit.
                    if (isDialog && props.isPrivate) {
                      isDisabled = true;
                    }
                    return (
                      <DaySelector
                        key={day}
                        day={day}
                        dayQty={dayQty}
                        radioDays={radioDays}
                        setDayQtyValues={setDayQtyValues}
                        setDayValues={setDayValues}
                        disableDay={isDisabled}
                      />
                    );
                  })
                }
              </div>
            )}
            {(values.radioDeliveryOption === 'One time only' &&
              <FormControl className={clsx(input_classes.margin, input_classes.textField)}>
                <FormLabel component="legend">{t('products.orderform.deliverydate')}</FormLabel>
                <DateSelector dayQty={dayQty} setDayQtyValues={setDayQtyValues} selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
              </FormControl>
            )}
          </div>
        )}
        {(!isRecurring && !props.isUpCrossSell &&
          <div>
            <FormControl className={clsx(input_classes.margin, input_classes.textField)}>
              <FormLabel component="legend">{t('products.orderform.quantity')}</FormLabel>
              <QtyInput dayQty={dayQty} setDayQtyValues={setDayQtyValues} />
            </FormControl>
          </div>
        )}
      </Grid>
      {(!isDialog &&
        <Grid item xs={12}>
          <br/>
          <hr className={grid_classes.hr}/>
          <Typography variant="h6">{t('general.total')}:</Typography>
          <Typography variant="h2" color={'secondary'}>
            <Price amount={props.totalCost} />
            {(props.isPrivate || !props.isSignedIn) &&
              <SimpleTaxDisplay subTotal={props.totalCost} />
            }
          </Typography>
        </Grid>
      )}
      { isDialog &&
        !props.viewOnly &&
        !props.isUpCrossSell &&
        !isPrivate && 
        !props.isFromCheckout &&
        <React.Fragment>
          <Grid container spacing={2} style={{ marginTop: '10px'}}>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <CheckBox color="primary"
                    checked={range.apply}
                    onChange={() => handleSetRange('apply', !range.apply)}
                  />
                )}
                label={t('orders.applyastemporary')} />
            </Grid>
          </Grid>
          { range.apply &&
            <Grid container spacing={2} style={{ paddingTop: 10 }}>
              <Grid item xs={12} md={6}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel error={!range.start} component="legend">{t('orders.startdate')}</FormLabel>
                  <MuiPickersUtilsProvider utils={CustomDateFnsUtils} locale={dateLocale}>
                    <DatePicker
                      error={!range.start}
                      fullWidth
                      value={range.start}
                      onChange={(val) => handleSetRange('start', val)}
                      shouldDisableDate={disableDates}
                      format="dd.MM.yy"
                      cancelLabel={t('buttons.cancel')}
                      />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel error={!range.end} component="legend">{t('orders.enddate')}</FormLabel>
                  <MuiPickersUtilsProvider utils={CustomDateFnsUtils} locale={dateLocale}>
                    <DatePicker
                      error={!range.end}
                      fullWidth
                      value={range.end}
                      onChange={(val) => handleSetRange('end', val)}
                      shouldDisableDate={disableDates}
                      format="dd.MM.yy"
                      cancelLabel={t('buttons.cancel')}
                      />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
            </Grid>
          }
        </React.Fragment>
      }
      { !props.viewOnly &&
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={isDialog ? 12: 6} lg={isDialog ? 12: 4}>
              <br/>
              <Button
                fullWidth
                onClick={handlePushCart}
                disabled={!enableCTA()}
                className={`${button_classes.noRadius} ${button_classes.default}`}
                variant="contained"
                color="primary">
                {t('buttons.' + (isDialog ? 'savechanges': 'addtoorder'))}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    </Grid>
    }
    </React.Fragment>
  );
}

export default OrderForm;
