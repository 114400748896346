import React from 'react';
import DashboardAnon from './DashboardAnon';
import DashboardUser from './DashboardUser';
import QFBox from '../Base/QueryContainer/QFBox';

export default function Dashboard(props: any) {
  const { isSignedIn } = props;
  const isAuthenticated = props?.location?.state?.isAuthenticated ?? false;

  if (!isSignedIn && !isAuthenticated) {
    return (
      <DashboardAnon {...props} />
    );
  } else {
    return (
      <QFBox
        resource={[
          'dashboarduser',
          'user',
          'orders'
        ]}
        {...props}
        component={DashboardUser}
      />
    );
  }
}
