import React from "react";
import { Redirect } from "react-router";
import { Auth } from "aws-amplify";
import { removeCookie, setCookie } from "../../libs/cookie";
import { ADDRESSES_COOKIE } from "../Base/QueryContainer/Addresses";
import { cache } from "../../App";
import ToastError from "../Base/ToastError";
import { CreateOrderProvider } from "../Contexts/Order";

function Signout(props: any) {
  Auth.signOut()
    .then(() => {
      props.onStateChange("signedOut");
      removeCookie(ADDRESSES_COOKIE);
      setCookie("cart", "");
      props.updateCart({ items: [] });
      cache.reset();
    })
    .catch((err) => {
      let error_message = ((err || {}) as any).message || "Signout failed";
      ToastError(error_message);
    });

  if (!props.isSignedIn) {
    return (
      <CreateOrderProvider>
        <Redirect to="/signin" />
      </CreateOrderProvider>
    );
  } else {
    return <div>Signing out...</div>;
  }
}

export default Signout;
