import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';

import Welcome from './Welcome';
import UpSell from './UpSell';
import Jumbotron from './Jumbotron';
import CrossSell from './CrossSell';
import grid_style from '../../style/grids';
import { getUpSell, getCrossSell } from '../../libs/product';
import { gtmEvents } from '../../libs/tagmanager';
import Banner from './Banner';
import { labelLookup } from '../Base/OrderFrequency';
import { getCookie } from '../../libs/cookie';

export default function DashboardLayout(props: any) {
  const {
    frequencies,
    days,
    orders,
    isPrivate
  } = props;
  const grid_classes = grid_style();
  const upSellList = getUpSell(props.orders, props.products, 3);
  const crossSellList = getCrossSell(props.orders, props.cart, props.products, 4, !isPrivate);
  const base = (props.orders || []).find((o: any) => (o.orderfrequency !== null));
  const welcomepopup = getCookie('welcome') === "1";
  window.history.replaceState(
    { ...(props.location?.state ?? {}), welcomepopup: false },
    ""
  ); // reset welcomepopup location state

  useEffect(() => {
    const crossSellProducts: any = [];
    const upSellProducts: any = [];
    crossSellList.forEach((item: any, idx: number) => {
      crossSellProducts.push({
        name: item.family.name,
        id: item.family.variants[0].productId,
        price: item.family.variants[0].price,
        brand: 'Fruitbox',
        category: item.categorySlug,
        variant: item.family.variant_type,
        list: 'cross sell list',
        position: idx
      })
    });
    upSellList.forEach((item: any, idx: number) => {
      upSellProducts.push({
        name: item.family.name,
        id: item.family.variants[0].productId,
        price: item.family.variants[0].price,
        brand: 'Fruitbox',
        category: item.categorySlug,
        variant: item.family.variant_type,
        list: 'up sell list',
        position: idx
      })
    });

    gtmEvents(crossSellProducts, 'product_impression', '');
    gtmEvents(upSellProducts, 'product_impression', '');
  }, [  upSellList, crossSellList]);

  var optionsDays = {};
  days.forEach((option: any) => {
    optionsDays = {...optionsDays, [option.value]: option.label};
  });

  const frequency = labelLookup[frequencies.find((f: any) => f.value === base?.orderfrequency)?.label ?? ''];
  var upSellOptions: any = (!base || !frequency) ? {} : {
    frequency: frequency,
    quantity: base.quantity
  };
  // Append quantity per week day.
  if (base) {
    base.order_details.forEach((od: any) => {
      const weekday = days[od.deliveryweekday],
        weekdayQty = od.quantity;
      if (weekday && weekdayQty && weekdayQty > 0) {
        upSellOptions[weekday] = weekdayQty;
      }
    });
  }

  const showUpSell = !!orders && !isPrivate;

  return (
    <React.Fragment>
      <Grid container className={clsx(grid_classes.root, grid_classes.top_content_container)}>
        <Grid item sm={12}>
          <Welcome user={props.user} orders={props.data} welcomepopup={welcomepopup} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item className={grid_classes.carouselContainer}>
          <Banner images={props?.images ?? []} />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={clsx(grid_classes.container_padding)}>
        <Grid item sm={12} md={9}>
          <Grid container>
            <Grid item sm={12}>
              <Jumbotron {...props} />
            </Grid>
            { showUpSell &&
              <Hidden mdUp>
                <Grid item sm={12}>
                  <UpSell isSideBar={false}
                    upSellList={upSellList}
                    upSellOptions={upSellOptions}
                    orders={props.orders}
                    refetchOrders={props.refetch}
                  />
                </Grid>
              </Hidden>
            }
            <Grid item sm={12}>
              <CrossSell crossSellList={crossSellList}
                orders={props.orders}
                refetch={props.refetch}
              />
            </Grid>
          </Grid>
        </Grid>
        { showUpSell &&
          <Hidden smDown>
            <Grid item sm={12} md={3}>
              <UpSell isSideBar={true}
                upSellList={upSellList}
                upSellOptions={upSellOptions}
                orders={props.orders}
                refetchOrders={props.refetch}
              />
            </Grid>
          </Hidden>
        }
      </Grid>
    </React.Fragment>
  );
}
