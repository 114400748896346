export const nav_user = [
  { label: 'menu.myfruitbox', 'link': '/' },
  { label: 'menu.products', 'link': '/products' },
  { label: 'menu.orderhistory', 'link': '/orders' },
  // { label: 'menu.rewards', 'link': '/rewards' },
  { label: 'menu.profile', 'link': '/profile' },
  { label: '' },
  { label: 'menu.signout', 'link': '/signout' },
  { label: 'menu.cart', 'link': '/orderoverview' },
]

export const nav_steps = [
  { label: 'menu.step1', 'link': '/products' },
  { label: 'menu.step2' },
  { label: 'menu.step3', 'link': '/signin' }
]

export const nav_anon = [
  { label: 'menu.products', 'link': '/products' },
  { label: '' },
  { label: 'menu.signin', 'link': '/signin' },
  { label: 'menu.cart', 'link': '/orderoverview' },
]

export const nav_funnel = [
  { label: 'menu.step1', 'link': '/orderoverview' },
  { label: 'menu.step2', 'link': '/checkout' },
  { label: 'menu.step3', 'link': '/checkout' }
];
