import { PRODUCT_ADD_ONS } from '../config';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';


export const flattenOrders = (orders:any, productIds?: string[], compact: boolean = false) => {
  var res: any[] = [];
  // Filter continuous orders only.
  const continuousOrders = orders.filter((order: any) =>
    order.deliveryweekday &&
    order.orderfrequency &&
    productIds?.includes(order.productid) &&
    !PRODUCT_ADD_ONS.includes(order.productslug)
  );

  continuousOrders.forEach((order: any) => {
    // Find from template with the same product id, shipping address, and order frequency.
    const fields = [
      'productid',
      'orderfrequency',
      'customername',
      'shiptoline1',
      'shiptocity',
      'shiptozip'
    ];

    let resIndex = -1;
    if (!compact) {
      res.findIndex((r: any) => {
        if (isEqual(pick(r, fields), pick(order, fields))) {
          for (const detail of r?.order_details ?? []) {
            if (detail?.orderid === order.salesorderid) {
              return true;
            }
          }
        }
        return false;
      })
    } else {
      resIndex = res.findIndex((r: any) => {
        const a_order = mapEmptyValueToNull(pick(r, fields));
        const b_order = mapEmptyValueToNull(pick(order, fields));
        const ab_equal = isEqual(a_order, b_order);
        return ab_equal;
      });
    }

    const otherProducts = orders.filter((o:any) =>
      o.salesorderid === order.salesorderid &&
      o.salesorderdetailid !== order.salesorderdetailid
    ).map((o:any) => o.productid);

    if (resIndex < 0) {
      var newOrderTemplate = orderTemplate(order);
      newOrderTemplate.order_details[0].otherproducts = otherProducts;
      res.push(newOrderTemplate);
    } else {
      const curQty = res?.[resIndex]?.quantity ?? 0;
      res[resIndex].quantity = curQty + order.quantity;
      const detailIndex = res[resIndex].order_details?.findIndex((detail: any) =>
        detail?.deliveryweekday === order?.deliveryweekday
      );
      if (detailIndex >= 0) {
        const curQty = res?.[resIndex]?.order_details?.[detailIndex]?.quantity ?? 0;
        res[resIndex].order_details[detailIndex].quantity = curQty + order.quantity;
      } else {
        var newDetail = detailTemplate(order);
        newDetail.otherproducts = otherProducts;
        res[resIndex].order_details.push(newDetail);
      }
    }
  });
  return res;
}

function detailTemplate (sampleOrder: any): any {
  const template = {
    orderid: sampleOrder.salesorderid,
    deliveryweekday: sampleOrder.deliveryweekday,
    quantity: sampleOrder.quantity,
    orderids: [sampleOrder.salesorderid],
    otherproducts: []
  }
  return template;
}

function orderTemplate (sampleOrder: any): any {
  const template = {
    productid: sampleOrder.productid,
    uomid: sampleOrder.uomid,
    orderfrequency: sampleOrder.orderfrequency,
    customername: sampleOrder?.customername ?? '',
    shiptoline1: sampleOrder.shiptoline1,
    shiptozip: sampleOrder.shiptozip,
    shiptocity: sampleOrder.shiptocity,
    priceperunit: sampleOrder.priceperunit,
    orderispaused: sampleOrder.orderispaused,
    quantity: sampleOrder.quantity,
    subscriptionId: sampleOrder.subscriptionid,
    order_details: [{
      orderid: sampleOrder.salesorderid,
      deliveryweekday: sampleOrder.deliveryweekday,
      quantity: sampleOrder.quantity,
      orderids: [sampleOrder.salesorderid],
      otherproducts: []
    }],
    originalOrderId:sampleOrder.ordernumber,
    order_ids: [sampleOrder.salesorderid],
    tempchanges: sampleOrder?.tempchanges ?? []
  }
  return template;
}

function mapEmptyValueToNull(o:any) {
  Object.keys(o).forEach((key) => {
    if(o[key] === '') {
      o[key] = null;
    }
  });
  return o;
}

export const CHECKOUT_REGISTRATION_STAGES = {
  waiting:'waiting',
  verify:'verify',
  ready:'ready'
};