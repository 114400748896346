import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import trim from 'lodash/trim';
import uniqBy from 'lodash/uniqBy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import QFBox from './QFBox';
import EditAddress from '../EditAddress';
import ConfirmationDialog from '../ConfirmationDialog';
import { getCookie, setCookie } from '../../../libs/cookie';
import { HOST_AWS_SERVERLESS } from '../../../config';
import { AppContext, AppState } from '../../../App';
import ToastError from '../ToastError';
import axios from 'axios';
import { handleError } from '../../../libs/utils';


const sha1 = require('sha1');

export const ADDRESSES_COOKIE: string = 'addresses';

/**
 * Combine addresses
 */
export function combineAddresses(user: any, orders: any, local: any): any {
  const addresses: Array<any> = [];
  let hash: any;
  // Addresses from user data
  if (user && user.deliveryAddress) {
    (user.deliveryAddress || []).forEach((deliveryAddress: any) => {
      hash = getAddressHash(deliveryAddress)
      if (hash) {
        addresses.push({
          ...deliveryAddress,
          hash,
          source: 'user'
        });
      }
    });
  }
  // Addresses from order data
  if (orders) {
    (orders || []).forEach((order: any) => {
      const orderAddress = {
        customerName: order.customername,
        city: order.shiptocity,
        streetAddress: order.shiptoline1,
        zipCode: order.shiptozip
      };
      hash = getAddressHash(orderAddress)
      if (hash) {
        addresses.push({
          ...orderAddress,
          hash,
          source: 'order'
        });
      }
    })
  }
  // Addresses from local data
  if (local) {
    (local || []).forEach((localAddress: any) => {
      hash = getAddressHash(localAddress);
      if (hash) {
        addresses.push({
          ...localAddress,
          hash,
          source: 'local'
        });
      }
    })
  }
  return keyBy(
    uniqBy(addresses, 'hash')
      .map((address: any, index: number) => ({ ...address, index })),
    'index'
  );
}

/**
 * Get address hash
 */
export function getAddressHash(address: any) {
  const flat = ['customerName', 'streetAddress', 'zipCode', 'city']
    .map((name: string) => trim(address[name] || '').toLowerCase())
    .filter((value: string) => !isEmpty(value))
    .join(', ');
  return flat ? sha1(flat) : null;
}

/**
 * Address exists
 */
export function addressExists(addresses: any, address: any): boolean {
  const hash = getAddressHash(address),
    hasIndex = !isUndefined(address.index) && address.index !== null;
  return !!keys(addresses).find((key: string) => {
    return addresses[key].hash === hash && (!hasIndex || (address.index !== addresses[key].index));
  });
}

export default function Addresses(props: any) {
  const { t } = useTranslation();
  const [addresses, setAddresses] = React.useState({});
  const [currentUser, setUser] = React.useState({} as any);
  const [removeAddressDialog, setRemoveAddressDialog] = React.useState({
    address: {},
    open: false
  });
  const [addressDialog, setAddressDialog] = React.useState({
    address: {},
    others: {},
    dialog: {},
    cartId:undefined,
    updateCartFn:undefined,
    touched: false
  } as any);

  return (
    <AppState.Consumer>
      {({ refetch }: AppContext) => {
        const editAddress = (address: any, cartId?:any, updateCartFn?:any) => {
          (addressDialog as any).dialog.open();
          setAddressDialog({
            ...addressDialog,
            address,
            cartId: cartId,
            updateCartFn,
            touched: false
          });
        };

        const editOrderAddress = (others: any) => {
          const { order } = others;
          const address = {
            index: 0,
            customerName: order.customername,
            streetAddress: order.shiptoline1,
            zipCode: order.shiptozip,
            city: order.shiptocity,
            source: 'order',
          };
          (addressDialog as any).dialog.open();
          setAddressDialog({
            ...addressDialog,
            address,
            others,
            touched: false
          });
        }

        const storeAddresses = (object: any) => {
          setCookie(ADDRESSES_COOKIE,
            keys(object)
              .map((key: string) => (object as any)[key])
              .filter(({ source }) => (source === 'local'))
              .map((address: any) => omit(address, ['index'])))
          return object;
        };

        const removeAddress = (address: any) => {
          setRemoveAddressDialog({
            address,
            open: true
          });
        };

        const updateUser = (address: any) => {
          return Auth.currentAuthenticatedUser()
            .then(user => {
              const accessToken = user.signInUserSession.accessToken.jwtToken;
              const updateData: any = {
                account_id: currentUser.accountId,
                contact_id: currentUser.contactId,
                given_name: currentUser.givenName,
                family_name: currentUser.familyName,
                phone_number: currentUser.phone,
                account_name: currentUser.company,
                invoice_street: currentUser.billingAddress.streetAddress,
                invoice_city: currentUser.billingAddress.city,
                invoice_zip: currentUser.billingAddress.zipCode,
                delivery_street: address.streetAddress,
                delivery_city: address.city,
                delivery_zip: address.zipCode,
                payment_method: Number(currentUser.invoicemethod),
                business_id: currentUser.companyid,
                account_phone_number: currentUser.phone
              };
              return axios({
                method: 'post',
                url: `${HOST_AWS_SERVERLESS}/users/update`,
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json'
                },
                data: updateData
              }).then((response: any) => {
                refetch('user');
                return response;
              }).catch((error: any) => {
                handleError(error);
                let error_message = ((error || {}) as any).message || 'Update failed';
                ToastError(error_message);
                return Promise.reject(error_message);
              })
            });
        }

        const deleteAddress = (address: any) => {
          if (!isUndefined(address.index) && address.index !== null) {
            setAddresses(storeAddresses(keyBy(keys(addresses).map((key: string) => (addresses as any)[key]).filter(({ index }: any) => {
              return address.index !== index;
            }).map((data: any, index: number) => {
              return {
                ...data,
                index
              };
            }), 'index')));
          }
        };

        const updateOrder = (address: any) => {
          const { order, product, frequencies, weekdays } = addressDialog.others;
          var details = [];
          if (order.order_details) {
            details = order.order_details.map((od: any) => {
              return {
                day: weekdays[od.deliveryweekday],
                quantity: od.quantity
              }
            });
          }
          const item: any = {
            productId: order.productid,
            family: product.slug,
            orderOption: frequencies[order.orderfrequency],
            orderOption_id: order.orderfrequency,
            isContinuous: product.iscontinuouspossible,
            details: details,
          };
          const data = [{
            ...item,
            city: address.city,
            pricelevelid: product.pricelevelid,
            customerName: address?.customerName ?? '',
            streetAddress: address.streetAddress,
            transactioncurrencyid: product.transactioncurrencyid,
            variant_type: product.variant_type,
            zipCode: address.zipCode,
            orderreference: order.orderreference,
            customerName_original: order?.customername ?? '',
            streetAddress_original: order.shiptoline1,
            zipCode_original: order.shiptozip,
            city_original: order.shiptocity,
            originalOrderId: order.originalOrderId,
            isPrivate: props.isPrivate
          }];

          return Auth
            .currentSession()
            .then(authorization => {
              const token = authorization.getAccessToken();
              return axios({
                method: 'post',
                url: `${HOST_AWS_SERVERLESS}/orders/update`,
                data,
                headers: {
                  Authorization: `Bearer ${token.getJwtToken()}`
                }
              }).then(() => {
                refetch('orders');
                setAddressDialog({
                  ...addressDialog,
                  others: {}
                });
                return 0;
              }).catch((err: any) => {
                let error_message = ((err || {}) as any).message || 'Update failed';
                ToastError(error_message);
                handleError(err);
                setAddressDialog({
                  ...addressDialog,
                  others: {}
                });
                return Promise.reject(err);
              });
            });
        };

        const saveAddress = (address: any) => {
          if (addressDialog.others.order) {
            return updateOrder(address);
          }
          if (addressExists(addresses, address)) {
            return Promise.reject(t('address.addressexists'));
          }
          if (!isUndefined(address.index) && address.index !== null) {
            const promises: Array<any> = [];
            if (address.source === 'user') {
              promises.push(updateUser(address));
            }
            setAddresses(storeAddresses({
              ...addresses,
              [address.index]: {
                ...address,
                hash: getAddressHash(address),
                index: address.index
              }
            }));
            return Promise
              .all(promises)
              .then(() => address.index);
          } else {
            const index = keys(addresses).length;
            setAddresses(storeAddresses({
              ...addresses,
              [keys(addresses).length]: {
                ...address,
                hash: getAddressHash(address),
                index
              }
            }));
            return Promise.resolve(index);
          }
        };

        // Address rendering for signed-out users.
        if (!props.isSignedIn) {
          if (isEmpty(addresses) && !isEmpty(getCookie(ADDRESSES_COOKIE))) {
            setAddresses(combineAddresses(
              null,
              null,
              getCookie(ADDRESSES_COOKIE) || []
            ));
          }
          return (
            <>
              {
                props.children({
                  addresses: keys(addresses).map((i) => (addresses as any)[i]),
                  editAddress,
                  editOrderAddress,
                  removeAddress,
                  saveAddress
                })
              }
              <EditAddress 
                address={addressDialog.address}
                cartId={addressDialog.cartId}
                saveAddress={saveAddress}
                updateCartFn={addressDialog.updateCartFn}
                cart={props.cart}
                title={t('orders.editaddress')}>
                {(dialog: any) => {
                  if (!addressDialog.touched) {
                    setAddressDialog({
                      ...addressDialog,
                      dialog,
                      touched: true
                    });
                  }
                }}
              </EditAddress>
              <ConfirmationDialog
                open={removeAddressDialog.open}
                setOpen={(open: boolean) => {
                  setRemoveAddressDialog({
                    ...removeAddressDialog,
                    open
                  });
                }}
                yesAction={() => deleteAddress(removeAddressDialog.address)}
                contentText={t('orders.deleteaddressconfirmation')}
                contentTitle={t('orders.deleteaddress')} 
              />
            </>
          );
        }

        // Default addresses rendering when user is signed in.
        return (
          <>
            <QFBox
              resource={['orders']} {...omit(props, ['resource'])}
              component={(subprops: any) => {
                if (isEmpty(currentUser)) {
                  setUser(subprops.user);
                }
                if (isEmpty(addresses)) {
                  setAddresses(combineAddresses(
                    subprops.user,
                    subprops.orders,
                    getCookie(ADDRESSES_COOKIE) || []
                  ));
                }
                return props.children({
                  addresses: keys(addresses).map((i) => (addresses as any)[i]),
                  editAddress,
                  editOrderAddress,
                  removeAddress,
                  saveAddress
                });
              }} 
            />
            <EditAddress 
              address={addressDialog.address}
              cartId={addressDialog.cartId}
              saveAddress={saveAddress}
              updateCartFn={addressDialog.updateCartFn}
              cart={props.cart}
              title={t('orders.editaddress')}>
              {(dialog: any) => {
                if (!addressDialog.touched) {
                  setAddressDialog({
                    ...addressDialog,
                    dialog,
                    touched: true
                  });
                }
              }}
            </EditAddress>
            <ConfirmationDialog
              open={removeAddressDialog.open}
              setOpen={(open: boolean) => {
                setRemoveAddressDialog({
                  ...removeAddressDialog,
                  open
                });
              }}
              yesAction={() => deleteAddress(removeAddressDialog.address)}
              contentText={t('orders.deleteaddressconfirmation')}
              contentTitle={t('orders.deleteaddress')} 
            />
          </>
        );
      }}
    </AppState.Consumer>
  )
}
