import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UpSellCard from '../Base/UpSellCard';
import UpSellCardSmall from '../Base/UpSellCardSmall';
import grid_style from '../../style/grids';
import card_style from '../../style/card';

const UpSell = (props: any) => {
  const { t } = useTranslation();
  const grid_classes = grid_style();
  const card_classes = card_style();
  const { orders } = props;

  return (
    <React.Fragment>
      <Grid container className={`${grid_classes.root} ${grid_classes.container_padding_on_smDown}`} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" className={card_classes.headerText}>
            {t('dashboard.upsellsidebar')}
          </Typography>
          <Typography variant="body1">
            {t('dashboard.upsellcontent')}
          </Typography>
        </Grid>
      </Grid>
      {(
        <Grid container className={`${grid_classes.root} ${grid_classes.top_content_container}`} spacing={2}>
          {( props.upSellList.map((item: any, idx: any) => {

            if (props.isSideBar) {
              return (
                <UpSellCard key={idx}
                  upSellItem={item}
                  upSellOptions={props.upSellOptions}
                  orders={orders}
                  refetchOrders={props.refetchOrders}
                />
              );
            } else {
              return (
                <UpSellCardSmall key={idx}
                  upSellItem={item}
                  upSellOptions={props.upSellOptions}
                  orders={orders}
                  refetchOrders={props.refetchOrders}
                />
              );
            }
          }))}
        </Grid>
      )}
    </React.Fragment>
  );
}

export default UpSell;
