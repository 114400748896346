import React, { useState, useCallback, useEffect }  from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import SigninForm from '../../components/Auth/SigninForm'
import QFBox from "../Base/QueryContainer/QFBox";
import SignupForm from "../Auth/SignupForm";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ItemDetail from './ItemDetail';
import grid_style from '../../style/grids';
import paper_style from '../../style/paper';
import link_style from '../../style/links';
import button_style from '../../style/button';
import { getFamily } from '../../libs/product';

const OrderSummary: React.FC = (props: any) => {
  const { t } = useTranslation();
  const grid_classes = grid_style();
  const paper_classes = paper_style();
  const link_classes = link_style();
  const button_classes = button_style();
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsOpenLoginModal(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  if (props.cart.items.length === 0) {
    return (
      <Grid container >
        <Grid item sm={12} md={12}>
          <Grid container className={`${grid_classes.root} ${grid_classes.top_content_container} ${grid_classes.product_content_container}`}>
            <Grid item sm={12}>
              <Typography variant="h5">
                {t('orders.noitems')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <React.Fragment>
      <Grid container className={`${grid_classes.root} ${grid_classes.top_content_container}`}>
        <Grid item sm={12} md={6}>
          <Typography variant="h3">
            {t('orders.products')}
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} className={grid_classes.checkoutAllProductsLink}>
          <Typography variant="h5" color="primary">
            <Link
              to={{
                pathname: '/products',
              }}
              className={link_classes.link_primary}>{t('orders.continueshopping')}</Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid container >
        <Grid item sm={12} md={12}>
          <Grid container className={`${grid_classes.root}`}>
            <Grid item sm={12}>
              <Paper className={`${paper_classes.paper} ${paper_classes.login_container}`}>
                {props.cart.items.map((product: any, index:any) => {
                  const family: any = getFamily(product.family, props.products);
                  return (
                    <ItemDetail
                      editAddress={props.editAddress}
                      addresses={props.addresses}
                      user={props.user}
                      isSignedIn={props.isSignedIn}
                      isPrivate={props.isPrivate}
                      key={product.id ?? index}
                      productFamily={family}
                      orderDetails={product}
                      cartId={product.id}
                      cart={props.cart}
                      updateCart={props.updateCart}
                      isBusy={props.isBusy}
                      setIsBusy={props.setIsBusy} />
                  );
                })}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!props.isSignedIn && (
        <React.Fragment>
          <Grid
            container
            className={`${grid_classes.root} ${grid_classes.top_content_container}`}
          >
            <Grid container>
              <Grid item sm={12} md={6}>
                <Typography variant="h3">{t("signup.contactInfo")}</Typography>
              </Grid>
              <Grid
                item
                sm={12}
                md={6}
                className={grid_classes.checkoutAllProductsLink}
              >
                <Typography variant="h5" color="primary">
                  <p
                    className={`${link_classes.link_primary} ${button_classes.pointer}`}
                    onClick={() => setIsOpenLoginModal(true)}
                  >
                    {t("buttons.signin")}
                  </p>
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Paper
                className={`${paper_classes.paper} ${paper_classes.login_container}`}
              >
                <Grid container>
                  <Grid item sm={12}>
                    <QFBox
                      resource={["picklists"]}
                      component={SignupForm}
                      setIsBusy={props.setIsBusy}
                      cart={props.cart ? props.cart : {}}
                      emitFormValues={props.emitSignupValues}
                      signupStage={props.signupStage}
                      setSignupStage={props.setSignupStage}
                      isFromCheckout
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Dialog
            maxWidth="md"
            classes={{ paper: paper_classes.login_modal }}
            open={isOpenLoginModal}
          >
            <DialogTitle className={grid_classes.dialogHeader}>
              <CloseIcon
                className={button_classes.pointer}
                onClick={() => setIsOpenLoginModal(false)}
              />
            </DialogTitle>
            <SigninForm
              {...props}
              asModalForm
              onStateChange={props.onStateChange}
              setUser={props.setUser}
              setUserEmail={props.setUserEmail}
              setIsPrivate={props.setIsPrivate}
            />
          </Dialog>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default OrderSummary;
