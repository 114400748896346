import React from 'react';
import Grid from '@material-ui/core/Grid';
import Add from '@material-ui/icons/Add';
import Minus from '@material-ui/icons/Remove';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import fi from 'date-fns/locale/fi';
import en from 'date-fns/locale/en-US';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

class CustomDateFnsUtils extends DateFnsUtils {
  getDatePickerHeaderText(date:Date) {
    return format(date, 'dd.MM.yy', {locale: useTranslation().i18n.language === 'fi' ? fi : en})
  }
}

function DateSelector(props: any) {

  const { t, i18n } = useTranslation(),
    minDate = moment().add(3, 'days');

  let locale;
  if (i18n.language === 'fi') {
    locale = fi;
  }
  else {
    locale = en;
  }

  function handleDateChange(date: any) {
    props.setSelectedDate(date);
  }

  const handleDayQtyChange = (name: any, val: any, op: any) => (event: any) => {
    if (op === '+') {
      props.setDayQtyValues({ ...props.dayQty, [name]: val + 1});
    } else if (val > 1) {
      props.setDayQtyValues({ ...props.dayQty, [name]: val - 1});
    }
  };

  function disableWeekends(date:any) {
    return date.getDay()===0 || date.getDay()===6;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={9}>
        <MuiPickersUtilsProvider utils={CustomDateFnsUtils} locale={locale}>
          <DatePicker
            value={props.selectedDate}
            onChange={handleDateChange}
            shouldDisableDate={disableWeekends}
            minDate={minDate}
            okLabel={t('buttons.ok')}
            cancelLabel={t('buttons.cancel')}
            format="dd.MM.yy"
            />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Input
          type={'number'}
          value={props.dayQty['OnceOnly']}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton aria-label="" onClick={handleDayQtyChange('OnceOnly', props.dayQty['OnceOnly'], '-')} >
                <Minus />
              </IconButton>
              <IconButton aria-label="" onClick={handleDayQtyChange('OnceOnly', props.dayQty['OnceOnly'], '+')}>
                <Add />
              </IconButton>
            </InputAdornment>)}
          />
      </Grid>
    </Grid>
  );
}

export default DateSelector;
