import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CartOverview from './CartOverview';
import Suggestions from './Suggestions';
import QFBox from '../Base/QueryContainer/QFBox';
import { useTranslation } from 'react-i18next';

import grid_style from '../../style/grids';
import { getItem, getFamily } from '../../libs/product';
import { gaCheckoutSteps } from '../../libs/tagmanager';

function Info(props: any) {
  const { t } = useTranslation();
  const grid_classes = grid_style();

  const products = props.products;
  const displayedItem = props.cart.items[props.cart.items.length - 1];
  const family: any = getFamily(displayedItem.family, products);
  const variant: any = getItem(displayedItem.productId, family.item);

  useEffect(() => {
    gaCheckoutSteps(products, props.cart, 1);
  }, [  products, props.cart]);

  return (
    <React.Fragment>
      <Grid container className={`${grid_classes.root} ${grid_classes.container_padding}`} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2">
            {t('orders.productadded')}
          </Typography>
        </Grid>
      </Grid>
      <CartOverview family={family} variant={variant} displayedItem={displayedItem} cart={props.cart} />
      <Suggestions products={products} cart={props.cart} orders={props.orders} />
    </React.Fragment>
  );
}

function Overview(props: any) {
  const { t } = useTranslation();
  const grid_classes = grid_style();
  if (props.cart.items.length === 0) {
    return (
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Grid container
            className={`${grid_classes.root} ${grid_classes.top_content_container} ${grid_classes.product_content_container}`}
            style={{ padding: '24px', marginTop: '24px' }}>
            <Grid item sm={12}>
              <Typography variant="h5">
                {t('orders.noitemsadded')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  if (props.isSignedIn) {
    return <QFBox resource={['products', 'user', 'orders']} {...props} component={Info} />
  } else {
    return <QFBox resource={['products']} {...props} component={Info} />
  }
}

export default Overview;

