import { makeStyles } from '@material-ui/core/styles';

const link_style = makeStyles(theme => ({
  link_primary: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '& :hover': {
        textDecoration: 'underline',
    }
  },
  span_link: {
    fontWeight: 700,
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  normal_text: {
    textDecoration: 'none',
    color: "#000",
  },
  conditionsLink: {
    paddingLeft: theme.spacing(6)
  },
  pause_order: {
    cursor: 'pointer',
    color: '#fff',
    marginTop: 0,
    position: 'relative',
    height: '52px',
    '& span.pauseText, span.resumeText': {
      fontSize: '1.25rem',
      fontWeight: 700,
      position: 'absolute',
      left: '48px',
      bottom: '17px'
    },
    '& div.pauseIcon': {
      position: 'absolute',
    }
  },
  no_margin: {
    marginTop: '0px'
  }
}));

export default link_style;
