import React from 'react';
import { Query } from 'react-apollo';

import { PRODUCTS_QUERY } from '../../query/products';
import SimpleLoader from '../Loader/SimpleLoader';
import ItemDetails from './ItemDetails';
import { getFamily, assignImages } from '../../libs/product';
import PageNotFound from '../../layouts/DefaultLayout/PageNotFound';

const Details: React.FC = (props: any) => {
  return (
    <Query<any> query={PRODUCTS_QUERY}>
      {({loading, error, data}) => {
        if (loading) {
          return <SimpleLoader/>;
        }
        if (error) return null;

        assignImages(data, data?.images ?? []);
        const products = data;
        const family = !!getFamily(props.match.params.family, products).slug;

        if (!family) {
          return <PageNotFound />
        }

        return (
          <ItemDetails {...props} products={products} newItem={true} />
        );
      }}
    </Query>
  );
};

export default Details;
