import { getFamily } from '../libs/product';

export function gtmEvents(products : any , type : any , action : any){

  let data : any = {};
  switch(type) {
    case 'product_impression': {
      data = {
        event: 'eec.impressionView',
        eventAction: 'Ecommerce',
        eventCategory: 'Impression View',
        ecommerce : {
          'impressions': products
        }
      }
      break;
    }
    case 'product_click': {
      data = {
        event: 'eec.impressionClick',
        eventAction: 'Ecommerce',
        eventCategory: 'Impression Click',
        ecommerce : {
          actionField: {
            list: action
          },
          'click' : {
              'products' : products
            }
          }
      }
      break;
    }
    case 'product_detail_impression': {
      data = {
        event: 'eec.detail',
        eventAction: 'Ecommerce',
        eventCategory: 'Product Detail View',
        ecommerce: {
          'detail': {
            actionField: {
              list: action
            },
            'products': products
            }
          }
      }
      break;
    }
    case 'add_to_cart': {
      data = {
        event: 'eec.add',
        eventAction: 'Ecommerce',
        eventCategory: 'Add to Cart',
        totalQty: action, // total qty later
        ecommerce: {
          'add': {
            actionField: {
              list: 'Shopping cart'
            },
            'products': products
            }
          }
      }
      break;
    }
    case 'remove_from_cart': {
      data = {
        event: 'eec.remove',
        eventAction: 'Ecommerce',
        eventCategory: 'Remove from Cart',
        totalQty: action, // total qty later
        ecommerce: {
          'remove': {
            actionField: {
              list: 'Shopping cart'
            },
            'products': products
            }
          }
      }
      break;
    }
    case 'checkout_step': {
      data = {
        event: 'eec.checkout',
        eventAction: 'Ecommerce',
        eventCategory: `Checkout step ${action}`,
        ecommerce: {
          checkout: {
            actionField: {
              step: action
            },
            'products': products
          }
        }
      }
      break;
    }
    case 'purchase': {
      data = {
        event: 'eec.purchase',
        eventAction: 'Ecommerce',
        eventCategory: 'Purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: action.id,
              affiliation: 'Fruitbox',
              revenue: `${action.revenue.toFixed(2)}`,
              tax: '0.00',
              shipping: '0.00',
              coupon: action.coupon
            },
            'products': products
          }
        }
      }
      break;
    }
    default: {
      break;
    }
  }

  (window as any).dataLayer.push(data);
}

export function gaPurchase(products : any , cart : any  , action : any){
  gtmEvents(formatCart( {products,cart }), 'purchase', action);
}

export function gaCheckoutSteps(products : any , cart : any , steps : number){
  gtmEvents(formatCart( { products , cart }),'checkout_step',steps);
}

function formatCart(data : any ){
  const {cart, products} = data;
  const product_cart : any = [];
  cart.items.forEach((item:any) => {
    const total_qty = item.details.reduce((acc : any , detail : any) => acc + detail.quantity, 0);
    const familyCart : any = getFamily(item.family, products);
    const variant_detail = familyCart.item.variants.filter( (variant : any )  => variant.productId === item.productId);
    product_cart.push(
      {
      'name': familyCart.item.name,
      'id': item.productId,
      'brand': 'Fruitbox',
      'category': familyCart.slug,
      'variant': familyCart.item.variant_type,
      'price': variant_detail[0].price,
      'dimension1' : variant_detail[0].name,
      'dimension2' : (item.isContinuous) ? item.orderOption : '',
      'quantity': total_qty
      }
    );
  });

  return product_cart;
}
