import React from 'react';
import Grid from '@material-ui/core/Grid';
import Form from './SigninForm';
import grid_style from '../../style/grids';
import Next, { applyNext } from './Next';

function Signin(props: any) {
  const grid_classes = grid_style();
  if (props.isSignedIn) {
    return <Next />;
  }
  const next: any = applyNext(props);
  if (next) {
    return next;
  }
  return (
    <div className={grid_classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} xl={4} lg={3} md={2}></Grid>
        <Grid item xs={12} sm={12} xl={4} lg={6} md={8}>
          <Form {...props}
            onStateChange={props.onStateChange}
            setUser={props.setUser}
            setUserEmail={props.setUserEmail}
            setIsPrivate={props.setIsPrivate}
          />
        </Grid>
        <Grid item xs={12} sm={12} xl={4} lg={3} md={2}></Grid>
      </Grid>
    </div>
  );
};

export default Signin;
