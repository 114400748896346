import keys from 'lodash/keys';
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Add from '@material-ui/icons/Add';
import Minus from '@material-ui/icons/Remove';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import { useTranslation } from 'react-i18next';

import input_styles from '../../../style/input';
import button_styles from '../../../style/button';
import clsx from 'clsx';

function DaySelector(props: any) {
  const { t } = useTranslation();
  const input_classes = input_styles();
  const button_classes = button_styles();
  const { disableDay } = props;

  const handleDayChange = (name: any) => (event: any) => {
    let radioDays: any = {};
    if (props.single === true) {
      keys(props.radioDays).forEach((day: string) => {
        radioDays[day] = false;
      });
    } else {
      radioDays = props.radioDays;
    }
    props.setDayValues({ ...radioDays, [name]: event.target.checked });
  };

  const handleDayQtyChange = (name: any, val: any, op: any) => (event: any) => {
    if (op === '+') {
      props.setDayQtyValues({ ...props.dayQty, [name]: Number(val) + 1});
    } else if (val > 1) {
      props.setDayQtyValues({ ...props.dayQty, [name]: Number(val) - 1});
    }
  };

  return (
    <FormControl className={clsx(input_classes.margin, input_classes.textField)}>
      <FormGroup>
        <Grid container>
          <Grid item xs={12} sm={9}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={props.radioDays[props.day]}
                  onChange={handleDayChange(props.day)}
                  value={props.radioDays[props.day]}
                  disabled={disableDay}
                  />
              )}
              label={t('general.' + props.day.toLowerCase())} />
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} sm={3}>
              <Input
                fullWidth
                id="adornment-password"
                style={{display: props.radioDays[props.day] ? '' : 'none'}}
                type={'number'}
                value={props.dayQty[props.day]}
                disabled={disableDay}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label=""
                      onClick={handleDayQtyChange(props.day, props.dayQty[props.day], '-')}
                    >
                      <Minus />
                    </IconButton>
                    <IconButton
                      aria-label=""
                      onClick={handleDayQtyChange(props.day, props.dayQty[props.day], '+')}
                    >
                      <Add />
                    </IconButton>
                  </InputAdornment>
                )} />
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item sm={6}>
              <Grid container style={{display: props.radioDays[props.day] ? '' : 'none'}}>
                <Grid item xs={4} className={`${button_classes.buttonQtyContainer} middle right`}>
                  <IconButton aria-label=""
                    onClick={handleDayQtyChange(props.day, props.dayQty[props.day], '-')}
                    className={`${button_classes.buttonQtyMobile} nomargin`}
                    disabled={props.dayQty[props.day] && props.dayQty[props.day] <= 1}>
                    <Minus />
                  </IconButton>
                </Grid>
                <Grid item xs={4} className={`${button_classes.buttonQtyContainer}`}>
                  <Input
                    fullWidth
                    id="adornment-password"
                    type={'number'}
                    value={props.dayQty[props.day]}
                    disableUnderline={true}
                    className={`${input_classes.inputQtyMobile}`}/>
                </Grid>
                <Grid item xs={4} className={`${button_classes.buttonQtyContainer} middle left`}>
                  <IconButton aria-label=""
                    onClick={handleDayQtyChange(props.day, props.dayQty[props.day], '+')}
                    className={`${button_classes.buttonQtyMobile} nomargin`}>
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </FormGroup>
    </FormControl>
  );
}

export default DaySelector;
