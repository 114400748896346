import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import form_style from '../../style/form';
import button_style from '../../style/button';
import paper_style from '../../style/paper';
import grid_style from '../../style/grids';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import { forgotPasswordErrorMap } from './ForgotPasswordForm';

import 'url-search-params-polyfill';

const Form: React.FC = (props: any) => {
  const { t } = useTranslation();
  const form_classes = form_style();
  const button_classes = button_style();
  const paper_classes = paper_style();
  const grid_classes = grid_style();

  const urlParams = new URLSearchParams(props.location.search);
  let defaultEmail = urlParams.get('email') ? urlParams.get('email') : '';
  defaultEmail = decodeURIComponent(defaultEmail ?? '');
  const [values, setValues] = useState({
    username: defaultEmail,
    password: '',
    code: '',
    isBusy: false,
    isResending: false,
    isSuccess: false,
    error: ''
  });

  const handleChange = (event: any) => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  async function handleResend(event: any) {
    if (event) {
      event.preventDefault();
    }
    setValues(values => ({
      ...values,
      isBusy: true,
      isResending: true,
      error: ''
    }));
    Auth.forgotPassword(values.username ? values.username.toLowerCase() : '')
      .then(() => {
        setValues(values => ({
          ...values,
          isBusy: false,
          isResending: false,
          error: ''
        }));
      })
      .catch((err: any) => {
        let errMsg: string;
        if (err.code) {
          errMsg = err.message;
        } else {
          errMsg = err;
        }
        setValues(values => ({
          ...values,
          isBusy: false,
          isResending: false,
          error: t('forgotpassword.' + (forgotPasswordErrorMap[errMsg] || 'unknown')),
        }));
      });
  };

  async function handleSubmit(event: any) {
    if (event) {
      event.preventDefault();
    }
    setValues(values => ({
      ...values,
      isBusy: true,
      error: ''
    }));
    Auth.forgotPasswordSubmit(values.username ? values.username.toLowerCase() : '', values.code, values.password)
      .then(() => {
        setValues(values => ({
          ...values,
          isBusy: false,
          error: '',
          isSuccess: true
        }));
      })
      .catch((err: any) => {
        var errMsg = '';
        if (typeof err === "string") {
          switch (err) {
            case 'Username cannot be empty': {
              // Email is required.
              errMsg = t('err_msgs.emailisempty');
              break;
            }
            case 'Code cannot be empty': {
              // Code is required.
              errMsg = t('err_msgs.codeisempty');
              break;
            }
            case 'Password cannot be empty': {
              // Password is required.
              errMsg = t('err_msgs.passwordisempty');
              break;
            }
          }
        }
        else {
          switch (err.code) {
            case 'CodeMismatchException': {
              // Invalid code.
              errMsg = t('err_msgs.codemismatch');
              break;
            }
            case 'InvalidParameterException': {
              // When password length is less than required, throws InvalidParameterException instead.
              errMsg = t('err_msgs.passwordtooshort');
              break;
            }
            case 'ExpiredCodeException': {
              // Code has expired.
              errMsg = t('err_msgs.codeexpired');
              break;
            }
            case 'InvalidPasswordException': {
              switch (err.message) {
                case 'Password does not conform to policy: Password must have symbol characters': {
                  errMsg = t('err_msgs.passwordneedssymbol');
                  break;
                }
                case 'Password does not conform to policy: Password must have uppercase characters': {
                  errMsg = t('err_msgs.passwordneedsuppercase');
                  break;
                }
                case 'Password did not conform with policy: Password must have numeric characters': {
                  errMsg = t('err_msgs.passwordneedsnumber');
                  break;
                }
                default: {
                  errMsg = t('err_msgs.unknown');
                  break;
                }
              }
              break;
            }
            default: {
              errMsg = t('err_msgs.unknown');
              break;
            }
          }
        }

        setValues(values => ({
          ...values,
          isBusy: false,
          error: errMsg,
        }));
      });
  };

  if (values.isSuccess) {
    return <ResetPasswordSuccess />;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Paper className={`${paper_classes.paper} ${paper_classes.login_container}`}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h3">Reset password</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <form onSubmit={handleSubmit} className={form_classes.container} noValidate autoComplete="off">
              <TextField
                id="username"
                name="username"
                label={t('forgotpassword.email')}
                value={values.username}
                onChange={handleChange}
                fullWidth
                disabled={values.isBusy ? true : false || defaultEmail ? true : false}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }} />
              <TextField
                id="code"
                name="code"
                label={t('forgotpassword.code')}
                value={values.code}
                onChange={handleChange}
                fullWidth
                disabled={values.isBusy ? true : false}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }} />
              <TextField
                id="password"
                name="password"
                label={t('forgotpassword.newpassword')}
                helperText={t('signup.passwordrules')}
                value={values.password}
                onChange={handleChange}
                fullWidth
                disabled={values.isBusy ? true : false}
                margin="normal"
                type="password"
                InputLabelProps={{
                  shrink: true,
                }} />
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <p className={paper_classes.error}>
                    {values.error ? values.error : ''}
                  </p>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={3}></Grid>
                <Grid item xs={12} sm={12} md={6} className={grid_classes.relative}>
                  <Button
                    disabled={!!values.isBusy}
                    type="submit"
                    className={`${button_classes.noRadius} ${button_classes.default} ${button_classes.block}`}
                    variant="contained"
                    color="primary">
                    {t('forgotpassword.' + (values.isBusy ? (values.isResending ? 'resending' : 'validating') : 'submit'))}
                  </Button>
                  {(values.isBusy &&
                    <CircularProgress size={24} className={button_classes.buttonProgress} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={3}></Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <p onClick={handleResend} className={paper_classes.category} color="primary" style={{ textAlign: 'center', cursor: 'pointer' }}>
                    {t('forgotpassword.resendcode')}
                  </p>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Form;
