import { makeStyles } from '@material-ui/core/styles';

const loader_style = makeStyles(() => ({
  loader: {
    position: 'fixed',
    top: '45%',
    width: '100%',
    height: '100%',
    marginLeft: '-24px',
    textAlign: 'center'
  },
  loader2: {
    position: 'absolute',
    top:0,
    bottom:0,
    left:0,
    right: 0,
    margin: 'auto',
  },
  dialogLoader: {
    position: 'fixed',
    top: '45%',
    width: '100%',
    height: '100%',
    color: '#fff',
    marginLeft: '-24px',
  },
  componentLoader: {
    position: 'relative',
    width: '100%',
    height: '100%'
  }
}));

export default loader_style;
