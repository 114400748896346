import React, { useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import paper_style from '../../style/paper';
import grid_style from '../../style/grids';
import button_style from '../../style/button';

import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';

export interface NewPasswordProps {
  amplifyUser: any;
}

const NewPassword: React.FC<NewPasswordProps> = (props: any) => {
  const grid_classes = grid_style();
  const paper_classes = paper_style();
  const button_classes = button_style();

  const { amplifyUser } = props;
  const translate = useTranslation().t;
  const t = (message: string) => translate('resetpassword.' + message);

  const handleChange = (event: any) => {
    event.persist();
    setValues((values:any) => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!values.password) {
      setError(t('passwordnotset'));
    }

    if (!values.retypepassword) {
      setError(t('retypepasswordnotset'));
    }

    if (values.password && values.retypepassword) {
      if (values.password === values.retypepassword) {
        setLoading(true);
        Auth.completeNewPassword(
          values.user,
          values.password,
          {}
        )
        .then(user => {
          Auth.signOut()
            .then(() => {
              setSuccess(true);
            });
        })
        .catch(err => {
          setError(err.message ?? t('unkownerror'));
        })
        .finally(() => {
          setLoading(false);
        });
        ;
      } else {
        setError(t('passwordsdoesnotmatch'));
      }
    }
  }

  const [values, setValues] = useState({
    user: amplifyUser,
    password: '',
    retypepassword: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  if (success) {
    return (
      <Paper className={`${paper_classes.paper} ${paper_classes.login_container}`}>
        <Typography variant="h4">{t('resetpasswordsuccess')}</Typography>
        <br></br>
        <Button
          className={`${button_classes.noRadius} ${button_classes.default} ${button_classes.block}`}
          variant="contained"
          color="primary"
          onClick={() => {window.location.href = '/signin'; }}
        >
          {t('signin')}
        </Button>
      </Paper>
    )
  }

  return (
    <div className={grid_classes.root}>
      <Paper className={`${paper_classes.paper} ${paper_classes.login_container}`}>
        <Typography variant="h3">{t('selectnewpassword')}</Typography>
        <TextField
          id="newpassword"
          name="password"
          helperText={t('passwordrules')}
          label={t('newpassword')}
          type="password"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChange}
        />
        <TextField
          id="retypepassword"
          name="retypepassword"
          helperText={t('passwordrules')}
          label={t('retypepassword')}
          type="password"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChange}
        />
        <Button
          disabled={loading}
          className={`${button_classes.noRadius} ${button_classes.default} ${button_classes.block}`}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          {t('submit')}
        </Button>
        {(loading &&
          (<CircularProgress size={24} className={button_classes.buttonProgress} />)
        )}
        <p className={paper_classes.error}>
          {error ?? ''}
        </p>
      </Paper>
    </div>
  );
};

export default NewPassword;
