import React from 'react';

import QFBox from '../Base/QueryContainer/QFBox';
import Products from './Products';

const ProductList: React.FC = (props: any) => {
  if (props.isSignedIn) {
    return <QFBox resource={['picklists', 'images', 'products', 'user', 'orders']} {...props} component={Products} />
  }
  else {
    return <QFBox resource={['picklists', 'images', 'products']} {...props} component={Products} />
  }

};

export default ProductList;
