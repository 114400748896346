import { makeStyles } from '@material-ui/core/styles';

const container_style = makeStyles(theme => ({
  body: {
    paddingLeft: '0px',
    paddingRight: '0px',
    maxWidth: '1400px',
    overflow: 'hidden',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xl')]: {
      maxWidth: '1400px',
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1160px',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '720px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    }
  }
}));

export default container_style;
