import gql from 'graphql-tag';

export const ACCOUNT_QUERY = gql`
  query Account {
    account {
      accountid
      accounttype
      contactid
      firstname
      lastname
      email
      phone
      accountname
      businessid
      accountemail
      addressline1
      addresscity1
      addresszip1
      addressline2
      addresscity2
      addresszip2
      accountphone
      paymentmethod
      startingpoints
      earnedrewardpoints
      countrycodeinvoice
      countrycodedelivery
      einvoiceoperator
      einvoiceidentifier
      stripeid
      stripepaymentmethods {
        id
        brand
        lastfour
        exp_month
        exp_year
      }
    }
  }
`;
