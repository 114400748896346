import React from 'react';
import { Query } from 'react-apollo';
import { SALESORDER_OPTIONS_QUERY } from '../../../query/orderOptions';
import SimpleLoader from '../../Loader/SimpleLoader';
import { labelLookup } from '../OrderFrequency';

/**
 * Frequencies query
 */
const FrequenciesQuery: React.FC = (props: any) => {
  return (
    <Query<any> query={SALESORDER_OPTIONS_QUERY}>
      {({ loading, error, data }) => {
        if (loading) {
          return <SimpleLoader />;
        }
        if (error) return null;

        const frequencyOptions = data?.orderPicklists?.find((o: any) => {
          return o.logicalName === 'fs_orderfrequency'
        })?.options ?? [];

        var frequencies: any = {};
        for (const option of frequencyOptions) {
          const label = labelLookup[option.label];
          if (label) {
            frequencies = {...frequencies, [option.value]: labelLookup[option.label]};
          }
        }

        return props.children(frequencies);
      }}
    </Query>
  );
}

export default FrequenciesQuery;
