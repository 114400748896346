import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import button_style from "../../style/button";
import paper_style from "../../style/paper";
import grid_style from "../../style/grids";
import link_style from "../../style/links";
import { useState } from "react";
import { Auth } from "aws-amplify";

export default function Verification(props: any) {
  const { t } = useTranslation();
  const button_classes = button_style();
  const paper_classes = paper_style();
  const grid_classes = grid_style();
  const link_classes = link_style();

  var urlParams = new URLSearchParams(
    props.location ? props.location.search ?? "" : ""
  );
  const email = urlParams.get("username") ?? props.email ?? "";
  const code = urlParams.get("code") ?? props.email ?? "";

  const [values, setValues] = useState({
    username: email,
    password: "",
    code: code,
    isBusy: false,
    isResending: false,
    isSuccess: false,
    error: "",
  });

  async function verify() {
    setValues((values) => ({
      ...values,
      isBusy: true,
      error: "",
    }));

    Auth.confirmSignUp(
      values.username ? values.username.toLowerCase() : "",
      values.code,
      {
        forceAliasCreation: true,
      }
    )
      .then((data) => {
        setValues((values) => ({
          ...values,
          isBusy: false,
          error: "",
          isSuccess: true,
        }));
      })
      .catch((err) => {
        let errMsg = t("err_msgs.unknown");
        if (err.code) {
          errMsg = err.message;
        } else {
          errMsg = err;
        }
        setValues((values) => ({
          ...values,
          isBusy: false,
          error: errMsg,
          isSuccess: true,
        }));
      });
  }
  if (email && code && !values.isBusy && !values.isSuccess && !values.error) {
    verify();
  }

  return values.isSuccess ? (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Paper
          className={`${paper_classes.paper} ${paper_classes.login_container}`}
        >
          <Grid item xs={12} sm={12}>
            <Typography variant="h3">
              {!values.error
                ? t("signup.accountallset")
                : t("signup.verificationexpired")}
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={3} lg={4}></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              className={grid_classes.relative}
            >
              <Link
                to={!values.error ? `/signin` : `/confirmsignup?email=${email}`}
                className={`${button_classes.block} ${link_classes.normal_text}`}
              >
                <Button
                  className={`${button_classes.noRadius} ${button_classes.default}  ${button_classes.block}`}
                  variant="contained"
                  color="primary"
                >
                  { !values.error ? t("buttons.signin") : t('signup.resendcode')}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={4}></Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  ) : (
    ""
  );
}
