import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { AppState } from '../../App';

/**
 * Scroll to top
 */
class ScrollToTop extends React.Component<RouteComponentProps> {

  render() {
    return (
      <AppState.Consumer>
        {({ state, setState }) => {
          switch (this.props.location.pathname.substr(1)) {
            case 'orderoverview':
              if (state.funnel !== 1) {
                setState({ ...state, funnel: 1 });
              }
              break;
            case 'checkout':
              if (state.funnel < 2) {
                setState({ ...state, funnel: 2 });
              }
              break;
            default:
              if (state.funnel !== 0) {
                setState({ ...state, funnel: 0 });
              }
              break;
          }
          return this.props.children
        }}
      </AppState.Consumer>
    )
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
    }
  }
}

export default withRouter(ScrollToTop);
