import gql from 'graphql-tag';

export const PICKLISTS = gql`
  query Picklists {
    accountPicklists: picklists(entity: "account", fields: ["fs_einvoiceoperator", "fs_paymentmethod", "fs_customertype"]) {
      logicalName
      displayName
      options {
        label
        value
      }
    }
    orderPicklists: picklists(entity: "salesorder", fields: ["fs_deliveryweekday", "fs_orderfrequency"]) {
      logicalName
      displayName
      options {
        label
        value
      }
    }
    imagePicklists: picklists(entity:"fs_productimage", fields: ["fs_imagetype"]) {
      logicalName
      displayName
      options {
        label
        value
      }
    }
  }
`;

export default PICKLISTS;
