import React from 'react';
import Grid from '@material-ui/core/Grid';
import ProductCardSmall from '../Base/ProductCardSmall';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { intersection, compact } from 'lodash';

import grid_style from '../../style/grids';
import card_style from '../../style/card';

const CrossSell = (props: any) => {
  const { t } = useTranslation();
  const grid_classes = grid_style();
  const card_classes = card_style();

  return (
  <React.Fragment>
    <Grid container className={`${grid_classes.root} ${grid_classes.container_padding}`} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" className={card_classes.headerText}>
          {t('dashboard.crosssellheader')}
        </Typography>
        <Typography variant="body1">
          {t('dashboard.crosssellcontent')}
        </Typography>
      </Grid>
    </Grid>
    <Grid container className={`${grid_classes.root} ${grid_classes.top_content_container}`} spacing={2}>
      {
        compact(props.crossSellList.map((item: any, idx: any) => {

          const crossSellIds:Array<String> = item.family.variants.map((variant:any) => variant.productId);
          const orderList:Array<any> = props.orders.filter((order:any) => {
            const detailsCommon = order.order_details.map((detail:any) => {
              return intersection(detail.otherproducts, crossSellIds).length === 0;
            });
            return !detailsCommon.includes(false);
          });

          if (item.isExtra && orderList.length === 0) {
            return null;
          }

          return (
            <ProductCardSmall key={idx}
              family={{item: item.family, slug: item.categorySlug }}
              orders={item.isExtra ? orderList: null }
              refetch={item.isExtra ? props.refetch : null }
            />
          );
        }))
      }
    </Grid>
  </React.Fragment>);
}

export default CrossSell;
