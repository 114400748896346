import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const MAX_AGE = 60 * 30;
export const COOKIE_STORE_INDEX_KEY = '_index';

/**
 * Get
 */
export function getCookie(name: string) {
  return cookies.get(name);
}

/**
 * Set
 */
export function setCookie(name: string, value: any) {
  return cookies.set(name, value, {
    path: '/'
  });
}

/**
 * Remove cookie
 */
export function removeCookie(name: string) {
  return cookies.remove(name, {
    path: '/'
  });
}

/**
 * Cookie storage
 */
export class CookieStorage {

  constructor(protected prefix: string = 'fruitbox.') {}

  /**
   * Get index
   */
  get index(): Array<string> {
    return getCookie(this.prefix + COOKIE_STORE_INDEX_KEY) || [];
  }

  /**
   * Set index
   */
  set index(index: Array<string>) {
    setCookie(this.prefix + COOKIE_STORE_INDEX_KEY, index);
  }

  /**
   * Set cookie item
   */
  setItem(key: string, value: string): string {
    const index: Array<string> = this.index;
    if (index.indexOf(key) < 0) {
      index.push(key);
    }
    setCookie(this.prefix + key, value);
    this.index = index;
    return value;
  }

  /**
   * Get cookie item
   */
  getItem(key: string): string {
    return getCookie(this.prefix + key);
  }

  /**
   * Remove item
   */
  removeItem(key: string): void {
    const index = this.index,
      position = index.indexOf(key);
    if (position >= 0) {
      index.splice(position, 1);
    }
    removeCookie(this.prefix + key);
    this.index = index;
  }

  /**
   * Clear
   */
  clear(): void {
    this.index.forEach((key: string) => {
      this.removeItem(key);
    });
  }
}

export default cookies;
