import gql from 'graphql-tag';

export const IMAGES = gql`
  query Images {
    images  {
      id
      type
      url
      productfamily
    }
  }
`;
