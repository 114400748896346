import Dashboard from './components/Dashboard/Dashboard';
import ProductList from './components/Products/List';
import ProductDetails from './components/Products/Details';
import Checkout from './components/Checkout/List';
import OrderOverview from './components/OrderOveriew/List';
import Signin from './components/Auth/Signin';
import ForgotPasswordForm from './components/Auth/ForgotPasswordForm';
import ResetPassword from './components/Auth/ResetPassword';
import Signup from './components/Auth/Signup';
import SignupConfirm from './components/Auth/SignupConfirm';
import OrderHistory from './components/OrderHistory/OrderHistory';
import Profile from './components/Profile/Profile';
import Signout from './components/Signout/Signout';
import SubmitOrderSuccess from './components/Checkout/SubmitOrderSuccess';
import PaymentFailed from './components/Checkout/PaymentFailed';
import Verification from './components/Auth/Verification';
// import RewardsComponent from './components/Rewards/RewardsComponent';

const routes = [
  { path: '/', exact: true, name: 'Home', component: Dashboard },
  { path: '/products', exact: true, name: 'Product List', component: ProductList },
  { path: '/products/:cat', exact: true, name: 'Product List', component: ProductList },
  { path: '/products/:cat/:family', exact: true, name: 'Product Details', component: ProductDetails },
  { path: '/checkout', exact: true, name: 'Checkout', component: Checkout },
  { path: '/checkout/success', exact: true, name: 'Checkout', component: SubmitOrderSuccess },
  { path: '/verify', exact: true, name: 'Checkout', component: Verification },
  { path: '/checkout/failed', exact: true, name: 'Payment Failed', component: PaymentFailed },
  { path: '/orderoverview', exact: true, name: 'Order Overview', component: OrderOverview },
  { path: '/signin', name: 'Signin', component: Signin, restricted: false, withuser: false},
  { path: '/signup', name: 'Signup', component: Signup, restricted: true, withuser: false},
  { path: '/confirmsignup', name: 'Confirm Signup', component: SignupConfirm, restricted: true, withuser: false},
  { path: '/forgotpassword', name: 'Forgot Password', component: ForgotPasswordForm, restricted: false, withuser: false},
  { path: '/resetpassword', name: 'Reset Password', component: ResetPassword, restricted: true, withuser: false},
  { path: '/orders', name: 'Order History', component: OrderHistory, restricted: true, withuser: true},
  { path: '/profile', name: 'Profile', component: Profile, restricted: true, withuser: true},
  { path: '/signout', name: 'Signout', component: Signout, restricted: false, withuser: false},
  // { path: '/rewards', name: 'Rewards', component: RewardsComponent, restricted: true, withuser: true }
]

export default routes;
