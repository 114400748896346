import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import card_styles from '../../style/card';
import button_styles from '../../style/button';
import link_styles from '../../style/links';
import Price, { addVatToPrice } from '../Utils/Price';
import { HOST } from '../../config';
import { gtmEvents } from '../../libs/tagmanager';

import EditOrderDialog from '../Base/EditOrderDialog';
import intersection from 'lodash/intersection';
import RenderText from '../Utils/TextUtils';


const UpSellCard = (props: any) => {
  const { t } = useTranslation();
  const classes = card_styles();
  const button_classes = button_styles();
  const link_classes = link_styles();
  const { orders } = props;
  const isFunnel =  ( props.funnel) ?  {funnel : 1}  : '';

  const gtmProductClick = () => {
    gtmEvents([{
      'name': props.family.item.name,
      'id': props.family.item.variants[0].productId,
      'price': props.family.item.variants[0].price,
      'brand': 'Fruitbox',
      'category': props.family.slug,
      'variant': props.family.item.variant_type
    }],'product_click','product');
  }

  const isAddOn = Math.max(...props.family.item.variants.map((v:any) => v.price)) <= 10;
  const cartIsNotEmpty = !!props.cart && !!props.cart.items && !!props.cart.items.length;
  const crossSellIds:string[] = props.family.item.variants.map((variant:any) => variant.productId);
  const orderList:any[] = orders?.filter((order:any) => {
    const detailsCommon = order.order_details.map((detail:any) => {
      return intersection(detail.otherproducts, crossSellIds).length === 0;
    });
    return !detailsCommon.includes(false);
  }) ?? [];

  const isPopUp:Boolean = isAddOn && !cartIsNotEmpty && !!orderList?.length;

  return (
    <Grid item sm={12} md={4} lg={3} onClick={gtmProductClick}>
      <Card className={classes.card}>
        { !isPopUp &&
          <Link to={{pathname: `/products/${props.family.slug}/${props.family.item.slug}`,
            state: isFunnel}}>
            <div className={classes.swapMedia}>
              {( props.family.item.images.length > 1 &&
                <CardMedia
                  className={`${classes.swapMediaOverlay}`}
                  image={`${HOST}${props.family.item.images[1]}`}
                  title={props.family.item.name} />
              )}
              {( props.family.item.images.length > 0 &&
                <CardMedia
                  className={`${classes.media} swapimg`}
                  image={`${HOST}${props.family.item.images[0]}`}
                  title={props.family.item.name} />
              )}
            </div>
          </Link>
        }
        { isPopUp &&
          <EditOrderDialog
            orders={orderList}
            refetch={props.refetch}
            crossSellItem={props.family}
            isPrivate={props?.isPrivate ?? false}>
            <div className={classes.swapMedia}>
              {( props.family.item.images.length > 1 &&
                <CardMedia
                  className={`${classes.swapMediaOverlay}`}
                  image={`${HOST}${props.family.item.images[1]}`}
                  title={props.family.item.name} />
              )}
              {( props.family.item.images.length > 0 &&
                <CardMedia
                  className={`${classes.media} swapimg`}
                  image={`${HOST}${props.family.item.images[0]}`}
                  title={props.family.item.name} />
              )}
            </div>
          </EditOrderDialog>
        }
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              { !isPopUp &&
                <Link to={{
                  pathname: `/products/${props.family.slug}/${props.family.item.slug}`,
                  state: isFunnel }}
                  className={link_classes.normal_text}>
                  <Typography variant="h4">{props.family.item.name}</Typography>
                </Link>
              }
              { isPopUp &&
                <EditOrderDialog
                  orders={orderList}
                  refetch={props.refetch}
                  crossSellItem={props.family}
                  isPrivate={props?.isPrivate ?? false}
                >
                  <Typography variant="h4">{props.family.item.name}</Typography>
                </EditOrderDialog>
              }
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                {props.family.item.variants.length>1?t('products.pricestartingfrom'):''}{' '}<Price amount={props.family.item.variants[0].price} />
              

              </Typography>
            </Grid>
            {(!props.isSignedIn || props.isPrivate) &&

              <Grid item xs={12}>
                <Typography variant="body1">
                    <small>
                      {`ALV (14%) `}<Price amount={ addVatToPrice(props.family.item.variants[0].price)} />
                    </small>
                  </Typography>
              </Grid>
            } 
            <Grid item xs={12} className={classes.productDescription}>
              {RenderText(props?.family?.item?.description ?? '')}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          { !isPopUp &&
            <Link to={{ pathname: `/products/${props.family.slug}/${props.family.item.slug}`,
              state: isFunnel }}
              className={`${button_classes.block} ${link_classes.normal_text}`}>
              <Button
                className={`
                  ${button_classes.noRadius}
                  ${button_classes.default}
                  ${button_classes.block}
                `}
                variant="contained"
                color="secondary"
              >
                {t('buttons.addtoorder')}
              </Button>
            </Link>
          }
          { isPopUp &&
            <EditOrderDialog orders={orderList}
              refetch={props.refetch}
              crossSellItem={props.family}
              className={button_classes.block}
              isPrivate={props?.isPrivate ?? false}
            >
              <Button
                className={`${button_classes.noRadius} ${button_classes.default}  ${button_classes.block}`}
                variant="contained"
                color="secondary"
                fullWidth>
                {t('buttons.addtoorder')}
              </Button>
            </EditOrderDialog>
          }
        </CardActions>
      </Card>
    </Grid>
  );
}

export default UpSellCard;
