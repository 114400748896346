import gql from 'graphql-tag';

export const PRODUCTS_QUERY = gql`
  query Products {

    images {
      id
      type
      url
      productfamily
    }

    categories {
      name
      slug
      families {
        name
        slug
        description
        longerDescription
        leadParagraph
        iscontinuouspossible
        variant_type
        pricelevelid
        transactioncurrencyid
        images
        variants {
          name
          price
          productId
          weight
          productnumber
          rewardpointsneeded
          uomid
        }
      }
    }
  }`;

export interface PRODUCTS_QUERY_DEF {
  products: Array<{
    productId: String,
    productImages: Array<String>,
    name: String,
    categoryId: String,
    categoryName: String,
  }>
}
