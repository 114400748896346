import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import grid_style from "../../style/grids";
import paper_style from "../../style/paper";
import imageStyle from "../../style/image";
import CardFields from "../Checkout/CardFields";
import Paper from "@material-ui/core/Paper";
import cardImages from "../Utils/CardTypes";
import { useCreateOrder } from "../Contexts/Order";

const PaymentMethods = forwardRef((props: any, ref) => {
  const { t } = useTranslation();
  const [grid_classes, paper_classes] = [grid_style(), paper_style()];
  const childRef = useRef<any>();
  const imageClasses = imageStyle();
  const { editCard, setEditCard } = useCreateOrder();

  useImperativeHandle(ref, () => ({
    hasErrors() {
      return childRef.current.hasErrors();
    },
    async createToken(submitOrderAction: () => void) {
      return childRef.current.createToken(submitOrderAction);
    },
  }));

  const cardImage = (brand: string) => {
    return cardImages.find((card) => card.brand === brand);
  };

  const paymentCards = props.user.stripepaymentmethods.map((card: any) => (
    <Grid key={card} container className={grid_classes.headerGridMargin}>
      {!editCard && (
        <Grid container>
          <Grid item xs={12} className={grid_classes.headerGridMargin}>
            <Paper
              className={`${paper_classes.paper} ${paper_classes.login_container}`}
            >
              <Typography variant="h4">{t("payments.paymentcards")}</Typography>

              <Typography variant="body1">
                {card.brand && cardImage(card.brand) && (
                  <img
                    className={imageClasses.allignMiddle}
                    src={cardImage(card.brand)?.logo ?? ""}
                    alt="card-logo"
                  />
                )}
                {`**** **** **** ${card.lastfour}`}
                <IconButton
                  disabled={card.isBusy ? true : false}
                  aria-label={t("general.edit")}
                  onClick={() => setEditCard(true)}
                >
                  <EditIcon />
                </IconButton>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Grid>
  ));

  return (
    <Grid container className={grid_classes.headerGridMargin}>
      {paymentCards}
      {(editCard || !props.user.stripepaymentmethods.length) &&
        <CardFields 
          ref={childRef}
          setIsBusy={props.setIsBusy}
          noCards={!props.user.stripepaymentmethods.length}
          headerLabel={t("payments.editcard")}
        />
      }
    </Grid>
  );
});

export default PaymentMethods;
