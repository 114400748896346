import { makeStyles } from '@material-ui/core/styles';

const button_style = makeStyles(theme => ({
  noRadius: {
    borderRadius: '0',
  },
  default: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: 'none',
    fontSize: '1.5rem',
    fontWeight: 700,
    boxShadow: 'none',
    textDecoration: 'none',
    minHeight: '54px',
    lineHeight: '1.1',
    '&:focus': {
      boxShadow: 'none',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  dialog: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: 'none',
    boxShadow: 'none',
    background: 'none',
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
      background: 'none',
    },
  },
  block: {
    width: '100%',
  },
  block_on_xs: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  link: {
    '&:hover': {
        background: 'none',
        textDecoration: 'underline'
      },
  },
  linkDefault: {
    color: '#fff',
    '&:hover': {
        background: 'none',
        textDecoration: 'underline'
      },
  },
  withBgDarkPrimary: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark
      },
  },
  withBgPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
        backgroundColor: theme.palette.primary.main
      },
  },
  withBgSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    '&:hover': {
        backgroundColor: theme.palette.secondary.main
      },
  },
  withBgLightSecondary: {
    backgroundColor: theme.palette.secondary.light,
    color: '#fff',
    '&:hover': {
        backgroundColor: theme.palette.secondary.light
      },
  },
  menuLinks: {
      color: '#fff'
  },
  buttonProgress: {
    color: theme.palette.primary.light,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProductFilter: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: 'none',
    fontSize: '1.5rem',
    fontWeight: 700,
    boxShadow: 'none',
    textDecoration: 'none',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '99px',
    '&:focus': {
      boxShadow: 'none',
    },
    '&.active': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.3rem !important'
    },
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: '1rem'
    },
  },
  buttonQtyContainer: {
    display: 'flex',
    justifyContent: 'center',
    '&.right': {
      justifyContent: 'flex-end'
    },
    '&.left': {
      justifyContent: 'flex-start'
    },
    '&.middle': {
      alignItems: 'center'
    }
  },
  buttonQtyMobile: {
    marginTop: '10px',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    '&:focus': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main
    },
    '&:disabled': {
      color: '#fff',
      backgroundColor: '#f9d6ca !important'
    },
    '&.nomargin': {
      margin: '0'
    }
  },
  noMargin: {
    margin: '0'
  },
  upsellChooseButton: {
    borderRadius: '0',
    border: '2px solid #0000008A',
    padding: '0',

    '& .image': {
      width: '100px',
      height: '80px'
    },
    '& .text': {
      textAlign: 'left',
      textTransform: 'capitalize',
      fontWeight: 'bold'
    },
    '&:hover': {
      backgroundColor: 'white'
    },
    '&:not(:first-child)': {
      marginLeft: '10px'
    },
    '&.selected': {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main
    }
  },
  buttonGridContainer: {
    padding: '5px'
  },
  pointer: {
    cursor: 'pointer',
  }
}));

export default button_style;
