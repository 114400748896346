import React from 'react';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { appmenu_style } from '../../style/appmenu';


export default function CartIcon(props:any) {
  const { items } = props;
  const appmenu_classes = appmenu_style();

  var qty = 0;
  if (items && items.length) {
    items.forEach((item:any) => {
      item.details.forEach((detail:any) => {
        qty += detail && detail.quantity ? detail.quantity : 0;
      });
    });
  }

  return (
    <>
      { qty > 0 &&
      <div style={{position: 'relative'}}>
        <div className={appmenu_classes.cartIconQuantity}>
          <strong style={{marginLeft: '-1px', marginTop: '-2px'}}>
            {qty}
          </strong>
        </div>
        <ShoppingCartIcon style={{ color: '#000' }}className={appmenu_classes.cartIcon} />
      </div>
      }
    </>
  )
}
