import { makeStyles } from '@material-ui/core/styles';

const pagination_style = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
    [theme.breakpoints.between('xs', 'sm')]: {
      position: 'absolute',
      top:'35px',
    }
  },
  container:{
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingBottom: '30px'
    }
  }
}));

export default pagination_style;
