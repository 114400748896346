import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import grid_style from '../../style/grids';
import ProductCard from '../Base/ProductCard';
import ProductCardSmall from '../Base/ProductCardSmall';
import { getCategoryTitle, getCategoryItems } from '../../libs/product';
import { gtmEvents } from '../../libs/tagmanager';
import PageNotFound from '../../layouts/DefaultLayout/PageNotFound';


const Products = (props: any) => {
  const { t } = useTranslation();
  const { cat } = props.match.params;
  const grid_classes = grid_style();

  const catExists = !cat ||
    !!props.products.categories
      .find((el: any) => el.slug === cat)
    ;

  useEffect(() => {
    if (!catExists) return;
    const products: any = [];
    getCategoryItems(props.match.params.cat, props.products, false).forEach((family: any, idx: number) => {
      const variant = family.item.variants[0];
      products.push({
        name: family.item.name,
        id: variant.productId,
        price: variant.price,
        brand: 'Fruitbox',
        category: family.slug,
        variant: family.item.variant_type,
        list: props.match.params.cat || 'all products',
        position: idx
      })
    });
    gtmEvents(products, 'product_impression', '');
  }, [props.match.params.cat, props.products, catExists]);

  if (!catExists) {
    return <PageNotFound />;
  }

  return (
    <React.Fragment>
      <Grid container className={`${grid_classes.root} ${grid_classes.top_content_container}`}>
        <Grid item xs={12}>
          <Typography variant="h2" className={`${grid_classes.top_bottom_margin_typo}`}>
            {getCategoryTitle(props.match.params.cat, props.products) || t('products.allproducts')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={`${grid_classes.root} ${grid_classes.top_content_container}`} spacing={2}>
        {
          getCategoryItems(props.match.params.cat, props.products, false).map((family: any, idx: number) => {
          return (
            <React.Fragment key={idx}>
              <Hidden smDown>
                <ProductCard key={idx} family={family} funnel={props.location.state} {...props} />
              </Hidden>
              <Hidden mdUp>
                <ProductCardSmall key={idx} family={family} funnel={props.location.state} {...props} />
              </Hidden>
            </React.Fragment>
          );
        })}
      </Grid>
    </React.Fragment>
  );
}

export default Products;
