import React, { Component } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { HOST } from '../../config';
import sortBy from 'lodash/sortBy';

import { PRODUCT_IMAGES_ORDER } from '../../config';

interface AsNavForProps {
  images: any;
}

interface AsNavForState {
  nav1: any;
  nav2: any;
}

const showNumCol = 4;

const Div = styled.div`
  & img {
    width: 100%
  }

  & img.bottom_nav:hover {
    cursor: pointer
  }

  & img.bottom_nav {
    border: 1px solid #ccc
  }

  outline: none;
  padding: 12px
`;


export default class AsNavFor extends Component<AsNavForProps, AsNavForState> {
  slider1: any = '';
  slider2: any = '';
  constructor(props: any) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    const extraCols: any = [];
    if (this.props.images.length < showNumCol) {
      for (var i = 0; i < showNumCol - this.props.images.length; i++) {
        extraCols.push(<Div key={i}></Div>);
      }
    }

    var mainImages, extraImages;
    mainImages = this.props.images.slice(0, 2);
    if (this.props.images.length > 2) {
      extraImages = this.props.images.slice(2, this.props.images.length);
    }
    if (PRODUCT_IMAGES_ORDER.length && extraImages) {
      extraImages = sortBy(extraImages, (image) => {
        const idx = PRODUCT_IMAGES_ORDER.indexOf(image);
        if (idx < 0) return this.props.images.length;
        return idx;
      });
    }
    if (extraImages) {
      mainImages = [...mainImages, ...extraImages];
    }

    return (
      <div>
        <Slider
          asNavFor={this.state.nav2}
          ref={(slider: any) => (this.slider1 = slider)}
        >
          {mainImages.map((img: any, idx: any) => {
            return (
              <Div key={idx}>
                <img src={`${HOST}${img}`} alt="" />
              </Div>
            )
          })}
        </Slider>
        <Slider
          asNavFor={this.state.nav1}
          ref={(slider: any) => (this.slider2 = slider)}
          slidesToShow={showNumCol}
          focusOnSelect={true}
        >
          {mainImages.map((img: any, idx: any) => {
            return (
              <Div key={idx}>
                <img src={`${HOST}${img}`} alt="" className="bottom_nav" />
              </Div>
            )
          })}
          {extraCols}
        </Slider>
      </div>
    );
  }
}
