import React from 'react';
import Add from '@material-ui/icons/Add';
import Minus from '@material-ui/icons/Remove';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import button_styles from '../../../style/button';
import input_styles from '../../../style/input';
import grid_style from '../../../style/grids';

function QtyInput(props: any) {

  const button_classes = button_styles();
  const input_classes = input_styles();
  const grid_classes = grid_style();

  const [disabled, setDisabled] = React.useState(true);

  const handleDayQtyChange = (name: any, val: any, op: any) => () => {
    setDisabled(false);
    if (op === '+') {
      props.setDayQtyValues({ ...props.dayQty, [name]: val + 1});
    } else if (val > 1) {
      props.setDayQtyValues({ ...props.dayQty, [name]: val - 1});
      (props.dayQty['OnceOnly'] - 1 === 1) ? setDisabled(true) : setDisabled(false);
    }
  };

  return (
    <>
     <Input
      type={'number'}
      value={props.dayQty['OnceOnly']}
      className={`${grid_classes.qtyInputDesktopView}`}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton aria-label="" onClick={handleDayQtyChange('OnceOnly', props.dayQty['OnceOnly'], '-')} >
            <Minus />
          </IconButton>
          <IconButton aria-label="" onClick={handleDayQtyChange('OnceOnly', props.dayQty['OnceOnly'], '+')}>
            <Add />
          </IconButton>
        </InputAdornment>)}
      />
      <Grid container className={`${grid_classes.qtyInputMobileView} ${grid_classes.top_content_container} ${grid_classes.align_center}`}>
        <Grid item xs>
          <IconButton disabled={disabled} aria-label="" onClick={handleDayQtyChange('OnceOnly', props.dayQty['OnceOnly'], '-')} className={`${button_classes.buttonQtyMobile}`}>
          <Minus />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Input
            disableUnderline={true}
            type={'number'}
            value={props.dayQty['OnceOnly']}
            className={`${input_classes.inputQtyMobile}`}
          />
        </Grid>
        <Grid item xs>
          <IconButton aria-label="" onClick={handleDayQtyChange('OnceOnly', props.dayQty['OnceOnly'], '+')} className={`${button_classes.buttonQtyMobile}`}>
            <Add />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

export default QtyInput;
