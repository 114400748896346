import { makeStyles } from '@material-ui/core/styles';

const card_styles = makeStyles(theme => ({
  card: {
    height: '100%',
    borderRadius: '0px',
    boxShadow: 'none',
    position: 'relative',
    paddingBottom: '64px',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  cardUpSell: {
    height: '100%',
    borderRadius: '0px',
    boxShadow: 'none',
    position: 'relative',
    paddingBottom: '64px',
  },
  cardUpSellSmall: {
    height: '100%',
    borderRadius: '0px',
    boxShadow: 'none',
    paddingBottom: theme.spacing(2)
  },
  card_no_fade: {
    height: '100%',
    borderRadius: '0px',
    boxShadow: 'none',
    position: 'relative',
    paddingBottom: '0',
    display: 'block',
  },
  card_condensed_media: {
    height: '100%',
    paddingTop: '100%',
  },
  card_condensed: {
    borderRadius: '0px',
    boxShadow: 'none',
    position: 'relative',
    display: 'block'
  },
  price: {
    margin: '0px',
    padding: '0px',
  },
  media: {
    height: 0,
    paddingTop: '100%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
  },
  actions: {
    margin: '0px',
    padding: '0px',
    bottom: '0px',
    position: 'absolute',
    width: '100%',
    '& button': {
      margin: '0px',
      width: '100%'
    }
  },
  swapMedia: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    '& :hover':{
      opacity: 1,
    }
  },
  swapMediaOverlay: {
    position: 'absolute',
    opacity: 0,
    transition: '.5s ease',
    height: '100%',
    width: '100%',
    paddingTop: '100%',
  },
  card_condensed_container: {
    background: '#fff',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '& button': {
      margin: 0,
    }
  },
  card_condensed_content: {
    margin: 0,
    padding: theme.spacing(2)
  },
  upSellTag: {
    width: '60%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    textAlign: 'center',
    fontWeight: 700,
    color: '#fff',
    marginTop: theme.spacing(4),
    position: 'absolute',
    zIndex: 999,
    display:'none'
  },
  upSellTagBottom: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    textAlign: 'center',
    fontWeight: 700,
    color: '#fff',
  },
  headerText: {
    paddingBottom: theme.spacing(1)
  },
  jumbotron: {
    height: '100%',
    borderRadius: '0px',
    boxShadow: 'none',
    padding: theme.spacing(6),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  jumbotronContainter: {
    marginBottom: theme.spacing(2),
  },
  weekday: {
    textTransform: "lowercase"
  },
  continuousDeliveryAddress : {
    marginLeft: '50px',
  },
  productDescription: {
    '& p' : {
      margin: '0'
    }
  }
}));

export default card_styles;
