import gql from 'graphql-tag';

export const INVOICES_QUERY = gql`
  query Invoices($accountid: String) {
    invoices(accountid: $accountid) {
      invoicenumber
      createdon
      paymentbankstatus
      transactioncurrencyid
      details {
        productdescription
        priceperunit
        extendedamount
        quantity
      }
    }
  }
`;
