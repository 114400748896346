import gql from 'graphql-tag';

export const DASHBOARD_USER_QUERY = gql`
  query QueryDashboardUser {

    accountPicklists: picklists(entity: "account", fields: ["fs_einvoiceoperator", "fs_paymentmethod", "fs_customertype"]) {
      logicalName
      displayName
      options {
        label
        value
      }
   }

    orderPicklists: picklists(entity: "salesorder", fields: ["fs_deliveryweekday", "fs_orderfrequency"]) {
      logicalName
      displayName
      options {
        label
        value
      }
    }

    imagePicklists: picklists(entity:"fs_productimage", fields: ["fs_imagetype"]) {
      logicalName
      displayName
      options {
        label
        value
      }
    }

    images: images  {
      id
      type
      url
      productfamily
    }

    products: categories {
      name
      slug
      families {
        name
        slug
        description
        iscontinuouspossible
        variant_type
        pricelevelid
        transactioncurrencyid
        images
        variants {
          name
          price
          productId
          weight
          productnumber
          rewardpointsneeded
          uomid
        }
      }
    }

    account: account {
      accountid
      accounttype
      contactid
      firstname
      lastname
      email
      phone
      accountname
      businessid
      accountemail
      addressline1
      addresscity1
      addresszip1
      addressline2
      addresscity2
      addresszip2
      accountphone
      paymentmethod
      startingpoints
      earnedrewardpoints
      countrycodeinvoice
      countrycodedelivery
      einvoiceoperator
      einvoiceidentifier
    }

    orders: orders {
      ordernumber
      orderreference
      productid
      productname
      productslug
      salesorderid
      salesorderdetailid
      uomid
      manualdiscountamount
      priceperunit
      quantity
      deliveryweekday
      orderfrequency
      nextpickupdate
      shiptoline1
      shiptocity
      shiptozip
      orderstartdate
      orderispaused
      subscriptionid
      tempchanges {
        id
        orderid
        from
        to
        ispaused
        productid
        weekdayid
        quantity
      }
    }

    stripesubscriptions: stripesubscriptions {
      id
      customer
      latest_invoice {
        id
        number
        hosted_invoice_url
        invoice_pdf
        status
      }
    }
  }
`;
