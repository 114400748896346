import { makeStyles } from "@material-ui/core/styles";

const imageStyle = makeStyles((theme) => ({
  allignMiddle: {
    marginRight: "15px",
    verticalAlign: "middle",
  },
}));

export default imageStyle;
