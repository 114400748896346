import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { HOST } from '../../config';
import card_styles from '../../style/card';
import history_styles from '../../style/history';
import Price from '../Utils/Price';
import moment from 'moment';

function HistoryDetails(props: any) {

  const { t } = useTranslation();
  const classes = card_styles();
  const history_classes = history_styles();

  const { order, product} = props;
  const pickup:any = moment(Number(order.pickupdate));
  const dateFormat = (i18n.language === 'fi' ) ? `${pickup.format('D.M.YYYY')}` : `${pickup.format('MMMM DD YYYY')}`;

  const checkOrderType = () => {
    return ( product ?  (product.iscontinuouspossible) ? t('orders.iscontinuous') : t('orders.isonetime') : '' ) ;
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
          <Typography variant="h4" className={history_classes.header}>{dateFormat}</Typography>
      </Grid>
      <Grid container className={history_classes.itemContainer}>
        <Grid item md={2} sm={12} xs={4}>
          <Card className={classes.card_no_fade}>
            <div className={classes.swapMedia}>
            { product &&
            <CardMedia
                className={classes.media}
                image={`${HOST}${product.images[0]}`}
                title={product.name}
            />
            }
            </div>
          </Card>
        </Grid>
        <Grid item md={10} sm={12} xs={8}>
          <Typography variant="h4" style={{ marginBottom: 10 }}>{order.productname}</Typography>
          <Typography variant="body1" className={history_classes.orderTypeLabel}>{checkOrderType()}</Typography>
          <Typography variant="body2">
            <strong>{t('orders.orderid')}:</strong> {order.ordernumber}<br />
            <strong>{t('orders.qty')}:</strong> {order.quantity}<br />
            <strong>{t('general.total')}:</strong> <Price amount={order.quantity * order.price} /><br />
            <strong>{t('orders.discount')}:</strong> <Price amount={order.discount} /> <br />
            <strong>{t('orders.delivertohistory')}:</strong><br />
            {`${order.deliverystreet}`}<br/>{`${order.deliveryzip} ${order.deliverycity ? order.deliverycity : ''}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HistoryDetails;

