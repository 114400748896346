import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import grid_style from '../../style/grids';
import link_style from '../../style/links';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { gtmEvents } from '../../libs/tagmanager';

import ProductCard from '../Base/ProductCard';
import ProductCardSmall from '../Base/ProductCardSmall';
import { Typography } from '@material-ui/core';
import { getCrossSell } from '../../libs/product';

const Products = (props: any) => {
  const { t } = useTranslation(),
    grid_classes = grid_style(),
    link_classes = link_style(),
    crossSellList = getCrossSell(props.orders, props.cart, props.products, 4, true);

  useEffect(() => {
    const crossSellProducts: any = [];
    crossSellList.forEach((item: any, idx: number) => {
      crossSellProducts.push({
        name: item.family.name,
        id: item.family.variants[0].productId,
        price: item.family.variants[0].price,
        brand: 'Fruitbox',
        category: item.categorySlug,
        variant: item.family.variant_type,
        list: 'suggestions',
        position: idx
      });
    });
    gtmEvents(crossSellProducts, 'product_impression', '');
  }, [  crossSellList]);

  return (
    <React.Fragment>
      <Grid container className={clsx(grid_classes.container_padding, grid_classes.root)}>
        <Grid item sm={12} md={8}>
          <Typography variant="h3">
            {t('orders.overviewcrosssell')}
          </Typography>
        </Grid>
        <Grid item sm={12} md={4} className={grid_classes.overviewAllProductsLink}>
          <Typography variant="h5" color="primary">
            <Link
              to={{
                pathname: '/products',
                state: {
                  funnel: 1
                }
              }}
              className={link_classes.link_primary}>{t('orders.viewallproducts')}</Link>
          </Typography>
        </Grid>
      </Grid>
      {(
        <Grid container className={`${grid_classes.root} ${grid_classes.top_content_container}`} spacing={2}>
          {(
            crossSellList.map((item: any, idx: number) => {
              return (
                <React.Fragment key={idx}>
                  <Hidden smDown>
                    <ProductCard key={idx}
                      family={{ item: item.family, slug: item.categoryslug }}
                      funnel={1}
                      {...props}
                    />
                  </Hidden>
                  <Hidden mdUp>
                    <ProductCardSmall key={idx}
                      family={{ item: item.family, slug: item.categorySlug }}
                      funnel={1}
                      {...props}
                    />
                  </Hidden>
                </React.Fragment>
              );
            })
          )}
        </Grid>
      )}
    </React.Fragment>
  );
}

export default Products;
