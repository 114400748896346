import React from 'react';
import { Redirect } from 'react-router-dom';
import { parseQueryString } from '../../libs/utils';

/**
 * Apply next
 */
export function applyNext(props: any) {
  const query = parseQueryString(props.location.search);
  if (query.next) {
    window.localStorage.setItem('next', query.next);
    return <Redirect to={props.location.pathname} />;
  } else {
    return null;
  }
}

/**
 * Redirect Next
 */
export default function Next(props: any) {
  const next = window.localStorage.getItem('next');
  if (next) {
    window.localStorage.removeItem('next');
    return <Redirect to={next} />;
  } else {
    return <Redirect to="/" />
  }
}
