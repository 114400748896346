import React, { useState } from 'react';
import { Redirect } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import form_style from '../../style/form';
import button_style from '../../style/button';
import paper_style from '../../style/paper';
import grid_style from '../../style/grids';

export const forgotPasswordErrorMap: any = {
  'Username cannot be empty': 'usernameempty',
  'Username/client id combination not found.': 'usernamenotfound',
  'Code cannot be empty': 'codeempty',
  'Password cannot be empty': 'passwordempty',
  'Invalid verification code provided, please try again.': 'invalidcode',
  '1 validation error detected: Value at \'password\' failed to satisfy constraint: Member must have length greater than or equal to 6': 'invalidlength'
};

const Form: React.FC = (props: any) => {
  const { t } = useTranslation();
  const form_classes = form_style();
  const button_classes = button_style();
  const paper_classes = paper_style();
  const grid_classes = grid_style();
  const { isSignedIn } = props;

  const [values, setValues] = useState({
    username: '',
    password: '',
    passwordRetype: '',
    isBusy: false,
    isSuccess: false,
    error: ''
  });

  const handleChange = (event: any) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));
  };

  async function handleSubmit(event: any) {
    if (event) {
      event.preventDefault();
    }
    setValues(values => ({ ...values, isBusy: true, error: '' }));
    Auth.forgotPassword(values.username.toLowerCase())
      .then(data => {
        setValues(values => ({
          ...values,
          isBusy: false,
          error: '',
          isSuccess: true
        }));
      })
      .catch(err => {
        let errMsg: string;
        if (err.code) {
          errMsg = err.message;
        } else {
          errMsg = err;
        }
        setValues(values => ({
          ...values,
          isBusy: false,
          error: t('forgotpassword.' + (forgotPasswordErrorMap[errMsg] || 'unknown')),
          username: ''
        }));
      });
  };

  if (values.isSuccess) {
    const uri_username = encodeURIComponent(values.username);
    return <Redirect to={`/resetpassword?email=${uri_username}`} />
  }

  if (isSignedIn) {
    return <Redirect to={'/'} />
  }

  return (
    <div className={grid_classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} xl={4} lg={3} md={2}></Grid>
        <Grid item xs={12} sm={12} xl={4} lg={6} md={8}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Paper className={`${paper_classes.paper} ${paper_classes.login_container}`}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h3">{t('forgotpassword.title')}</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <form onSubmit={handleSubmit} className={form_classes.container} noValidate autoComplete="off">
                    <TextField
                      error={values.error ? true : false}
                      helperText={values.error ? values.error : ''}
                      id="username"
                      name="username"
                      label={t('forgotpassword.email')}
                      value={values.username}
                      onChange={handleChange}
                      fullWidth
                      disabled={values.isBusy ? true : false}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }} />
                    <Grid container>
                      <Grid item xs={12} sm={12} md={3}></Grid>
                      <Grid item xs={12} sm={12} md={6} className={grid_classes.relative}>
                        <Button
                          disabled={!!values.isBusy}
                          type="submit"
                          className={`${button_classes.noRadius} ${button_classes.default} ${button_classes.block}`}
                          variant="contained" color="primary">
                          {t('forgotpassword.' + (values.isBusy ? 'validating' : 'submit'))}
                        </Button>
                        {values.isBusy && <CircularProgress size={24} className={button_classes.buttonProgress} />}
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}></Grid>
                    </Grid>
                  </form>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} xl={4} lg={3} md={2}></Grid>
      </Grid>
    </div>
  );
};

export default Form;
