import React, { useState } from "react";

import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import grid_style from "../../style/grids";
import button_style from "../../style/button";
import paper_style from "../../style/paper";
import link_style from "../../style/links";
import {
  getOrderDeliveryAddress,
  getDeliveryAddressDetails,
} from "../../libs/user";
import { getFamily } from "../../libs/product";
import { HOST_AWS_SERVERLESS } from "../../config";
import { AppContext, AppState } from "../../App";
import { gaCheckoutSteps, gaPurchase } from "../../libs/tagmanager";
import axios from "axios";
import { handleError } from "../../libs/utils";
import ToastError from "../Base/ToastError";
import { useCreateOrder } from "../Contexts/Order";

const SubmitOrder = withRouter((props: any) => {
  const { t } = useTranslation();
  const [grid_classes, button_classes, link_classes, paper_classes] = [
    grid_style(),
    button_style(),
    link_style(),
    paper_style(),
  ];
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { agreed, setAgreed } = useCreateOrder();

  return (
    <AppState.Consumer>
      {({ refetch }: AppContext) => {
        const validateCart = async (user: any, paymentMethodId?: string) => {
          setIsProcessing(true);
          props.setIsBusy(true);
          const cart = Object.assign({}, props.cart);
          const additionalValues = props.additionalValues;

          if (props.couponCode)
            cart.couponCodes = props.couponCode
              .split(",")
              .map((item: string) => item.trim());

          for (var i = 0; i < cart.items.length; i++) {
            if (!cart.items[i].streetAddress && !props.signupValues) {
              const deliveryAddressId = getOrderDeliveryAddress(
                cart.items[i],
                user
              );
              const deliveryAddress: any = getDeliveryAddressDetails(
                deliveryAddressId,
                user
              );
              cart.items[i].streetAddress = deliveryAddress.streetAddress;
              cart.items[i].zipCode = deliveryAddress.zipCode;
              cart.items[i].city = deliveryAddress.city;
              cart.items[i].postOffice = deliveryAddress.postOffice;
            } 
            // For checkout w/o login.
            else if (props.signupValues) {
              // Check if cart item has delivery address. If a field in the delivery address
              // is missing or invalid, use the other address.
              const itemHasDeliveryAddress = cart.items[i].streetAddress // Has street.
                && cart.items[i].zipCode // Has zip code.
                && (cart.items[i].city || cart.items[i].postOffice); // Has city.
              const isDeliverySame = user?.is_delivery_same ?? false;
              // If a field is invalid, use the user default delivery address.
              if (!itemHasDeliveryAddress) {
                cart.items[i].streetAddress = !isDeliverySame ? user.d_streetaddress : user.streetaddress;
                cart.items[i].zipCode = !isDeliverySame ? user.d_zipcode : user.zipcode;
                cart.items[i].city = !isDeliverySame ? user.d_postaddress : user.postaddress ;
                cart.items[i].postOffice = !isDeliverySame ? user.d_postaddress : user.postaddress;
              }
            }

            const family: any = getFamily(cart.items[i].family, props.products);
            cart.items[i].pricelevelid = family.item.pricelevelid;
            cart.items[i].transactioncurrencyid =
              family.item.transactioncurrencyid;
            cart.items[i].variant_type = family.item.variant_type;

            additionalValues.orderInformation
              ? (cart.items[i]["orderInformation"] =
                  additionalValues.orderInformation)
              : delete cart.items[i].orderInformation;
            additionalValues.orderReference
              ? (cart.items[i]["orderReference"] =
                  additionalValues.orderReference)
              : delete cart.items[i].orderReference;
          }

          cart.username = user?.username?.toLowerCase() ?? "";
          cart.paymentMethodId = paymentMethodId;

          if (props.signupValues) createAccountToDynamics(cart.username, cart);
          else
            Auth.currentSession()
              .then((data) => createOrder(cart, data.getAccessToken()))
              .catch((err) => {
                props.setIsBusy(false);
                handleError(err);
                ToastError(t("err_msgs.notauthenticated"));
              });
        };

        const createOrder = (cart: any, token?: any) => {
          axios({
            method: "post",
            url: `${HOST_AWS_SERVERLESS}/orders/create${
              !token ? "/noverify" : ""
            }`,
            data: cart,
            headers: token
              ? { Authorization: `Bearer ${token.getJwtToken()}` }
              : {},
          })
            .then((res: any) => {
              const data = JSON.parse(res?.data ?? res);
              const { responses } = data ?? {};

              props.setIsBusy(false);
              refetch("orders", { funnel: 3 });
              // Google Analytics.
              gaCheckoutSteps(props.products, cart, 3);
              gaPurchase(props.products, cart, {
                id: responses ? responses.join() : data[0],
                coupon: props.couponCode,
                revenue: props.totalCost,
              });
              return props.history.push("/checkout/success");
            })
            .catch((err) => {
              if (err && cart.paymentMethodId)
                return props.history.push("/checkout/failed");
              props.setIsBusy(false);
              handleError(err);
              ToastError(t("err_msgs.orderprocess"));
            });
        };

        const handleSubmit = () => {
          if (!agreed)
            return setErrorMsg(
              agreed ? "" : t("signup.agreetermsandconditions")
            );
          if (props.user && props.user.accountType === 1) {
            return validateCart(props.user);
          }

          props.emitCheckoutAction((paymentMethodId?: string) => {
            validateCart(props.user, paymentMethodId ?? undefined);
          });
        };

        const createAccountToDynamics = (email: string, cart: any) => {
          axios({
            method: "post",
            url: `${HOST_AWS_SERVERLESS}/account/create/noverify`,
            data: { email: email.toLowerCase() },
          })
            .then((res: any) => createOrder(cart))
            .catch((err) => {
              props.setIsBusy(false);
              handleError(err);
              ToastError(t("err_msgs.orderprocess"));
            });
        };

        if (props.signupValues && props.signupValues.isSuccess) {
          if (!isProcessing) {
            validateCart(
              props.signupValues,
              props.signupValues.paymentMethodId ?? ""
            );
          }
        }
        const termsAndCondition = (
          <>
            <span> {t("signup.iagreetotermsandconditions")}</span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://fruitboxoffice.fi/toimitusehdot/"
              className={`${link_classes.link_primary}`}
            >
              {t("signup.termsandconditions")}
            </a>
          </>
        );
        return (
          <Grid container>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="agreed"
                      onChange={() => {
                        setAgreed(!agreed);
                      }}
                      checked={agreed}
                    />
                  }
                  label={termsAndCondition}
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <p className={paper_classes.error}>
                  {errorMsg && !agreed ? errorMsg : ""}
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              className={grid_classes.relative}
              style={{ marginTop: "24px" }}
            >
              <Button
                onClick={handleSubmit}
                disabled={props.isBusy ? true : false}
                type="submit"
                className={`${button_classes.noRadius} ${button_classes.default} ${button_classes.block}`}
                variant="contained"
                color="primary"
              >
                {props.isBusy
                  ? t("orders.processing")
                  : t("buttons.payAndShip")}
              </Button>
              {props.isBusy && (
                <CircularProgress
                  size={24}
                  className={button_classes.buttonProgress}
                />
              )}
              {t('orders.beforedelivery')}
            </Grid>
          </Grid>
        );
      }}
    </AppState.Consumer>
  );
});

export default SubmitOrder;
