import gql from 'graphql-tag';

export const ORDERS_HISTORY_QUERY = gql`
  query OrdersHistory($accountId: String) {
    ordershistory(accountId: $accountId) {
      ordernumber
      productname
      productnumber
      discount
      quantity
      price
      deliverystreet
      deliveryzip
      deliverycity
      frequency
      deliveryday
      startdate
      pickupdate
    }
  }
`;
