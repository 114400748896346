import React from 'react'

export interface PriceProps {
  amount: number
};

export const addVatToPrice = (price :number): number => {
  return (price * 0.14) + price;
}

/**
 * Price formatting
 */
const Price: React.FC<PriceProps> = ({ amount }) => {
  return (
    <>{ (amount || 0).toFixed(2).replace('.', ',') } &euro;</>
  );
}

export default Price;
