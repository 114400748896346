import sortBy from 'lodash/sortBy';
import React , { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import HistoryDetails from './HistoryDetails';
import Pagination from '../Pagination/Pagination';
import grid_style from '../../style/grids';
import Products from '../Base/QueryContainer/Products';

const OrderHistoryLayout: React.FC = (props: any) => {
  const { t } = useTranslation();
  const grid_classes = grid_style();
  const orders = sortBy(props.ordershistory, 'pickupdate').reverse();
  const [page, setPage] = useState(0),
        [rowsPerPage, setRowsPerPage] = useState(5);

  if (orders.length === 0) {
    return (
      <Grid container >
        <Grid item sm={12} md={12}>
          <Grid container className={`${grid_classes.root} ${grid_classes.top_content_container} ${grid_classes.product_content_container}`}>
            <Grid item sm={12}>
              <Typography variant="h5">
                {t('orders.noorderhistory')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Products>
      {({ getProducts }: any) => (
        <React.Fragment>
          <Grid container className={`${grid_classes.root} ${grid_classes.top_content_container}`}>
            <Grid item sm={12}>
              <Typography variant="h2">
                {t('orders.orderhistory')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container >
            <Grid item sm={12} md={12}>
              <Grid container className={`${grid_classes.root}`}>
                <Grid item sm={12}>
                {
                  orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order:any) => {
                    let product = getProducts().find((p:any) => p.productnumber === order.productnumber);
                    return (
                      <>
                        <HistoryDetails
                          key={order.ordernumber}
                          order={order}
                          product={product}
                        />
                      </>
                    )
                  })
                }
                </Grid>
                <Grid item sm={12}>
                <Pagination
                  page={page}
                  rowsPerPageOptions={[5, 10, 25]}
                  rowsPerPage={rowsPerPage}
                  data={orders}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Products>
  );
};

export default OrderHistoryLayout;
