import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import form_style from '../../style/form';
import button_style from '../../style/button';
import paper_style from '../../style/paper';
import grid_style from '../../style/grids';
import link_style from '../../style/links';
import avatar_style from '../../style/avatar';
import { parseAmplifyUserInfo } from '../../libs/user';
import { HOST_AWS_SERVERLESS } from '../../config';
import { AppContext, AppState } from '../../App';
import axios from 'axios';
import { handleError } from '../../libs/utils';
import { setCookie } from '../../libs/cookie';
import NewPassword from './NewPassword';

export interface ForceResetPasswordFields {
  amplifyUser: any,
  passwordNeedsReset: boolean,
}

const Form: React.FC = (props: any) => {
  const form_classes = form_style();
  const button_classes = button_style();
  const paper_classes = paper_style();
  const grid_classes = grid_style();
  const link_classes = link_style();
  const avatar_classes = avatar_style();
  const { t } = useTranslation();
  const [values, setValues] = useState({
    username: '',
    password: '',
    isAuthenticating: false,
    gotoConfirmation: false,
    isAuthenticated: false,
    error: ''
  });

  const [resetValues, setResetValues] = useState<ForceResetPasswordFields>({
    amplifyUser: null,
    passwordNeedsReset: false,
  });

  return (
    <AppState.Consumer>
      {({ refetch }: AppContext) => {

        const handleChange = (event: any) => {
          event.persist();
          setValues(values => ({
            ...values,
            [event.target.name]: event.target.value
          }));
        };

        function signinSuccessful() {
          refetch('orders');
        }

        function handleSubmit(event: any) {
          if (event) event.preventDefault();

          if (!values.username || !values.password) {
            const errMsg = t('err_msgs.requireduserpass');
            setValues(values => ({
              ...values,
              isAuthenticating: false,
              error: errMsg
            }))
          }

          if (values.username && values.password) {
            setValues(values => ({ ...values, isAuthenticating: true, error: '' }));
            Auth.signIn(values.username.toLowerCase(), values.password)
              .then(user => {

                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                  setResetValues({
                    amplifyUser: user,
                    passwordNeedsReset: true,
                  });
                  return;
                }

                const amplifyUserInfo = parseAmplifyUserInfo(user);
                if(amplifyUserInfo.firstLogin !== "1") {
                  setCookie('welcome', 1);
                  axios({
                    method: 'post',
                    url: `${HOST_AWS_SERVERLESS}/account/firstlogin`,
                    data:{email:amplifyUserInfo.email},
                    headers: {
                      'Authorization': `Bearer ${amplifyUserInfo.token}`
                    }
                  })
                  .then(() => {});
                } else {
                  setCookie('welcome', 0);
                }
                if (!amplifyUserInfo.dynamics_contact_id) {
                  axios({
                    method: 'get',
                    url: `${HOST_AWS_SERVERLESS}/users/createaccount`,
                    headers: {
                      'Authorization': `Bearer ${amplifyUserInfo.token}`
                    }
                  })
                  .then(() => {
                    setValues(values => ({
                      ...values,
                      isAuthenticating: false,
                      isAuthenticated: true
                    }));
                    props.setUserEmail(amplifyUserInfo.email);
                    props.setIsPrivate(amplifyUserInfo.account_type === '2');
                    props.onStateChange('signedIn');
                    signinSuccessful();
                  })
                  .catch((error: any) => {
                    handleError(error);
                    Auth.signOut();
                    setValues(values => ({
                      ...values,
                      isAuthenticating: false,
                      error: t('err_msgs.registrationfailed'),
                      password: ''
                    }));
                  });
                } else {
                  setValues(values => ({
                    ...values,
                    isAuthenticating: false,
                    error: '',
                    password: '',
                    isAuthenticated: true
                  }));
                  props.setUserEmail(amplifyUserInfo.email);
                  props.setIsPrivate(amplifyUserInfo.account_type === '2');
                  props.onStateChange('signedIn');
                  signinSuccessful();
                }
              })
              .catch(err => {
                Auth.signOut();
                let errMsg = 'Unknown error occured.';
                if (err.code === 'UserNotConfirmedException') {
                  setValues(values => ({
                    ...values,
                    isAuthenticating: false,
                    error: '',
                    password: '',
                    gotoConfirmation: true
                  }));
                } else if (err.code) {
                  errMsg = err.message;
                  if (err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException') {
                    errMsg = t('err_msgs.invalidlogin');
                  }
                } else {
                  errMsg = err;
                }
                setValues(values => ({
                  ...values,
                  isAuthenticating: false,
                  error: errMsg,
                  password: ''
                }));
              });
          }
        };

        if (resetValues.passwordNeedsReset) {
          return <NewPassword amplifyUser={resetValues.amplifyUser} />
        }

        if (values.gotoConfirmation) {
          return <Redirect to={`/confirmsignup?email=${encodeURIComponent(values.username)}`} />
        }

        if (values.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: props.asModalForm ? '/checkout' : '/',
                state: {
                  welcomepopup: true,
                  isAuthenticated: true,
                },
              }}
            />
          )
        }

        return (
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Paper className={`${paper_classes.paper} ${paper_classes.login_container}`}>
                {props.asModalForm ? <Typography variant="h3">{t('buttons.signin')}</Typography> : <Grid item xs={12} sm={12} className={grid_classes.loginHeader}>
                  <Avatar alt="" src="/assets/fruitbox_logo_alt.svg" className={avatar_classes.avatarAltLogo} />
                  <Typography variant="h3" align={"center"}>{t('signin_form.welcome')}</Typography>
                </Grid>}
                <Grid item xs={12} sm={12}>
                  <form onSubmit={handleSubmit} className={form_classes.container} noValidate autoComplete="off">
                    <TextField
                      error={values.error ? true : false}
                      helperText={values.error ? values.error : ''}
                      id="username"
                      name="username"
                      label={`${t('form_labels.username')}`}
                      value={values.username}
                      onChange={handleChange}
                      fullWidth
                      disabled={values.isAuthenticating ? true : false}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }} />
                    <TextField
                      id="password"
                      name="password"
                      label={`${t('form_labels.password')}`}
                      value={values.password}
                      onChange={handleChange}
                      fullWidth
                      type={'password'}
                      margin="normal"
                      disabled={values.isAuthenticating ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }} />
                    <Grid item sm={12} className={grid_classes.loginForgotPassword}>
                      <Typography variant="body1" align="right">
                        <Link to='forgotpassword' className={`${link_classes.link_primary} ${link_classes.nowrap}`}>
                          {t('signin_form.forgotpassword')}
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={3}></Grid>
                      <Grid item xs={12} sm={12} md={6} className={grid_classes.relative}>
                        <Button
                          disabled={!!values.isAuthenticating}
                          type="submit"
                          className={`${button_classes.noRadius} ${button_classes.default} ${button_classes.block}`}
                          variant="contained"
                          color="primary">
                          {values.isAuthenticating ? t('buttons.authenticating') : t('buttons.signin')}
                        </Button>
                        {(values.isAuthenticating &&
                          (<CircularProgress size={24} className={button_classes.buttonProgress} />)
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}></Grid>
                    </Grid>
                  </form>
                </Grid>
                {!props.asModalForm && <Grid item xs={12} sm={12} className={grid_classes.loginFooter}>
                  <Typography variant="body1">
                    {t('signin_form.noaccount')}
                    &nbsp;
                    <Link to='signup' className={`${link_classes.link_primary} ${link_classes.nowrap}`}>{t('signin_form.signup')}</Link>
                  </Typography>
                </Grid>}
              </Paper>
            </Grid>
          </Grid>
        );
      }}
    </AppState.Consumer>
  );
};

export default Form;
