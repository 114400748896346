import isFunction from 'lodash/isFunction';
import isUndefined from 'lodash/isUndefined';
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import form_style from '../../style/form';
import button_style from '../../style/button';
import paper_style from '../../style/paper';

import { editItemCart } from '../../libs/cart';
import {getAddressHash} from  '../Base/QueryContainer/Addresses';

interface Dictionary<T> {
  [key: string]: T;
}

export default function EditItemDialog(props: any) {
  const { t } = useTranslation();
  const form_classes = form_style();
  const paper_classes = paper_style();
  const button_classes = button_style();

  const address = props.address || {},
    index = address.index,
    hasIndex = !isUndefined(index) && index !== null;

  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [values, setValues] = useState<Dictionary<any>>({
    customername: '',
    streetaddress: '',
    zipcode: '',
    postaddress: '',
    isBusy: false,
    isSuccess: false,
    error: ''
  });

  const handleChange = (event: any) => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  function handleNo() {
    if (!values.isBusy) {
      props.setOpen(false);
    }
  }

  function onEnter() {
    setErrorFields([]);
    if (hasIndex) {
      setValues({
        customername: address.customerName || '',
        streetaddress: address.streetAddress || '',
        zipcode: address.zipCode || '',
        postaddress: address.city || '',
        isBusy: false,
        isSuccess: false,
        error: ''
      });
    } else {
      setValues({
        customername: '',
        streetaddress: '',
        zipcode: '',
        postaddress: '',
        isBusy: false,
        isSuccess: false,
        error: ''
      });
    }
  }

  async function handleSubmit(event: any) {
    if (event) {
      event.preventDefault();
    }

    let is_error_required_field = false;
    setErrorFields([]);
    setValues(values => ({...values, error: ''}));
    const error_fields: string[] = [];

    ['streetaddress', 'zipcode', 'postaddress'].forEach((item: any) => {
      if (!values[item].trim()) {
        error_fields.push(item);
        setValues(values => ({
          ...values,
          error: t('address.fillupfields')
        }));
        is_error_required_field = true;
      }
    });
    if (is_error_required_field) {
      setErrorFields(error_fields);
    } else if (isFunction(props.saveAddress)) {
      setValues({
        ...values,
        isBusy: true,
        error: ''
      });

      if(props.cartId) {
        const currentCartItems = props.cart?.items;
        const newCartItem = currentCartItems.find((item:any, _index:number) => {
          return item.id === props.cartId
        });
        if(newCartItem) {
          const addressObj  = {
            city:values.postaddress,
            streetAddress:values.streetaddress,
            zipCode: values.zipcode
          };
          const updatedAddress = {...newCartItem, ...addressObj, hash:getAddressHash(addressObj) }
          editItemCart(currentCartItems, updatedAddress, newCartItem.id, props.updateCartFn );
        } 
      }
      
      props.saveAddress({
        city: values.postaddress,
        index,
        source: address.source || 'local',
        streetAddress: values.streetaddress,
        cartId: props.cartId ?? 0,
        zipCode: values.zipcode,
        customerName: values.customername,
      }).then(() => {
        setValues({
          ...values,
          isBusy: false
        });
        props.setOpen(false);
      }).catch((err: any) => {
        setValues({
          ...values,
          isBusy: false,
          error: err.message || err
        });
      });
    }
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.open}
        scroll={`body`}
        onClose={handleNo}
        onEnter={onEnter}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{props.contentTitle}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} className={form_classes.container} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="customername"
                  name="customername"
                  error={errorFields.includes('customername')? true: false}
                  label={t('address.customername')}
                  value={values.customername}
                  onChange={handleChange}
                  fullWidth
                  disabled={values.isBusy ? true: false}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="streetaddress"
                  name="streetaddress"
                  error={errorFields.includes('streetaddress')? true: false}
                  label={t('address.street')}
                  value={values.streetaddress}
                  onChange={handleChange}
                  fullWidth
                  disabled={values.isBusy ? true: false}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="zipcode"
                  name="zipcode"
                  error={errorFields.includes('zipcode')? true: false}
                  label={t('address.zipcode')}
                  value={values.zipcode}
                  onChange={handleChange}
                  fullWidth
                  disabled={values.isBusy ? true: false}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="postaddress"
                  name="postaddress"
                  error={errorFields.includes('postaddress')? true: false}
                  label={t('address.postoffice')}
                  value={values.postaddress}
                  onChange={handleChange}
                  fullWidth
                  disabled={values.isBusy ? true: false}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item  sm={12} md={12} xs={12}>
                <p className={paper_classes.error}>
                  {values.error ? values.error: ''}
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item  sm={12} xs={12}>
                <Button
                  disabled={!!values.isBusy}
                  type="submit"
                  className={`${button_classes.noRadius} ${button_classes.default} ${button_classes.block}`}
                  variant="contained" color="primary">
                  {t('address.' + (values.isBusy ? 'validating' : (hasIndex ? 'savechanges': 'addaddress')))}
                </Button>
                {( values.isBusy &&
                  <CircularProgress size={24} className={button_classes.buttonProgress} />
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
