import React from 'react';
import OrderHistoryLayout from './OrderHistoryLayout';
import QFBox from '../Base/QueryContainer/QFBox';

export default function OrderHistory(props: any) {
  return (
    <QFBox
      resource={['products', 'user', 'ordershistory', 'picklists']} {...props}
      component={OrderHistoryLayout} />
  );
}
