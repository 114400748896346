import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import theme from './style/theme';
import App from './App';

import { REACT_APP_STRIPE_KEY } from './config';

// Initialize sentry.
Sentry.init({dsn: "https://da3209e6c387457398e2af97c5885d84@sentry.io/1546589"});

const stripePromise = loadStripe(REACT_APP_STRIPE_KEY || '');

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </ThemeProvider>,
  document.querySelector('#root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
