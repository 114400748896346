import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import OrderSummary from "./OrderSummary";
import InvoiceSummary from "./InvoiceSummary";
import SubmitOrderSuccess from "./SubmitOrderSuccess";
import grid_style from "../../style/grids";
import { gaCheckoutSteps } from "../../libs/tagmanager";
import Addresses from "../Base/QueryContainer/Addresses";
import PaymentMethods from "../Profile/PaymentMethods";
import { useCreateOrder } from "../Contexts/Order";

function CheckoutUser(props: any) {
  const grid_classes = grid_style();
  const childRef = useRef<any>();
  const [isBusy, setIsBusy] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const paymentMethod = props.user.stripepaymentmethods[0]?.id || "";
  const { editCard } = useCreateOrder();

  useEffect(() => {
    gaCheckoutSteps(props.products, props.cart, 2);
  }, [props.cart, props.products]);

  const checkoutSubmitAction = (action?: any) => {
    if ((editCard && props.user.accountType === 2) || !paymentMethod) {
      childRef.current.createToken(action);
    } else {
      action(paymentMethod);
    }
  };

  if (submitSuccess) {
    return <SubmitOrderSuccess {...props} />;
  }

  return (
    <Grid container spacing={2} className={`${grid_classes.root}`}>
      <Grid item xs={12} sm={12} md={8}>
        <Addresses {...props}>
          {({ addresses, editAddress, removeAddress, saveAddress }: any) => {
            return (
              <OrderSummary
                {...props}
                editAddress={editAddress}
                addresses={addresses}
                products={props.products}
                user={props.user}
              />
            );
          }}
        </Addresses>

        {props.user.accountType === 2 && (
          
          <PaymentMethods {...props} setIsBusy={setIsBusy} ref={childRef}></PaymentMethods>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <InvoiceSummary
          {...props}
          products={props.products}
          user={props.user}
          isBusy={isBusy}
          setIsBusy={setIsBusy}
          submitSuccess={submitSuccess}
          setSubmitSuccess={setSubmitSuccess}
          emitCheckoutAction={checkoutSubmitAction}
        />
      </Grid>
    </Grid>
  );
}

export default CheckoutUser;
