import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { HOST, ORDER_OPTION } from '../../config';
import card_styles from '../../style/card';
import grid_style from '../../style/grids';
import paper_style from '../../style/paper';
import button_style from '../../style/button';
import link_styles from '../../style/links';

function CartOverview(props: any) {
  const { t } = useTranslation();
  const classes = card_styles();
  const paper_classes = paper_style();
  const grid_classes = grid_style();
  const button_classes = button_style();
  const link_classes = link_styles();
  const orderDetails = props.displayedItem.details.map((item: any) => {
    if (item.day) {
      return `${t('general.' + item.day.toLowerCase())} (${t('orders.qty')}: ${item.quantity})`;
    } else if (item.date) {
      return `${moment(item.date).format('DD.MM.YYYY')} (${t('orders.qty')}: ${item.quantity})`;
    } else {
      return `(${t('orders.qty')}: ${item.quantity})`;
    }
  });
  const orderOption: any = props.displayedItem.orderOption ? ORDER_OPTION.deliveryOptions.find(({ value }) => {
    return value === props.displayedItem.orderOption;
  }) : '';

  const totalQty = () => {
    let sum = 0;
    props.cart.items.forEach((item:any) => {
      item.details.forEach((detail:any) => {
        if (detail.quantity) sum += Number(detail.quantity);
      })
    });
    return sum;
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs','sm'));

  return (
    <Grid container className={`${grid_classes.root} ${grid_classes.top_content_container} ${grid_classes.product_content_container}`}>
      <Grid item xs={12}>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12}>
            <Paper className={paper_classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} lg={3}>
                  <Card className={classes.card_no_fade}>
                    <div className={classes.swapMedia}>
                      {(props.family.item.images.length > 0 &&
                        <CardMedia
                          className={classes.media}
                          image={`${HOST}${props.family.item.images[0]}`}
                          title={props.family.item.images} />
                      )}
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  <Grid container spacing={2} >
                    <Grid item sm={12}>
                      <Typography variant="h3">{props.family.item.name}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} >
                    <Grid item sm={12}>
                      <Typography variant="h5">
                        {props.family.item.iscontinuouspossible ? `${t(orderOption.locale)} - ` : ''}
                        {orderDetails && orderDetails.reduce((prev: any, curr: any) => [prev, ', ', curr])}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} >
                    <Grid item sm={12}>
                      <Typography variant="body2">
                        {props.family.item.description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <br/>
                  <Link to='/checkout' className={`${link_classes.normal_text}`}>
                    <Button
                      className={`${button_classes.noRadius} ${button_classes.default} ${button_classes.block_on_xs}`}
                      variant="contained"
                      color="primary">
                      {t('orders.proceedwithyourorder')} {!isMobile ? `(${totalQty()} ${t('orders.' + ((totalQty() > 1) ? 'items' : 'item'))})` : ''}
                    </Button>
                  </Link>
                  {isMobile &&
                  <Grid container spacing={2} className={`${grid_classes.align_center}`}>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                      ({totalQty()} {t('orders.' + ((totalQty() > 1) ? 'items' : 'item'))})
                      </Typography>
                    </Grid>
                  </Grid>
                  }
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CartOverview;

