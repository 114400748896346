import isUndefined from 'lodash/isUndefined';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { Query } from 'react-apollo';

import { PRODUCTS_QUERY } from '../../../query/products';
import SimpleLoader from '../../Loader/SimpleLoader';
import { assignImages } from '../../../libs/product';

export interface Category {
  name: string;
  slug: string;
}

export interface Methods {
  getCategory: (slug: string) => Category;
  getCategories: () => Array<Category>;
  getFamily: (slug: string, category: string) => any;
  getProduct: (value: string, type?: string) => Product;
  getProducts: () => Array<Product>;
  getProductRewards: () => Array<Product>;
}

export interface Product {
  category: string;
  description: string;
  id: string;
  images: Array<string>;
  iscontinuouspossible: boolean;
  name: string;
  price: number;
  pricelevelid: string;
  slug: string;
  transactioncurrencyid: string;
  variant: string;
  variant_type: string;
  weight: number;
  productnumber: number;
  rewardpointsneeded: number;
  uomid: string;
}

/**
 * Get categories
 */
export function getCategories(data: Array<any>): Array<Category> {
  return data.map<any>(({ name, slug }): Category => {
    return {
      name,
      slug
    }
  })
}

/**
 * Get category by slug
 */
export function getCategoryBySlug(categories: Array<Category>, value: string): Category {
  return (categories as any).find(({ slug }: any) => (slug === value));
}

/**
 * Get product by key
 */
export function getProductBy(products: Array<Product>, value: string, type: string): Product {
  return (products as any).find((product: any) => (product[type] === value));
}

/**
 * Get products
 */
export function getProducts(data: Array<any>): Array<Product> {
  const products: Array<Product> = [];
  data.forEach(({ families, slug }) => {
    families.forEach((family: any) => {
      (family.variants || []).forEach((variant: any) => {
        products.push({
          category: slug,
          description: family.description,
          images: family.images || [],
          id: variant.productId,
          iscontinuouspossible: !!family.iscontinuouspossible,
          name: family.name,
          price: variant.price,
          pricelevelid: family.pricelevelid,
          slug: family.slug,
          transactioncurrencyid: family.transactioncurrencyid,
          variant: variant.name,
          variant_type: family.variant_type,
          weight: variant.weight,
          productnumber: variant.productnumber,
          rewardpointsneeded: variant.rewardpointsneeded,
          uomid: variant.uomid
        });
      });
    });
  });
  return products;
}

/**
 * Products query
 */
const ProductsQuery: React.FC = (props: any) => {
  return (
    <Query<any> query={PRODUCTS_QUERY}>
      {({ loading, error, data }) => {
        if (loading) {
          return <SimpleLoader />;
        }
        if (error) return null;

        assignImages(data, data?.images ?? []);
        const categories = data?.categories ?? [];

        var cache: any = {};
        const methods: Methods = {
          getCategory: (slug: string): Category => {
            return getCategoryBySlug(methods.getCategories(), slug)
          },
          getCategories: (): Array<Category> => {
            if (isUndefined(cache.categories)) {
              cache.categories = getCategories(categories);
            }
            return cache.categories;
          },
          getFamily: (slug: string, category: string): any => {
            return ((categories.find((cat: any) => (category === cat.slug)) || {}).families || [])
              .find((family: any) => (family.slug === slug));
          },
          getProduct: (key: string, type: string = 'slug') => {
            return getProductBy(getProducts(categories), key, type);
          },
          getProducts: () => {
            if (isUndefined(cache.products)) {
              cache.products = getProducts(categories);
            }
            return cache.products;
          },
          getProductRewards: () => {
            if (isUndefined(cache.products)) {
              cache.products = getProducts(categories);
            }
            let rewards = cache.products.filter((product:any) => {
              return !isUndefined(product.rewardpointsneeded) && product.rewardpointsneeded > 0;
            });
            rewards = sortBy(rewards, 'rewardpointsneeded');
            return rewards;
          }
        };
        return props.children(methods);
      }}
    </Query>
  );
}

export default ProductsQuery;
