import { makeStyles } from '@material-ui/core/styles';

const avatar_style = makeStyles(theme => ({
  avatarAltLogo: {
    padding: 5,
    width: 80,
    height: 80,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10px',
    filter: 'brightness(0.3) sepia(1) hue-rotate(-35deg) saturate(6)'
  },
  avatarNavInitial: {
    margin: 0,
    width: '1.875rem',
    height: '1.875rem',
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
  }
}));

export default avatar_style;
