import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { getItem } from '../../libs/product';
import grid_style from '../../style/grids';
import Price from '../Utils/Price';

function InvoiceItemDetails(props: any) {
  const { t } = useTranslation()
  const grid_classes = grid_style();
  const productDetail: any = getItem(props.orderDetails.productId, props.productFamily.item);

  let totalCost = 0;
  let totalQty = 0;

  props.orderDetails.details.forEach((item: any) => {
    if (item.day) {
      totalCost += productDetail.price * item.quantity;
      totalQty += item.quantity;
      return `${t('general.' + item.day.toLowerCase())} (${t('orders.qty')}: ${item.quantity})`;
    } else if (item.date) {
      totalCost += productDetail.price * item.quantity;
      totalQty += item.quantity;
      return `${moment(item.date).format('DD.MM.YYYY')} (${t('orders.qty')}: ${item.quantity})`;
    } else {
      totalCost += productDetail.price * item.quantity;
      totalQty += item.quantity;
      return `(${t('orders.qty')}: ${item.quantity})`;
    }
  });

  return (
    <Grid container spacing={2} style={{paddingTop: '12px'}} >
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid item xs={8} sm={8}>
            {props.productFamily.item.name} <br/><Price amount={productDetail.price} /> x {totalQty}
          </Grid>
          <Grid item xs={4} sm={4} className={grid_classes.align_right}>
            <b><Price amount={totalCost} /></b>
          </Grid>
        </Grid>
        <hr className={grid_classes.hr2}/>
      </Grid>
    </Grid>
  );
};

export default InvoiceItemDetails;

