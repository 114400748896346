import { getCookie, setCookie } from './cookie';

export function cartItemsSaveToCookie(items: any) {
  setCookie('cart', { items });
}

export function cartItemsLoadFromCookie() {
  return (getCookie('cart') || {}).items || [];
}

export function push2Cart(currentCart: any, newItem: any, handleUpdateCart: any) {
  newItem.id = Math.random().toString(36).substring(7);
  const upateItems = currentCart.concat([newItem])
  cartItemsSaveToCookie(upateItems);
  handleUpdateCart({ items: upateItems })
}

export function delItemCart(currentCart: any, cartId: any, handleUpdateCart: any) {
  const newList: any = [];
  currentCart.forEach((i: any) => {
    if (i.id !== cartId)
      if (!!!i.ischeap)
        newList.push(i);
  });

  cartItemsSaveToCookie(newList);
  handleUpdateCart({ items: newList })
}

export function editItemCart(currentCart: any, updatedItem: any, cartId: any, handleUpdateCart: any) {
  const newList: any = [];
  currentCart.forEach((i: any) => {
    if (i.id !== cartId) {
      newList.push(i);
    }
    else {
      updatedItem.id = cartId;
      newList.push(updatedItem)
    }
  });
  cartItemsSaveToCookie(newList);
  handleUpdateCart({ items: newList })
}

export function getItemCart(currentCart: any, cartId: any) {
  let item = {};
  currentCart.forEach((i: any) => {
    if (i.id === cartId)
      item = i;
  });
  return item;
}

export function setItemDeliveryAddress(currentCart: any, cartId: any, addressId: any, handleUpdateCart: any) {
  const newList: any = [];
  currentCart.forEach((i: any) => {
    if (i.id !== cartId)
      newList.push(i);
    else {
      i.deliveryAddressId = addressId;
      newList.push(i)
    }
  });
  cartItemsSaveToCookie(newList);
  handleUpdateCart({ items: newList })
}
