import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import error_style from '../../style/error';

const PageNotFound = () => {

  const { t } = useTranslation(),
    error_classes = error_style();

  return (
    <div className={error_classes.container}>
      <Typography variant="h4">{t('err_msgs.pagenotfound')}</Typography>
    </div>
  );
}

export default PageNotFound;
