import React, { useState,useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import button_style from '../../style/button';
import paper_style from '../../style/paper';
import grid_style from '../../style/grids';

import 'url-search-params-polyfill';

interface SignupConfirmFormProps {
  location?: any;
  email?:string;
}

const Form = ({ location, email }: SignupConfirmFormProps) => {
  const { t } = useTranslation();
  const button_classes = button_style();
  const paper_classes = paper_style();
  const grid_classes = grid_style();

  const searchParams = location?.search ?? '';
  const urlParams = new URLSearchParams(searchParams);

  const [values, setValues] = useState({
    username: urlParams.get('email') ?? email,
    isBusy: false,
    isResending: false,
    isSuccess: false,
    error: ''
  });

  const [resent, setResent] = useState(false);

  useEffect(() => {
    if(!resent) handleResend();
  },[resent]);

  async function handleResend(event?: any) {
    if (event) event.preventDefault();

    setValues(values => ({
      ...values,
      isBusy: true,
      isResending: true,
      error: ''
    }));
    setResent(true);
    Auth.resendSignUp(values.username ? values.username.toLowerCase() : '')
      .then(data => {
        setValues(values => ({
          ...values,
          isBusy: false,
          isResending: false,
          error: ''
        }));
      })
      .catch(err => {
        let errMsg = t('err_msgs.unknown');
        if (err.code) {
          errMsg = err.message;
        } else {
          errMsg = err;
        }
        setValues(values => ({
          ...values,
          isBusy: false,
          isResending: false,
          error: errMsg
        }));
      });
  };



  return (
    <Grid container>
      <Grid item sm={12}>
        <Paper className={`${paper_classes.paper} ${paper_classes.login_container}`}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h3">{t('signup.confirmsignup')}</Typography>
            <p>{t('signup.sentverification')}</p>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item sm={12} md={2}></Grid>
              <Grid item sm={12} md={8} className={grid_classes.relative}>
                <Button
                  disabled={values.isBusy ? true : false}
                  type="submit"
                  onClick={handleResend}
                  className={`${button_classes.noRadius} ${button_classes.default} ${button_classes.block}`}
                  variant="contained"
                  color="primary">
                  {t('signup.' + (values.isBusy ? (values.isResending ? 'resending' : 'validating') : 'resendcode'))}
                </Button>
                {(values.isBusy &&
                  <CircularProgress size={24} className={button_classes.buttonProgress} />
                )}
              </Grid>
              <Grid item sm={12} md={2}></Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Form;
