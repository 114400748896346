import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import input_styles from '../../../style/input';

function VariantSelector(props: any) {
  const input_classes = input_styles();

  const handleChange = (event: any) => {
    props.setSelectedVariant(event.target.value);
  };

  return (
    <RadioGroup
      aria-label="Product Variant"
      name="radioDeliveryOption"
      className={input_classes.textField}
      value={props.defaultVariant.productId}
      onChange={handleChange} >
      <Grid container>
        {props.family.item.variants.map((item: any, index: any) => (
          <Grid item xs={6} sm={4} key={index}>
            <FormControlLabel value={item.productId} control={<Radio />} label={item.name} />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  );
}

export default VariantSelector;
