import { makeStyles } from '@material-ui/core/styles';

const input_style = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    marginTop: theme.spacing(4),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%'
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  legend_label: {
    '& label': {
      fontSize: '1.8rem',
    },
  },
  formgroupInline: {
    '& div': {
      flexDirection: 'row',
    }
  },
  separatedFormControls: {
    '& div.MuiFormControl-root': {
      marginTop: 0
    },
    '& legend': {
      marginTop: theme.spacing(4)
    }
  },
  inputQtyMobile: {
    '& input': {
      fontSize: '2.5rem',
      fontWeight: 700,
      textAlign: 'center'
    }
  }
}));

export default input_style;
