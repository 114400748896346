import React from "react";
import Grid from "@material-ui/core/Grid";
import SignupForm from "./SignupForm";
import grid_style from "../../style/grids";
import { applyNext } from "./Next";
import QFBox from "../Base/QueryContainer/QFBox";
import { CreateOrderProvider } from "../Contexts/Order";

const Signup: React.FC = (props: any) => {
  const { isFromCheckout = false } = props;
  const grid_classes = grid_style();
  const next: any = applyNext(props);

  if (next) {
    return next;
  }

  return (
    <div className={grid_classes.root}>
      <CreateOrderProvider>
        {isFromCheckout ? (
          <Grid container>
            <Grid item xs={12}>
              <QFBox
                resource={["picklists"]}
                component={SignupForm}
                isFromCheckout
              />
            </Grid>
          </Grid>
        ) : (
            <Grid container>
              <Grid item xs={12} sm={12} md={2}></Grid>
              <Grid item xs={12} sm={12} md={8}>
                <QFBox resource={["picklists"]} component={SignupForm} newUser={true} />
              </Grid>
              <Grid item xs={12} sm={12} md={2}></Grid>
            </Grid>
          )}
      </CreateOrderProvider>

    </div>
  );
};

export default Signup;
